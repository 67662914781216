export const horizontalBarPlugins = (format = 'percent') => [{
  afterDatasetDraw: (chartInstance) => {
    const ctx = chartInstance.chart.ctx;
    ctx.font = '12px Montserrat-Black, Helvetica, Arial, sans-serif';
    ctx.fillStyle = '#555'
    ctx.textAlign = 'center';
    ctx.textBaseline = 'bottom';

    chartInstance.data.datasets.forEach((dataset, i) => {
      const meta = chartInstance.controller.getDatasetMeta(i);
      meta.data.forEach((bar, index) => {
        if (!dataset.data[index]) return
        if (format === 'number') {
          return ctx.fillText(dataset.data[index], bar._model.x + 20, bar._model.y + 8);
        }
        const data = dataset.data[index];
        const result = data < 1 ? data.toFixed(1) : data.toFixed(0);
        return ctx.fillText(result % 1 === 0 ? Math.round(result) + '%' : result + '%',
          bar._model.x , bar._model.y + 8);
      });
    })
  }
}];

export const horizontalBarOptions = (format = 'percent') => {
  return {
    responsive: true,
    //maintainAspectRatio: false,
    tooltips: {
      mode: 'label'
    },
    scales: {
      xAxes: [
        {
          position: 'top',
          gridLines: {
            drawBorder: false
          },
          ticks: {
            callback: value => format === 'number' ? value : `${value}%`,
            beginAtZero: true,
            fontSize: 16
          }
        }
      ],
      yAxes: [
        {
          gridLines: {
            display: false
          },
          ticks: {
            callback: value => {
              const newStr = value.split(' ', 4).join(' ')+ '...'
              return value.length > 30 ? newStr : value
            },
          }
        }
      ]
    }
  }
};

export const horizontalBarDatasetOptions = [{
  borderColor: '#fdbb2d',
  backgroundColor: '#fdbb2d',
  pointBorderColor: '#fdbb2d',
  pointBackgroundColor: '#fdbb2d',
  pointHoverBackgroundColor: '#fdbb2d',
  pointHoverBorderColor: '#fdbb2d',
}]

export const barDatasetOptions = [{
  borderColor: '#6495ed',
  backgroundColor: '#6495ed',
  pointBorderColor: '#6495ed',
  pointBackgroundColor: '#6495ed',
  pointHoverBackgroundColor: '#6495ed',
  pointHoverBorderColor: '#6495ed',
}]

export const barPlugins = (format = 'percent') => [{
  afterDatasetDraw: (chartInstance) => {
    const ctx = chartInstance.chart.ctx;
    ctx.font = chartInstance.scales.font;
    ctx.fillStyle = '#555';
    ctx.textAlign = "center";
    ctx.textBaseline = "bottom";

    chartInstance.data.datasets.forEach((dataset, i) => {
      const meta = chartInstance.controller.getDatasetMeta(i);
      meta.data.forEach((bar, index) => {
        if (!dataset.data[index]) return
        if(format === 'number') {
          return ctx.fillText(Math.round(dataset.data[index]).toString(), bar._model.x, bar._model.y - 5);
        }
        const data = dataset.data[index];
        const result = data < 1 ? data.toFixed(1) : data.toFixed(0);
        return ctx.fillText(result + '%', bar._model.x, bar._model.y - 5);
      });
    })
  }
}];

export const barOptions = (format = 'percent') => ({
  responsive: true,
  tooltips: {
    mode: 'label'
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          display: false
        }
      }
    ],
    yAxes: [
      {
        gridLines: {
          drawBorder: false
        },
        ticks: {
          callback: value => format === 'number' ? value : `${value}%`,
          beginAtZero: true
        }
      }
    ]
  }
})
