import {API} from "../config.js";
import "ie-array-find-polyfill";
import {each} from "lodash";
import * as Sentry from "@sentry/react";
//import "formdata-polyfill";
//import FormData from "form-data";

class ItemService {
    constructor(apiPath, onUpdateState, lang, user) {
        this.apiPath = apiPath;
        this.API = `${API}/categories`;
        this._user = user;

        this.url = this.apiPath.match(/^http/) ? this.apiPath : API;
        this.onUpdateState = onUpdateState;
        this._lang = lang;
        this._isEn = this._lang === "Eng";
    }

    UpdateStateFromItem(state, item) {
        let isTransient = false;
        if (!item.id) {
            isTransient = true;
        }
        // moves everything into state
        state = {...state, ...item, isTransient};

        return state;
    }

    async GetRegions() {
        const data = await this.GetItems("regions?size=1000");

        const result = data
            .map(this.GetValueAndLabel.bind(this))
            .sort(function (a, b) {
                var nameA = a.label.toUpperCase();
                var nameB = b.label.toUpperCase();
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            });

        return result;
    }

    async GetAllSelectOptions(path, selected, getAdditionalProperties) {
        return this.GetSelectOptions(path, selected, getAdditionalProperties, true);
    }

    async GetSelectOptions(
        path,
        selected,
        getAdditionalProperties,
        dontFilterInactive,
        params
    ) {
        let url = `${path}?size=1000`;

        if (params) {
            url = `${url}&${params}`;
        }

        const data = await this.GetItems(url);

        const result = data
            .filter(
                (x) =>
                    dontFilterInactive ||
                    x.active ||
                    // if selected is an array
                    (Array.isArray(selected) ? selected.includes(x) : x.id === selected)
            )
            .map((x) => {
                //let item = this.GetValueAndLabel(x);
                let item = x;
                if (getAdditionalProperties) {
                    item = {...item, ...getAdditionalProperties(item)};
                }
                return item;
            });
        return result;
    }

    GetValueAndLabel(item) {
        return {
            value: item.id,
            label:
                this._lang === "Eng"
                    ? item.en
                    ? item.en
                    : item.nameEn
                    : item.ua
                    ? item.ua
                    : item.nameUa,
            ...item,
        };
    }

    async GetItem(id) {
        let url = `${this.url}${this.apiPath}/${id}`;

        return fetch(url, this.Options("get"))
            .then((response) => {
                if (!response.ok) {
                    this.SendMessageNetworkError();
                }
                return response.json();
            })
            .then((r) => {
                return r;
            })
            .catch((error) => {
            });
    }

    async GetItems(path) {
        let url = path
            ? `${this.url}/${path}`
            : `${this.url}/${this.endpoint.path}`;
        return fetch(url, this.Options("get"))
            .then((response) => {
                if (!response.ok) {
                    Sentry.withScope((scope) => {
                        scope.setExtra("Error-Data", {
                            url: response.url,
                            status: response.status,
                            statusText: response.statusText,
                            type: response.type,
                            redirected: response.redirected,
                        });
                        Sentry.captureException("API response NOT ok");
                    });
                    this.SendMessageNetworkError();
                }
                return response.json();
            })
            .catch((error) => {
                Sentry.captureException(error);
                this.SendMessageNetworkError(error);
            });
    }

    async Fetch(method, url, data) {
        return fetch(url, this.Options(method, data))
            .then((response) => {
                if (!response.ok) {
                    this.SendMessageNetworkError();
                    Sentry.withScope((scope) => {
                        scope.setExtra("Error-Data", {
                            url: response.url,
                            status: response.status,
                            statusText: response.statusText,
                            type: response.type,
                            redirected: response.redirected,
                        });
                        Sentry.captureException("API response NOT ok");
                    });
                }
                return response.status === 204 ? response.statusText : response.json();
            })
            .catch((error) => {
                Sentry.captureException(error);
                this.SendMessageNetworkError(error);
            });
    }

    Save(id, data) {
        const method = id ? "put" : "post";
        const url = id
            ? `${this.url}${this.apiPath}/${id}`
            : `${this.url}${this.apiPath}`;

        return fetch(url, this.Options(method, data));
    }

    Options(method, data) {
        // data is either json or formdata (multipart)
        const multipart = data instanceof FormData;

        let headers = {
            accept: "application/json",
            Authorization: localStorage.getItem("TOKEN"),
        };

        // FormData goes without content-type, biut json NEEDS!
        if (!multipart) {
            headers["content-type"] = "application/json";
        }

        let result = {
            method: method.toUpperCase(),
            body: multipart ? data : JSON.stringify(data),
            headers,
        };

        return result;
    }

    ValidateItem(item) {
        let result = {};
        return result;
    }

    GetInitialState(roles, currentPhaseId) {
        const state = {
            open: true,
            loading: true,
            showConfirmCancellation: false,
            sucessMessageOpen: false,
            sucessMessage: "",
            hasChanges: false,
            messages: [],
            user: this._user,
            isAdmin: this._user?.isAdmin,
            isGuest: roles?.indexOf("ROLE_GUEST") >= 0,
            currentPhaseId,
            isFirstPhase: currentPhaseId === 1,
        };

        return state;
    }

    GetClose() {
        const message = {
            type: "close",
            handled: false,
            timestamp: Date.now(),
        };

        return message;
    }

    GetRedirect(target) {
        const message = {
            type: "redirect",
            payload: target,
            handled: false,
            timestamp: Date.now(),
        };

        return message;
    }

    GetInfo(text, title) {
        const message = {
            type: "message",
            payload: {text, title: title ?? "Info", type: "info"},
            handled: false,
            timestamp: Date.now(),
        };

        return message;
    }

    GetInfoDialog(text, title) {
        const message = {
            type: "dialog",
            payload: {text, title: title ?? "Info", type: "info"},
            handled: false,
            timestamp: Date.now(),
        };

        return message;
    }

    GetWarning(text, title) {
        const message = {
            type: "message",
            payload: {text, title: title ?? "Warning", type: "warning"},
            handled: false,
            timestamp: Date.now(),
        };

        return message;
    }

    GetError(text, title) {
        const message = {
            type: "message",
            payload: {text, title: title ?? "Error", type: "error"},
            handled: false,
            timestamp: Date.now(),
        };

        return message;
    }

    SendMessageNetworkError(error) {
        this.SendMessage(
            this.GetError("Failed to load data from server, please reload!")
        );
    }

    SendMessage(message) {
        this.onUpdateState({messages: [message]});
    }

    async Action(e, state) {
        state = {...state};
        state[e.fieldName] = e.value;

        state = this.SetStateChanged(state);
        return state;
    }

    SetStateChanged(state) {
        if (!state.loading) {
            state = {...state, hasChanges: true};
        }

        return state;
    }

    MessageSaveSucceeded(state, r) {
        state = {...state};

        if (!state.messages) {
            state.messages = [];
        }

        state.messages.push(this.GetInfo("Item sucessfully saved"));
        state.messages.push({type: "triggerSaveSucceeded"});
        return state;
    }

    MessageDeleteSucceeded(state, r) {
        state = {...state};

        if (!state.messages) {
            state.messages = [];
        }

        state.messages.push(this.GetInfo("Item sucessfully deleted"));
        return state;
    }

    async MessageSaveFailed(state, r, data) {
        state = {...state};

        if (!state.messages) {
            state.messages = [];
        }

        state.messages.push(
            this.GetError(
                `Item could not be saved! Message from server: "${r.statusText}"`
            )
        );
        return state;
    }

    //default close, dont call super on override
    NavigateAfterSave(state) {
        state = {...state};

        if (!state.messages) {
            state.messages = [];
        }

        state.messages.push(this.GetClose());
        return state;
    }

    hasNoErrors(validation) {
        for (var key in validation) {
            if (validation.hasOwnProperty(key)) return false;
        }
        return true;
    }

    MapStateToModel(state) {
        return {};
    }

    MapStateToModelMultiPart(state) {
        return new FormData();
    }

    async Delete(state) {
        let url = `${this.url}${this.apiPath}/${state.id}`
        await this.Fetch("delete", url);
    }

    async TrySave(state) {
        const validation = this.ValidateItem(state);

        if (this.hasNoErrors(validation.valid)) {
            const data = this.MapStateToModel(state);

            this.Save(state.id, data)
                .then(async (response) => {
                    if (response.ok) {
                        const returnedItem = await response.json();
                        // save succeeded
                        state = {...state, returnedItem};

                        await this.onAfterSave(state);
                        state = this.MessageSaveSucceeded(state, response);
                        state = this.NavigateAfterSave(state, response);
                        this.onUpdateState(state);
                    } else {
                        let failedResponseData;
                        try {
                            failedResponseData = await response.json();
                        }catch
                        {
                            failedResponseData = [];
                        }

                        this.MessageSaveFailed(state, response, failedResponseData);
                        this.onUpdateState(state);
                        Sentry.withScope((scope) => {
                            scope.setExtra("Error-Data", {
                                url: response.url,
                                status: response.status,
                                statusText: response.statusText,
                                type: response.type,
                                redirected: response.redirected,
                                state: state,
                            });
                            Sentry.captureException("Save item Failed, API response NOT ok");
                        });
                    }
                })
                .catch((response) => {
                    // save failed
                });
        } else {
            this.MessageSaveFailed(state, validation);
            this.onUpdateState(state);
        }
    }

    async onAfterSave(state) {}

    validationFileSize(validation, item, property, maxSize) {
        if (item[property] && item[property].size > maxSize) {
            validation[property] = `Max. size ${(maxSize / 1024 / 1024).toFixed(
                0
            )} MB`;
        }
    }

    validationRequired(validation, item, property) {
        if (
            item[property] === undefined ||
            item[property] === null ||
            item[property] === ""
        ) {
            validation[property] = "Required";
        }
    }

    validationNumberRequired(validation, item, property) {
        if (
            item[property] === undefined ||
            item[property] === "" ||
            Number(item[property]) <= 0
        ) {
            validation[property] = "Required";
        }
    }

    validationNotEmpty(validation, item, property) {
        if (item[property] === undefined || item[property].length === 0) {
            validation[property] = "Required";
        }
    }

    validationSelectionRequired(validation, item, property) {
        if (item[property] === null || item[property] === undefined || item[property] === 0) {
            validation[property] = "Required";
        }
    }

    validateGeoData(validation, item, property) {
        if (item[property] && item[property] !== "") {
            let isValid = false;
            let geoData = item[property].split(" ");

            const isNumber = (x) => x !== "" && !isNaN(x);

            if(geoData.length ===2)
            {
                isValid =  isNumber(geoData[0]) && isNumber(geoData[1]);
            }
            if (!isValid) {
                validation[property] = "wrong format";
            }
        }
    }

    MapArray(param, data, field) {
        if (field) {
            data.append(param, JSON.stringify(field.map((x) => x.id)));
        }
    }

    MapProperty(data, state, param) {
        if (param.type === "array") {
            let propName = param.name;
            if (!state[propName] && propName.endsWith("Ids")) {
                propName = `${propName.slice(0, -3)}s`;
            }

            this.MapArray(param.name, data, state[propName]);
        } else if (state[param.name]) {
            data.append(param.name, state[param.name]);
        }
    }

    // for index page

    GetInitialIndexState(currentPhaseId) {
        const state = {
            loading: true,
            showConfirmDelete: false,
            items: [],
            page: 0,
            total: 0,
            rowsPerPage: 25,
            searchQuery: "",
            order: "desc",
            orderBy: "",
            currentPhaseId,
            isFirstPhase: currentPhaseId === 1,
        };

        return state;
    }

    UpdateStateFromQuery(state, queryString) {
        const query = new URLSearchParams(queryString);

        let sort = query.get("sort")?.split(",");

        let order = "";
        let orderBy = "";

        if (sort && sort.length === 2) {
            orderBy = sort[0];
            order = sort[1];
        }

        let result = {
            ...state,
            searchQuery: query.get("query") ?? "",
            page: Number(query.get("page")) ?? 0,
            orderBy: orderBy,
            order: order,
            queryString,
        };

        return result;
    }

    async GetList(state) {
        let result = {...state, loading: true};
        this.onUpdateState(result);

        const data = await this.GetListItems(
            result.searchQuery,
            result.page && result.page + 1,
            result.orderBy
                ? {
                    orderBy: result.orderBy,
                    order: result.order,
                }
                : undefined,
            result.columnFilter
        );

        result = {
            ...result,
            loading: false,
            queryString: data.queryString,
            items: data.items,
            total: data.total,
        };

        return result;
    }

    async GetListItems(query, page, sort, columnFilter) {
        let url, params;
        let hasParam = false;

        if (query) {
            url = `${this.url}${this.SearchUrlFragment()}${this.apiPath}`;
            params = `?query=${query}`;
            hasParam = true;
        } else {
            url = `${this.url}${this.apiPath}`;
            params = "";
        }

        if (page) {
            params = `${params}${hasParam ? "&" : "?"}page=${page}`;
            hasParam = true;
        }

        if (sort) {
            params = `${params}${hasParam ? "&" : "?"}sort=${sort.orderBy},${
                sort.order
            }`;
            hasParam = true;
        }

        const additionalParams = this.getAdditionalParams();
        if (additionalParams) {
            params = `${params}${hasParam ? "&" : "?"}${additionalParams}`;
            hasParam = true;
        }

        let columnFilterQueryString = this.FilterToQueryString(columnFilter);
        if (columnFilterQueryString) {
            params = `${params}${hasParam ? "&" : "?"}${columnFilterQueryString}`;
            hasParam = true;
        }

        const response = await this.FetchResponse("get", `${url}${params}`);

        let items = await response.json();

        if (!items) {
            return {
                queryString: params,
                items: [],
                total: 0,
            };
        }

        return {
            queryString: params,
            items,
            total: Number(response.headers.get("X-total")),
        };
    }

    getAdditionalParams() {
        return undefined;
    }

    FetchResponse(method, url) {
        return fetch(url, this.Options(method))
            .then((response) => {
                if (!response.ok) {
                    Sentry.withScope((scope) => {
                        scope.setExtra("Error-Data", {
                            url: response.url,
                            status: response.status,
                            statusText: response.statusText,
                            type: response.type,
                            redirected: response.redirected,
                        });
                        Sentry.captureException("API response NOT ok");
                    });
                    this.SendMessageNetworkError();
                }
                return response;
            })
            .catch((error) => {
                Sentry.captureException(error);
                this.SendMessageNetworkError(error);
            });
    }

    async SetPage(state, page) {
        let result = {...state, page: Number(page)};

        result = await this.GetList(result);

        return result;
    }

    SetPageSize(state, pageSize) {
        const result = {...state, rowsPerPage: Number(pageSize)};
        return result;
    }

    SetSearchQuery(state, searchQuery) {
        const result = {...state, searchQuery: searchQuery};
        return result;
    }

    async ClearSearch(state) {
        let result = {...state, searchQuery: ""};
        result = await this.GetList(result);
        return result;
    }

    async SetSort(state, orderBy) {
        let order = "desc";
        if (state.orderBy === orderBy && state.order === "desc") {
            order = "asc";
        }

        let result = {...state, orderBy: orderBy, order: order};

        result = await this.GetList(result);

        return result;
    }

    SetConfirmDelete(state, id) {
        return {...state, deleteId: id, showConfirmDelete: true};
    }

    SetCancelDelete(state) {
        return {...state, deleteId: undefined, showConfirmDelete: false};
    }

    async SetDelete(state) {
        let result = {...state, loading: true, showConfirmDelete: false};
        this.onUpdateState(result);

        const response = await this.Fetch(
            "DELETE",
            `${this.url}${this.apiPath}/${state.deleteId}`
        );

        if (!response.error) {
            result = this.MessageDeleteSucceeded(result);
            const items = result.items.filter((x) => x.id !== state.deleteId);
            result = {...result, items, total: result.total - 1};
        }

        result = {
            ...result,
            loading: false,
            showConfirmDelete: false,
            deleteId: undefined,
        };

        return result;
    }

    async SetColumnFilter(state, columnFilter, isChecked) {
        let filtersInState = [...(state.columnFilter ?? [])];

        if (!Array.isArray(columnFilter)) {
            columnFilter = [columnFilter];
        }

        for (let filter of columnFilter) {
            let [key, value] = filter.split("[]=");

            // find filter in state
            let criteriaInState = filtersInState.find((f) => f.criteria === key);
            if (!criteriaInState) {
                criteriaInState = {criteria: key, values: []};
                filtersInState.push(criteriaInState);
            }

            let indexOf = criteriaInState.values.indexOf(String(value));

            if (isChecked && indexOf === -1) {
                // add only if not exists
                criteriaInState.values.push(String(value));
            } else if (!isChecked && indexOf !== -1) {
                // remove only if exists
                criteriaInState.values.splice(indexOf, 1);
            }
        }

        state = {...state, columnFilter: filtersInState};

        state = await this.GetList(state);
        return state;
    }

    FilterToQueryString(columnFilter) {
        let filterString = undefined;

        if (columnFilter) {
            for (let index = 0; index < columnFilter.length; index++) {
                let filter = columnFilter[index];
                if (!filter.values) {
                    continue;
                }
                for (
                    let valueIndex = 0;
                    valueIndex < filter.values.length;
                    valueIndex++
                ) {
                    let value = filter.values[valueIndex];
                    filterString = filterString ? `${filterString}&` : "";
                    filterString = `${filterString}${filter.criteria}[]=${value}`;
                }
            }
        }

        return filterString;
    }

    SetDetailSorting(state, orderBy, entity) {
        let order = "desc";
        if (
            state[entity + "OrderBy"] === orderBy &&
            state[entity + "Order"] === "desc"
        ) {
            order = "asc";
        }
        state = {
            ...state,
            [entity + "OrderBy"]: orderBy,
            [entity + "Order"]: order,
        };
        return state;
    }

    GetDistChart(state, item, fieldName) {
        let result = {...state};

        let chart = {
            labels: [],
            datasets: [
                {
                    data: [],
                    backgroundColor: [],
                    borderColor: [],
                    borderWidth: 1,
                },
            ],
        };
        let dataset = item[fieldName];

        if (dataset) {
            each(dataset, (data) => {
                const [label, value] = data;
                chart.labels.push(this._isEn ? label.en : label.ua);
                chart.datasets[0].data.push(value);
                let color = this.dynamicColor();
                color = `${color[0]},${color[1]},${color[2]}`;
                chart.datasets[0].backgroundColor.push(`rgba(${color},0.2)`);
                chart.datasets[0].borderColor.push(`rgba(${color},1)`);
            });
        }

        return {...result, [`${fieldName}Chart`]: chart};
    }

    SearchUrlFragment() {
        return "/_search";
    }

    dynamicColor = () => {
        var r = Math.floor(Math.random() * 255);
        var g = Math.floor(Math.random() * 255);
        var b = Math.floor(Math.random() * 255);
        return [r, g, b];
    };

    doughnutOptions = {
        maintainAspectRatio: false,
        legend: {
            display: false,
        },
        tooltips: {},
        responsive: false,
    };

    emptyDoughnutState = {
        labels: [],
        datasets: [
            {
                data: [],
                backgroundColor: [],
                borderColor: [],
                borderWidth: 1,
            },
        ],
    };
}

export default ItemService;
