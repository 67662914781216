import { makeAutoObservable, runInAction } from "mobx";
import CyrillicToTranslit from "cyrillic-to-translit-js";
import { capitalize } from "../utils/capitalize";
import { PreregistrationSteps } from "../utils/PreregistrationSteps";

const NotAvailableRegion = { id: 30, en: "N/A", ua: "N/A" };

export class PreregistrationStore {
  dataFetcher = null;
  langStore = null;
  pending = true;
  step = "";
  event = {};
  regions = [];
  employerTypes = [];
  employerLevels = [];
  employerLevelId = -1;
  form = null;
  formUnsubscribe = null;

  constructor(dataFetcher, langStore) {
    this.dataFetcher = dataFetcher;
    this.langStore = langStore;
    makeAutoObservable(this, {
      dataFetcher: false,
      langStore: false,
      form: false,
      formUnsubscribe: false,
    });
  }

  get regionsDisabled() {
    return this.employerLevelId === 1 || this.employerLevelId === 2;
  }

  get availableRegions() {
    return this.regionsDisabled ? [NotAvailableRegion] : this.regions;
  }

  get availableEmployerLevels() {
    return this.employerLevels.filter((item) => item.id !== 5 && item.id !== 6);
  }

  get eventName() {
    return this.event[`name${capitalize(this.langStore.langKey)}`] ?? "";
  }

  setForm(form) {
    if (this.form !== form) {
      if (this.formUnsubscribe) {
        this.formUnsubscribe();
      }
      this.form = form;
      this.formUnsubscribe = this.form.subscribe(this.handleFormValuesChange, {
        values: true,
      });
    }
  }

  handleFormValuesChange = (state) => {
    const { employerLevelId, regionId } = state.values;
    if (employerLevelId) {
      this.employerLevelId = employerLevelId;
      if (this.regionsDisabled && regionId !== NotAvailableRegion.id) {
        this.form.change("regionId", NotAvailableRegion.id);
      }
      if (!this.regionsDisabled && regionId === NotAvailableRegion.id) {
        this.form.change("regionId", null);
      }
    }
  };

  setExistingParticipant() {
    this.step = PreregistrationSteps.ExistingParticipant;
  }

  setNewParticipant() {
    this.step = PreregistrationSteps.NewParticipant;
  }

  async loadEventData(id) {
    try {
      this.pending = true;
      const data = await this.dataFetcher.getPreregistrationEventData(id);
      runInAction(() => {
        this.pending = false;
        this.step = PreregistrationSteps.NewParticipant;
        this.event = data.event ?? {};
        this.regions = data.regions ?? [];
        this.employerTypes = data.employerTypes ?? [];
        this.employerLevels = data.employerLevels ?? data.emplyerLevels ?? [];
      });
    } catch (ex) {
      this.pending = false;
      this.step = PreregistrationSteps.EventNotFound;
    }
  }

  async register() {
    const { name, employerLocation, ...params } = this.form.getState().values;

    params.eventId = this.event.id;
    params.lang = this.langStore.langKey;
    params.alreadyParticipated =
      this.step === PreregistrationSteps.ExistingParticipant;

    const translit = new CyrillicToTranslit({ preset: "uk" });
    if (this.langStore.isUkr) {
      params.nameUa = name;
      params.nameEn = translit.transform(name);
    } else {
      params.nameEn = name;
      params.nameUa = translit.reverse(name);
    }

    if (!params.alreadyParticipated) {
      const { location, address } = employerLocation;
      params.contactAddress = address?.query;
      params.employerLocation = `POINT(${location[1]} ${location[0]})`;
    }

    this.pending = true;
    try {
      await this.dataFetcher.registerParticipantToEvent(this.event.id, params);
      runInAction(() => {
        this.pending = false;
        this.step = PreregistrationSteps.RegistrationSuccessful;
      });
    } catch (ex) {
      runInAction(() => {
        this.pending = false;
      });
      throw ex;
    }
  }
}
