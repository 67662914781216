import ItemService from "./ItemService";
import { Endpoints } from "../config.js";

class GoodPracticeService extends ItemService {
  constructor(onUpdateState, lang) {
    super(Endpoints.GoodPractice.path, onUpdateState, lang);
  }
}

export default GoodPracticeService;
