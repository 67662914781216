import camelcaseKeys from "camelcase-keys";
import snakecaseKeys from "snakecase-keys";
import { FetchError } from "../utils/FetchError";

const METHODS = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
  PATCH: "PATCH",
};

export const EVENT_EP = "/ul-events";
export const PARTICIPANT_EP = "/participants";
export const PREREG_PARTICIPANTS_EP = "/enrollments";
export const COMMUNITY_BY_COORDS = "/communities/by_coords";

export class DataFetcher {
  API_HOST = "";
  API_ROOT = "";
  PUBLIC_API_ROOT = "";
  token = localStorage.getItem("TOKEN") ?? "";

  constructor() {
    this.API_HOST = process.env.REACT_APP_API_HOST ?? "";
    this.API_ROOT = `${this.API_HOST}/api`;
    this.PUBLIC_API_ROOT = `${this.API_HOST}/public_api`;
  }

  request(endpoint, method = METHODS.GET, params = null, isPrivate = true) {
    const root = isPrivate ? this.API_ROOT : this.PUBLIC_API_ROOT;
    const headers = {};
    if (isPrivate) {
      headers.Authorization = this.token;
    }

    let body;
    if (params instanceof FormData) {
      body = params;
    } else if (params instanceof Object) {
      body = JSON.stringify(params);
      headers["Content-Type"] = "application/json";
    }

    return fetch(`${root}${endpoint}`, {
      method,
      body,
      headers,
    }).then(async (res) => {
      let data = {};
      const contentDisposition = res.headers.get("content-disposition") ?? "";
      const contentType = res.headers.get("content-type");
      if (contentDisposition.indexOf("attachment") === 0) {
        data = await res.blob();
      } else if (contentType === "application/json") {
        data = await res.json();
      }
      if (res.ok) {
        return {
          data,
          res,
        };
      } else {
        return Promise.reject({
          status: res.status,
          data,
          res,
        });
      }
    });
  }

  async signin(username, password, rememberMe) {
    try {
      const { res } = await this.request("/authenticate", METHODS.POST, {
        username,
        password,
        remember_me: rememberMe,
      });
      this.token = res.headers.get("Authorization");
      localStorage.setItem("TOKEN", this.token);
      return { token: this.token };
    } catch ({ data }) {
      throw new FetchError({
        errorMessage: data.authenticationException,
      });
    }
  }

  async signout() {
    await this.request("/logout", METHODS.DELETE);
    this.token = "";
    localStorage.removeItem("TOKEN");
    localStorage.removeItem("welcomeMsgShown");
  }

  async refreshToken() {
    try {
      const { res } = await this.request("/authenticate", METHODS.POST, {
        username: "",
        password: "",
      });
      this.token = res.headers.get("Authorization");
      localStorage.setItem("TOKEN", this.token);
      return { token: this.token };
    } catch ({ data }) {
      throw new FetchError({
        errorMessage: data.authenticationException,
      });
    }
  }

  updateSettings(params) {
    return this.request("/settings", METHODS.PUT, params);
  }

  async getAccount() {
    try {
      const { data } = await this.request("/account");
      if (!data?.id) {
        throw new FetchError();
      }
      return data;
    } catch (ex) {
      throw new FetchError();
    }
  }

  async getPreregistrationEventData(id) {
    try {
      const { data } = await this.request(
        `/enrollments/${id}`,
        METHODS.GET,
        null,
        false
      );
      return camelcaseKeys(data, { deep: true });
    } catch (ex) {
      throw new FetchError();
    }
  }

  async registerParticipantToEvent(eventId, params) {
    try {
      const { data } = await this.request(
        `/enrollments/${eventId}`,
        METHODS.POST,
        snakecaseKeys(params),
        false
      );
      if (data?.success) {
        return;
      }
      throw new FetchError();
    } catch (ex) {
      throw new FetchError(ex);
    }
  }

  async downloadParticipantsTemplate() {
    try {
      const { res, data } = await this.request(
        `${EVENT_EP}/participants_template`
      );
      let match = res.headers
        .get("content-disposition")
        .match(/filename="(.*)";/);
      return { name: match[1] ?? "participants-template", data };
    } catch (ex) {
      throw new FetchError(ex);
    }
  }

  async uploadParticipants(eventId, file) {
    const formData = new FormData();
    formData.set("participants_file", file);

    try {
      const { data } = await this.request(
        `${EVENT_EP}/${eventId}/participants_import`,
        METHODS.POST,
        formData
      );
      return camelcaseKeys(data, { deep: true });
    } catch (ex) {
      throw new FetchError(ex);
    }
  }

  async switchPreregistrationState(eventId, enabled) {
    try {
      const { data } = await this.request(
        `${EVENT_EP}/${eventId}`,
        METHODS.PUT,
        {
          enrollmentEnabled: enabled,
        }
      );
      return camelcaseKeys(data, { deep: true });
    } catch (ex) {
      throw new FetchError();
    }
  }

  async deletePreregParticipant(id) {
    try {
      const result = await this.request(
        `${PREREG_PARTICIPANTS_EP}/${id}`,
        METHODS.DELETE
      );
      console.log(result);
      return true;
    } catch (ex) {
      throw new FetchError(ex);
    }
  }
}
