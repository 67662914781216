import React from "react";
import classNames from "classnames";
import { Field } from "react-final-form";
import { TextField, MenuItem } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const styles = {
  root: {
    marginBottom: "20px",
    textAlign: "left",
  },
  label: {
    padding: 0,
    textAlign: "left",
    position: "static",
    transform: "none",
  },
  placeholder: {
    color: "rgba(0, 0, 0, 0.26)",
  },
  error: {
    borderLeft: "7px solid #fe8368",
  },
  select: {
    "&:focus": {
      backgroundColor: "#e1edfb",
      borderRadius: "0.25rem",
    },
  },
};

const GIZSelectField = ({
  classes,
  name,
  label,
  placeholder,
  values = [],
  validator = null,
  disabled = false,
  className,
}) => {
  return (
    <Field name={name} validate={validator}>
      {({ input, meta }) => {
        const { value, onChange, onBlur, onFocus } = input;
        return (
          <TextField
            name={name}
            label={label}
            InputLabelProps={{
              classes: {
                root: classes.label,
              },
              shrink: false,
              focused: false,
            }}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            select
            SelectProps={{
              classes: {
                select: classes.select,
              },
              displayEmpty: true,
              renderValue: (val) => {
                const selected = values.find(item => item.value === val) ?? {};
                return (
                  val ? selected.label : (
                    <span className={classes.placeholder}>{placeholder}</span>
                  )
                );

              }
            }}
            variant="outlined"
            disabled={disabled}
            className={classNames(classes.root, className)}
            inputProps={{
              className: classNames({
                [classes.error]:
                  meta.invalid &&
                  (meta.touched || (meta.visited && meta.dirty)),
              }),
            }}
          >
            {values.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        );
      }}
    </Field>
  );
};

export default withStyles(styles)(GIZSelectField);
