// history.js
import { createBrowserHistory } from "history";

/**
 * @deprecated use RouterStore instead
 */
export default createBrowserHistory({
  /* pass a configuration object here if needed */
});

/**
 * @deprecated use RouterStore instead
 */
export const changeUrl = (params) => {
  if (!params) {
    params = "?";
  }

  window.history.pushState({}, undefined, params);
};
