import React from "react";
import {observer} from "mobx-react";
import {Link, NavLink} from "react-router-dom";
import {AppBar, Typography, MenuItem, Select} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";
import classNames from "classnames";
import {StoreContext} from "../stores/context";
import withRoot from "../withRoot";
import {forumRoute} from "../utils/routes";
import fetchData from "../utils/fetch";
import {I18n} from "../i18n";
import {Settings} from "../config.js"

import {Breakpoints} from "../config";
import withRouter from "react-router-dom/es/withRouter";

const styles = (theme) => ({
    header: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        minWidth: "1278px",
    },
    appBar: {
        position: "relative",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: "#4D64A9",
        padding: "5px 2.3vw",
        height: "90px",
        boxShadow: "none",
        justifyContent: "space-between",


        "& nav": {
            display: "flex",
            justifyContent: "space-between",
            minWidth: "860px",
            width: "100%",
            padding: "7px 0 0 ",
            marginLeft: "43px",

        },
        "& .icon": {
            display: "block",
            height: "25px",
            width: "25px",
        },
        "& .icon-home": {
            background: "url(/images/icons-marin/header-home.svg) no-repeat 0 0",
        },
        "& .icon-register": {
            background: "url(/images/icons-marin/header-register.svg) no-repeat 0 0",
        },
        "& .icon-indicators": {
            background:
                "url(/images/icons-marin/header-indicators.svg) no-repeat 0 0",
        },
        "& .icon-dashboard": {
            background: "url(/images/icons-marin/header-dashboard.svg) no-repeat 0 0",
        },
        "& .icon-search": {
            background: "url(/images/icons-marin/header-search.svg) no-repeat 0 0",
        },
        "& .icon-templates": {
            background: "url(/images/icons-marin/header-templates.svg) no-repeat 0 0",
        },
        "& .icon-administration": {
            background:
                "url(/images/icons-marin/header-administration.svg) no-repeat 0 0",
        },
        "& .icon-support": {
            background: "url(/images/icons-marin/header-support.svg) no-repeat 0 0",
            height: "28px",
            width: "28px",
            marginBottom: "-3px",
        },
        "& .icon-user": {
            background: "url(/images/icons-marin/header-user.svg) no-repeat 0 0",
            width: "17px",
            marginLeft: "14px",
            marginRight: "0",
        },
    },
    appMenu: {
        "&.menu-popup": {
            position: "absolute",
            width: "225px",
            backgroundColor: "#4D64A9",
            top: "25px",
            paddingTop: "20px",
            left: "-20px",
            borderRadius: "5px",
            paddingBottom: "20px",
            display: "none",
        },
        "&.menu-popup_dashboard": {
            width: "190px",
        },
        "& .icon": {
            display: "block",
            height: "14px",
            width: "26px",
            color: "#E6FEFF",
            fontSize: "16px",
            margin: " 5px 0 0 7px",
            position: "absolute",
            left: "5px",
            top: "0",
        },

        "& > div:last-child": {
            backgroundColor: "#4D64A9",
            border: "none",
            boxShadow: "none",
            marginTop: "80px",
        },
        "& p": {
            outline: "none",
        },
        "& a": {
            color: "#AFDAE8",
            fontWeight: "700",
            fontSize: "13px",
            padding: "5px 10px 0",
            paddingLeft: "40px",
            position: "relative",
            letterSpacing: "0.1em",
        },
        "&.user-menu": {
            paddingBottom: "5px",
            width: "130px",
            top: "35px",
            background:
                "linear-gradient(to top, #4D64A9, #4D64A9 155px, transparent 130px)",
            left: "-19px",

            "& a": {
                padding: "10px 10px 0 40px",
                "&:last-child": {
                    marginBottom: "15px",
                },
            },
            "& .icon": {
                height: "100%",
                position: "absolute",
                left: "5px",
            },
        },
        "&.register-menu": {
            width: "158px",
            "& a": {
                padding: "5px 10px 0 40px",
                "&.link-hromadas": {
                    alignItems: "flex-start",
                    textAlign: "left",
                    "& i": {
                        width: "30px",
                        marginRight: "7px",
                    },
                },
            },
            "& .icon": {
                height: "100%",
                marginLeft: "4px",
            },
        },
    },
    appBarLink: {
        color: "#AFDAE8",
        textDecoration: "none",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        fontSize: "0.9vw",

        cursor: "pointer",
        fontFamily: "Montserrat!important",
        fontStyle: "normal",
        fontWeight: 400,
        position: "relative",
        "& a": {
            color: "#AFDAE8",
        },

        "&:hover, &.active": {
            "& .menu-popup": {
                display: "block",
            },
        },

        "&:hover, &.active, &.active-link": {
            color: "#E6FEFF",
            "& .icon-home": {
                background: "url(/images/icons/header-home.svg) no-repeat 0 0",
            },
            "& .icon-register": {
                background: "url(/images/icons/header-register.svg) no-repeat 0 0",
            },
            "& .icon-indicators": {
                background: "url(/images/icons/header-indicators.svg) no-repeat 0 0",
            },
            "& .icon-dashboard": {
                background: "url(/images/icons/header-dashboard.svg) no-repeat 0 0",
            },
            "& .icon-search": {
                background: "url(/images/icons/header-search.svg) no-repeat 0 0",
            },
            "& .icon-templates": {
                background: "url(/images/icons/header-templates.svg) no-repeat 0 0",
            },
            "& .icon-administration": {
                background:
                    "url(/images/icons/header-administration.svg) no-repeat 0 0",
            },
            "& .icon-support": {
                background: "url(/images/icons/header-support.svg) no-repeat 0 0",
            },
            "& a": {
                color: "#E6FEFF",
            },
        },
        "& svg": {
            height: "100%",
            width: "100%",
            maxHeight: "35px",
            maxWidth: "35px",
        },
        "& i": {
            marginRight: "10px",
        },
        "&.active": {
            color: "##E6FEFF",
            fontWeight: 500,
        },
        "&.user-link": {
            color: "#AFDAE8",
            "&:hover": {
                color: "#E6FEFF"
            },
            "&:hover .icon-user": {
                background: "url(/images/icons-marin/header-user-selected.svg) no-repeat 0 0",
            },
            padding: "10px 0 2px 0",
            marginLeft: "35px",

            "& .icon": {
                top: "5px",
            },
        },
    },
    appBarMenuLink: {
        fontFamily: "Montserrat!important",
        fontWeight: "400!important",
        fontStyle: "normal!important",
        fontSize: "14px",
        color: "#AFDAE8",
        textDecoration: "none",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        cursor: "pointer",
        padding: "5px 10px 0",
        outline: "none",
        minWidth: "150px",
        backgroundColor: "transparent !important",
        textAlign: "left",
        "&:hover, &.active": {
            color: "##E6FEFF",
            opacity: "1",
            "& .icon-users": {
                background:
                    "url(/images/icons/header-users.svg) no-repeat center center / 20px 20px",
            },
            "& .icon-graduation-cap": {
                background:
                    "url(/images/icons/header-graduation-cap.svg) no-repeat center center / 20px 20px",
            },
            "& .icon-microphone": {
                background:
                    "url(/images/icons/header-microphone.svg) no-repeat center center / 20px 20px",
            },
            "& .icon-hromadas": {
                background:
                    "url(/images/icons/header-hromadas.svg) no-repeat center center / 26px 26px",
            },
            "& .icon-item": {
                background:
                    "url(/images/icons/header-item.png) no-repeat center center / 12px 12px",
            },
            "& .icon-settings": {
                background:
                    "url(/images/icons/header-settings.svg) no-repeat center center / 17px 17px",
            },
            "& .icon-password": {
                background:
                    "url(/images/icons/header-password.svg) no-repeat center center / 15px 15px",
            },
            "& .icon-signout": {
                background:
                    "url(/images/icons/header-signout.svg) no-repeat center center / 15px 15px",
            },
            "& .icon-calendar": {
                background:
                    "url(/images/icons/header-calendar.svg) no-repeat center center / 17px 17px",
            },
            "& .icon-practice": {
                background:
                    "url(/images/icons/header-practice.svg) no-repeat center center/ 17px 17px",
            },
        },
        "& .icon": {
            height: "100%",
            width: "100%",
            maxHeight: "20px",
            maxWidth: "20px",
            marginRight: "10px",
        },
        "& .icon-users": {
            background:
                "url(/images/icons-marin/header-users.svg) no-repeat top center / 20px 20px",
            top: "-4px",
        },
        "& .icon-graduation-cap": {
            background:
                "url(/images/icons-marin/header-graduation-cap.svg) no-repeat center center / 20px 20px",
        },
        "& .icon-microphone": {
            background:
                "url(/images/icons-marin/header-microphone.svg) no-repeat center center / 20px 20px",
        },
        "& .icon-hromadas": {
            background:
                "url(/images/icons-marin/header-hromadas.svg) no-repeat center center / 26px 26px",
        },
        "& .icon-item": {
            background:
                "url(/images/icons-marin/header-item.png) no-repeat center center / 12px 12px",
        },
        "& .icon-settings": {
            background:
                "url(/images/icons-marin/header-settings.svg) no-repeat center center/ 17px 17px",
        },
        "& .icon-password": {
            background:
                "url(/images/icons-marin/header-password.svg) no-repeat center center / 15px 15px",
        },
        "& .icon-signout": {
            background:
                "url(/images/icons-marin/header-signout.svg) no-repeat center center / 15px 15px",
            top: "1px",
        },
        "& .icon-calendar": {
            background:
                "url(/images/icons-marin/header-calendar.svg) no-repeat center center/ 17px 17px",
            top: "-1px",
        },
        "& .icon-practice": {
            background:
                "url(/images/icons-marin/header-practice.svg) no-repeat center center / 17px 17px",
        },
    },
    langSelector: {
        marginTop: "10px",
        marginLeft: "25px",
        "&> div": {
            background: "none!important",
        },
        "& .select": {
            float: "right",
        },
        "& .select:before": {
            display: "none",
        },
        "& .select:after": {
            display: "none",
        },
        "& .select > div": {
            padding: "6px 6px 7px",
        },
        "& .select > div > div": {
            height: "2.2vw",
            width: "2.2vw",
            padding: "0",
            textAlign: "center",
            backgroundColor: "#191970",
            borderRadius: "8px",
            textTransform: "uppercase",
            fontWeight: "400",
            color: "#B4C3D1",
            fontSize: "12px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        "& .select > div > div:hover": {
            backgroundColor: "#AFDAE8",
        },
        "& .select > div > svg": {
            display: "none",
        },
    },
    logo:
        Settings.Tenant.IsUlead
            ? {
                marginTop: "10px",
                width: "120px",
                height: "40px"
            }
            : {
                marginTop: "10px",
                height: "70px"
            },
});

class Header extends React.Component {
    static contextType = StoreContext;

    state = {
        showHeader: true,
    };

    componentDidMount() {
        const currentPath = document.location.pathname;
        if (
            currentPath === "/login" ||
            currentPath === "/forgot-password" ||
            currentPath === "/reset-password" ||
            currentPath === "/search/oes" ||
            currentPath === "/search/xs"
        ) {
            this.setState({showHeader: false});
        }
    }

    onSignOutClick = async () => {
        const {routerStore, authStore} = this.context;
        await authStore.signout();
        routerStore.goToLogin();
    };

    handleChangeLanguage = (e) => {
        this.context.userStore.updateLanguage(e.target.value);
    };

    handleChangePhase = (new_phase_number) => {
        return async () => {
            await fetchData("put", "/settings", {
                current_phase_number: new_phase_number,
            });

            window.location.reload();
        };
    };

    getRegisterActiveLinkClass = () => {
        const registerLinks = [
            "/events",
            "/participants",
            "/trainers",
            "/facilitators",
            "/good-practices",
            "/communities",
        ];
        return !!registerLinks.find((item) => item === document.location.pathname)
            ? "active-link"
            : "";
    };

    getIndicatorActiveLinkClass = () => {
        const indicatorLinks = ["/indicators", "/indicator/"];
        return !!indicatorLinks.find((item) =>
            document.location.pathname.includes(item)
        )
            ? "active-link"
            : "";
    };

    getDashboardActiveLinkClass = () => {
        const dashboardLinks = [
            "/dashboard",
            "/graphs",
            "/indicator-graphs",
            "/map",
        ];

        return !!dashboardLinks.find((item) =>
            document.location.pathname.includes(item)
        )
            ? "active-link"
            : "";
    };

    getGoodPracticeActiveLinkClass = () => {
        const goodPracticeLink = "/good-practice";
        return !!document.location.pathname.includes(goodPracticeLink)
            ? "active-link"
            : "";
    };

    getAdministrationActiveLinkClass = () => {
        const administrationLinks = [
            "/user",
            "/maillog",
            "/team",
            "/funding-source",
            "/employer-level",
            "/employer-type",
            "/region",
            "/category",
            "/target-group",
            "/organizer",
            "/format",
            "/event-character",
            "/europe-actions",
            "/thematic-packages",
        ];
        return !!administrationLinks.find(
            (item) => item === document.location.pathname
        )
            ? "active-link"
            : "";
    };

    render() {
        const {userStore, langStore} = this.context;
        const {login, isMinreg, data: currentUser} = userStore;
        const {lang} = langStore;
        let showHeader = this.state;

        if (!currentUser) {
            return "";
        }

        const {classes} = this.props;
        let showAdminMenu =
            currentUser &&
            currentUser.authorities.filter(
                (r) =>
                    [
                        "ROLE_ADMIN",
                        "ROLE_ANALYST",
                        "ROLE_USER",
                        "ROLE_RO",
                        "ROLE_HUB",
                    ].indexOf(r) >= 0
            ).length > 0;
        const isOes =
            currentUser && currentUser.authorities.indexOf("ROLE_OES") >= 0;
        const isGuest =
            currentUser && currentUser.authorities.indexOf("ROLE_GUEST") >= 0;
        const showTrainers = currentUser && !isOes && !isGuest;
        const showFacilitators = currentUser && !isOes && !isGuest;
        const dashboardAllowed = !isMinreg;
        const anotherPhaseNumber = currentUser.currentPhaseNumber == 1 ? 2 : 1;
        const categoryTitle =
            currentUser && currentUser.categoryCaptions[lang.toLowerCase()];

        if (isMinreg && document.location.pathname == "/") {
            showHeader = false;
        }

        return (
            <Typography component="div">
                {showHeader && (
                    <AppBar className={classes.appBar}>
                        <Link to="/">
                            <img
                                src={
                                    Settings.Tenant.IsUlead
                                        ? `/images/U-LEAD_Logo_yellow_light_${lang}.png`
                                        : "/images/3E4u_logo.png"
                                }
                                alt="Logo"
                                className={classes.logo}
                            />
                        </Link>
                        <nav>
                            {!isMinreg && (
                                <NavLink exact className={classes.appBarLink} to="/">
                                    <i className="icon icon-home"></i>
                                    {I18n[lang].header.home}
                                </NavLink>
                            )}

                            <div
                                className={classNames(
                                    classes.appBarLink,
                                    this.getRegisterActiveLinkClass(),
                                    this.getGoodPracticeActiveLinkClass()
                                )}
                            >
                                <i className="icon icon-register"></i>
                                {I18n[lang].header.registries}
                                <div
                                    className={classNames(
                                        classes.appMenu,
                                        "menu-popup",
                                        "register-menu"
                                    )}
                                >
                                    <Typography>
                                        <Link className={classes.appBarMenuLink} to="/events">
                                            <i className="icon icon-calendar"></i>
                                            {I18n[lang].header.events}
                                        </Link>
                                        {!isMinreg && (
                                            <>
                                                <Link
                                                    className={classes.appBarMenuLink}
                                                    to="/participants"
                                                >
                                                    <i className="icon icon-users"></i>
                                                    {I18n[lang].header.participants}
                                                </Link>
                                                {showTrainers && (
                                                    <Link
                                                        className={classes.appBarMenuLink}
                                                        to="/trainers"
                                                    >
                                                        <i className="icon icon-graduation-cap"></i>
                                                        {I18n[lang].header.trainers}
                                                    </Link>
                                                )}
                                                {showFacilitators && (
                                                    <Link
                                                        className={classes.appBarMenuLink}
                                                        to="/facilitators"
                                                    >
                                                        <i className="icon icon-microphone"></i>
                                                        {I18n[lang].header.facilitators}
                                                    </Link>
                                                )}
                                                <Link
                                                    className={classes.appBarMenuLink}
                                                    to="/good-practices"
                                                >
                                                    <i className="icon icon-practice"></i>
                                                    {I18n[lang].header.practice}
                                                </Link>
                                                <Link
                                                    className={classNames(
                                                        classes.appBarMenuLink,
                                                        "link-hromadas"
                                                    )}
                                                    to="/communities"
                                                >
                                                    <i className="icon icon-hromadas"></i>
                                                    {I18n[lang].header.communities}
                                                </Link>
                                            </>
                                        )}
                                    </Typography>
                                </div>
                            </div>

                            {!isMinreg && (
                                <Link
                                    className={classNames(
                                        classes.appBarLink,
                                        this.getIndicatorActiveLinkClass()
                                    )}
                                    to="/indicators"
                                >
                                    <i className="icon icon-indicators"></i>
                                    {I18n[lang].header.indicators}
                                </Link>
                            )}

                            {!isMinreg && (
                                <div
                                    className={classNames(
                                        classes.appBarLink,
                                        this.getDashboardActiveLinkClass()
                                    )}
                                >
                                    <Link to={dashboardAllowed ? "/dashboard" : "/"}>
                                        {" "}
                                        <i className="icon icon-dashboard"/>{" "}
                                    </Link>
                                    <Link to={dashboardAllowed ? "/dashboard" : "</Link>/"}>
                                        {I18n[lang].header.dashboard}{" "}
                                    </Link>
                                    {dashboardAllowed && (
                                        <div
                                            className={classNames(
                                                classes.appMenu,
                                                "menu-popup",
                                                "menu-popup_dashboard"
                                            )}
                                        >
                                            <Typography>
                                                <Link className={classes.appBarMenuLink} to="/graphs">
                                                    <i className="icon icon-item"></i>
                                                    {I18n[lang].header.graphs}
                                                </Link>
                                                <Link className={classes.appBarMenuLink} to="/map">
                                                    <i className="icon icon-item"></i>
                                                    {I18n[lang].header.map}
                                                </Link>
                                            </Typography>
                                        </div>
                                    )}
                                </div>
                            )}

                            <a className={classes.appBarLink} href="/search/oes">
                                <i className="icon icon-search"></i>
                                {I18n[lang].header.searchAnalyse}
                            </a>

                            {!isMinreg && !isGuest && (
                                <NavLink
                                    className={classes.appBarLink}
                                    to="/templates"
                                    activeClassName="active"
                                >
                                    <i className="icon icon-templates"></i>
                                    {I18n[lang].header.templates}
                                </NavLink>
                            )}

                            {showAdminMenu && (
                                <div
                                    className={classNames(
                                        classes.appBarLink,
                                        this.getAdministrationActiveLinkClass()
                                    )}
                                >
                                    <i className="icon icon-administration"></i>
                                    {I18n[lang].header.administration}
                                    <div className={classNames(classes.appMenu, "menu-popup")}>
                                        <Typography>
                                            <Link className={classes.appBarMenuLink} to="/user">
                                                <i className="icon icon-item"></i>
                                                {I18n[lang].header.userManagement}
                                            </Link>
                                            <Link className={classes.appBarMenuLink} to="/maillog">
                                                <i className="icon icon-item"></i>
                                                {I18n[lang].header.mailLog}
                                            </Link>
                                            <Link className={classes.appBarMenuLink} to="/team">
                                                <i className="icon icon-item"></i>
                                                {I18n[lang].header.team}
                                            </Link>
                                            <Link
                                                className={classes.appBarMenuLink}
                                                to="/funding-source"
                                            >
                                                <i className="icon icon-item"></i>
                                                {I18n[lang].header.fundingSource}
                                            </Link>
                                            <Link
                                                className={classes.appBarMenuLink}
                                                to="/employer-level"
                                            >
                                                <i className="icon icon-item"></i>
                                                {I18n[lang].header.employerLevel}
                                            </Link>
                                            <Link
                                                className={classes.appBarMenuLink}
                                                to="/employer-type"
                                            >
                                                <i className="icon icon-item"></i>
                                                {I18n[lang].header.employerType}
                                            </Link>
                                            <Link className={classes.appBarMenuLink} to="/region">
                                                <i className="icon icon-item"></i>
                                                {I18n[lang].header.oblast}
                                            </Link>
                                            <Link className={classes.appBarMenuLink} to="/category">
                                                <i className="icon icon-item"></i>
                                                {categoryTitle || I18n[lang].header.category}
                                            </Link>
                                            <Link
                                                className={classes.appBarMenuLink}
                                                to="/target-group"
                                            >
                                                <i className="icon icon-item"></i>
                                                {I18n[lang].header.targetGroup}
                                            </Link>
                                            <Link
                                                className={classes.appBarMenuLink}
                                                to="/target-level"
                                            >
                                                <i className="icon icon-item"></i>
                                                {I18n[lang].header.targetLevel}
                                            </Link>
                                            <Link className={classes.appBarMenuLink} to="/organizer">
                                                <i className="icon icon-item"></i>
                                                {I18n[lang].header.organizer}
                                            </Link>
                                            <Link className={classes.appBarMenuLink} to="/format">
                                                <i className="icon icon-item"></i>
                                                {I18n[lang].header.eventFormat}
                                            </Link>
                                            <Link
                                                className={classes.appBarMenuLink}
                                                to="/event-character"
                                            >
                                                <i className="icon icon-item"></i>
                                                {I18n[lang].header.eventCharacter}
                                            </Link>
                                            <Link
                                                className={classes.appBarMenuLink}
                                                to="/europe-actions"
                                            >
                                                <i className="icon icon-item"></i>
                                                {I18n[lang].header.regionalInitiatives}
                                            </Link>
                                            <Link
                                                className={classes.appBarMenuLink}
                                                to="/thematic-packages"
                                            >
                                                <i className="icon icon-item"></i>
                                                {I18n[lang].header.thematicSupportPackages}
                                            </Link>
                                            <Link className={classes.appBarMenuLink} to="/cso-grants">
                                                <i className="icon icon-item"></i>
                                                {I18n[lang].header.csoGrants}
                                            </Link>
                                            <Link
                                                className={classes.appBarMenuLink}
                                                to="/donor-projects"
                                            >
                                                <i className="icon icon-item"></i>
                                                {I18n[lang].header.donorProjects}
                                            </Link>
                                        </Typography>
                                    </div>
                                </div>
                            )}

                            {userStore.isGuest && (
                                <NavLink
                                    className={classes.appBarLink}
                                    to="/support"
                                    activeClassName="active"
                                >
                                    <i className="icon icon-support"></i>
                                    {langStore.header.supportFeedback}
                                </NavLink>
                            )}
                            {!userStore.isGuest && (
                                <div className={classes.appBarLink}>
                                    <i className="icon icon-support"/>
                                    {langStore.header.support}
                                    <div className={classNames(classes.appMenu, "menu-popup")}>
                                        <NavLink
                                            to="/support"
                                            className={classes.appBarMenuLink}
                                            activeClassName="active"
                                        >
                                            <i className="icon icon-item"></i>
                                            {langStore.header.contactForm}
                                        </NavLink>
                                        <a
                                            className={classes.appBarMenuLink}
                                            href={forumRoute}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <i className="icon icon-item"></i>
                                            {langStore.header.forum}
                                        </a>
                                    </div>
                                </div>
                            )}
                        </nav>
                        <div className={classes.langSelector}>
                            <Select
                                className="select"
                                value={lang}
                                renderValue={(value) => {
                                    return value === "Ukr" ? "UA" : "ENG";
                                }}
                                onChange={this.handleChangeLanguage}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            backgroundColor: "#4D64A9",
                                            border: "none",
                                            boxShadow: "none",
                                            marginTop: "55px",
                                            marginLeft: "-30px",
                                        },
                                    },
                                }}
                            >
                                <MenuItem value="Eng" className={classes.appBarMenuLink}>
                                    English
                                </MenuItem>
                                <MenuItem value="Ukr" className={classes.appBarMenuLink}>
                                    Українська
                                </MenuItem>
                            </Select>
                        </div>

                        {currentUser && (
                            <div className={classNames(classes.appBarLink, "user-link")}>
                                {login}
                                <i className="icon icon-user"></i>
                                <div
                                    className={classNames(
                                        classes.appMenu,
                                        "menu-popup",
                                        "user-menu"
                                    )}
                                >
                                    <Typography>
                                        <Link
                                            onClick={this.handleChangePhase(anotherPhaseNumber)}
                                            className={classes.appBarMenuLink}
                                            to="#"
                                        >
                                            <i className="icon icon-settings"></i>
                                            Switch to Phase {anotherPhaseNumber}
                                        </Link>

                                        <Link className={classes.appBarMenuLink} to="/settings">
                                            <i className="icon icon-settings"></i>
                                            Settings
                                        </Link>
                                        {!isGuest && (
                                            <Link className={classes.appBarMenuLink} to="/password">
                                                <i className="icon icon-password"></i>
                                                Password
                                            </Link>
                                        )}
                                        <Link
                                            onClick={this.onSignOutClick}
                                            className={classes.appBarMenuLink}
                                            to="#"
                                        >
                                            <i className="icon icon-signout"></i>
                                            Sign out
                                        </Link>
                                    </Typography>
                                </div>
                            </div>
                        )}
                    </AppBar>
                )}
            </Typography>
        );
    }
}

export default withRouter(withStyles(styles)(observer(Header)));
