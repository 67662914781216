import { makeAutoObservable, runInAction } from "mobx";
import { REQUEST_STATES } from "../utils/RequestStates";
import { JWT_EXPIRATION_STATES, checkExpiration } from "../utils/jwt";

const TOKEN_TIMER_INTERVAL = 1000 * 60 * 5;

export class AuthStore {
  token = "";
  errorMessage = "";
  state = REQUEST_STATES.Initial;
  refreshTimer = null;

  constructor(dataFetcher, rootStore) {
    this.dataFetcher = dataFetcher;
    this.rootStore = rootStore;
    this.token = this.dataFetcher.token;
    makeAutoObservable(this, {
      dataFetcher: false,
      rootStore: false,
      refreshTimer: false,
    });
  }

  async initialize() {
    try {
      await this.rootStore.userStore.load();
      this.checkToken();
      this.startTokenRefreshing();
    } catch (ex) {
      const { routerStore } = this.rootStore;
      if (!routerStore.isPublicRoute) {
        routerStore.goToLogin();
      }
    }
  }

  checkToken = () => {
    const tokenState = checkExpiration(this.token);
    if (tokenState === JWT_EXPIRATION_STATES.Expiring) {
      this.refreshToken();
    } else if (tokenState === JWT_EXPIRATION_STATES.Expired) {
      this.rootStore.routerStore.goToLogin();
    }
  };

  startTokenRefreshing() {
    this.refreshTimer = setInterval(this.checkToken, TOKEN_TIMER_INTERVAL);
  }

  stopTokenRefreshing() {
    clearInterval(this.refreshTimer);
  }

  async signin(username, password, rememberMe) {
    this.errorMessage = "";
    this.state = REQUEST_STATES.Pending;

    try {
      const { token } = await this.dataFetcher.signin(
        username,
        password,
        rememberMe
      );
      runInAction(() => {
        this.token = token;
        this.state = REQUEST_STATES.Success;
      });
      this.rootStore.userStore.load();
      this.startTokenRefreshing();
    } catch (err) {
      const { errorMessage } = err.data;
      runInAction(() => {
        this.errorMessage =
          errorMessage ?? "Unknown authentication error. Try again.";
        this.state = REQUEST_STATES.Error;
      });
      throw new Error();
    }
  }

  async signout() {
    this.token = "";
    this.stopTokenRefreshing();
    this.rootStore.userStore.reset();
    try {
      await this.dataFetcher.signout();
    } catch (ex) {}
  }

  async refreshToken() {
    try {
      const { token } = await this.dataFetcher.refreshToken();
      runInAction(() => {
        this.token = token;
      });
    } catch (ex) {}
  }
}
