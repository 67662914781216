import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

const styles = {
  root: {
    display: "flex",
    flexDirection: "row",
    padding: "9px 16px 4px",
    backgroundColor: "#6495ed",
  },
  logo: {
    height: "45px",
  },
};

const GIZPublicHeader = ({ classes }) => {
  return (
    <div className={classes.root}>
      <Link to="/">
        <img
          src="/images/logo-u-lead.svg"
          alt="Logo"
          className={classes.logo}
        />
      </Link>
    </div>
  );
};

export default withStyles(styles)(GIZPublicHeader);
