import React from 'react';

import {
    Grid,
    Card,
    Typography
} from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import {Link} from 'react-router-dom';

import {withStyles} from '@material-ui/core/styles';
import withRoot from '../withRoot';
import {I18n} from '../i18n/';
import classNames from 'classnames';
import Dialogue from '../components/dialogue';
import {Breakpoints} from "../config";
import {config} from "../style/config";

const styles = theme => ({
    root: {
        padding: '0 0 40px',
        width: '100%',
        margin: '0',
        justifyContent: 'space-between',
        [theme.breakpoints.down(Breakpoints.md)]: {
            padding: "0 20px",

        },
    },


    home: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',

        padding: "0 32px",
        [theme.breakpoints.down(Breakpoints.md)]: {
            padding: "0",
            marginBottom: "20px",
        },
    },
    h2: {
        margin: '36px 0 40px!important',
        fontSize: "36px!important",
        lineHeight: '1 !important',
        letterSpacing: '2pt',
        [theme.breakpoints.down(Breakpoints.md)]: {
            paddingLeft: "20px",
            marginBottom: "10px!important",
        },
        '&.registries': {

            [theme.breakpoints.down(Breakpoints.md)]: {
                margin: '104px 0 10px 0!important',
                fontSize: '30px!important',
            }
        },
        '&.extra': {
            letterSpacing: '1pt',
            [theme.breakpoints.down(Breakpoints.md)]: {
                paddingLeft: 0,
            }
        }
    },
    card: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        background: '#eef2f6',
        marginBottom: '40px',
        boxShadow: 'none',
        borderRadius: '10px',
        marginRight: "24px",
        [theme.breakpoints.down(Breakpoints.md)]: {
            marginRight: "0",
            marginBottom: "10px",
        },

        '&:hover': {
            boxShadow: '0 0 0 5px #dce4ec'
        },
        '&.extra': {
            marginBottom: '6px',
            '& h4': {},
            '& p': {
                marginTop: '0'
            },
        }
    },
    cardIcon: {
        width: '6vw',
        flex: '0 0 6vw',
        height: '130px',
        background: 'var(--color)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#fdbb2d',
        [theme.breakpoints.down("sm")]: {
            height: '100px',
            flex: '0 0 100px',
        },
        '& i': {
            display: 'block',
            width: '50px',
            height: '50px',

            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            backgroundSize: '50px 50px'
        },
        '&.extra': {
            // width: '138px',
            // flex: '0 0 138px',
            // height: '138px',


            // '& i': {
            //     width: '62px',
            //     height: '62px',
            //     backgroundSize: '62px 62px',
            //     '&.cardIconName': {
            //         width: '85px',
            //         height: '85px',
            //         backgroundSize: '85px 85px',
            //     }
            // }
        }
    },
    cardIconEvents: {
        backgroundImage: 'url(/images/icons/home-events.svg)'
    },
    cardIconParticipants: {
        backgroundImage: 'url(/images/icons/home-participants.svg)',
        backgroundSize: '70px 70px !important',
        width: '70px !important',
        height: '70px !important'
    },
    cardIconTrainers: {
        backgroundImage: 'url(/images/icons/home-trainers.svg)',
        backgroundSize: '60px 60px !important',
        width: '60px !important',
        height: '60px !important'
    },
    cardIconPractice: {
        backgroundImage: 'url(/images/icons/home-practice.svg)'
    },
    cardIconIndicators: {
        backgroundImage: 'url(/images/icons/home-indicators.svg)'
    },
    cardIconMaps: {
        backgroundImage: 'url(/images/icons/home-bar-chart.svg)',
    },
    cardIconGraphs: {
        backgroundImage: 'url(/images/icons/home-graphs.svg)'
    },
    cardIconSearch: {
        backgroundImage: 'url(/images/icons/home-loupe.svg)'
    },
    cardIconFilter: {
        backgroundImage: 'url(/images/icons/home-filter.svg)'
    },
    cardIconFacilitators: {
        backgroundImage: 'url(/images/icons/home-facilitators.svg)'
    },
    cardIconHromadas: {
        backgroundImage: 'url(/images/icons/home-hromadas.svg)',
        backgroundSize: '70px 70px !important',
        width: '70px !important',
        height: '70px !important'
    },
    cardInfo: {
        padding: '0 22px',
        textAlign: 'left',
        display: 'flex',
        flexDirection: 'column',
        alignSelf:"stretch",
        [theme.breakpoints.down(Breakpoints.md)]: {
            justifyContent: "center",
        },
        '& h4': {
            color: '#778899',
            fontSize: '24px',
            margin: '20px 0 10px',
            [theme.breakpoints.down(Breakpoints.md)]: {
                fontSize: '21px',
                fontWeight: 500,
                margin: '0',
            },
            fontWeight: '500',
            lineHeight: '26px',
            letterSpacing: '0.05em'

        },
        '& p': {
            color: '#778899',
            fontSize: '14px',
            [theme.breakpoints.down(Breakpoints.md)]: {
                fontSize: '10pt'
            },
            fontWeight: '400',
            margin: '5px 0 0',
            lineHeight: '1.2',
            letterSpacing: '1pt',

        }
    },

    [theme.breakpoints.down(Breakpoints.md)]: {
        padding: '0 2.8% 0',
    },
    construction: {
        backgroundImage: 'url(/images/icons/under-construction.png)',
        backgroundPosition: 'right center',
        backgroundRepeat: 'no-repeat',
        paddingRight: '20px'
    }
});


class Home extends React.Component {

    onWelcomeMsgClose() {
        localStorage.setItem('welcomeMsgShown', true)
    }

    render() {
        const {classes, user} = this.props;
        const lang = this.props.lang;
        const isOes = user && (user.authorities.indexOf('ROLE_OES') >= 0)
        const isGuest = user && (user.authorities.indexOf('ROLE_GUEST') >= 0)
        const showTrainers = user && !isOes && !isGuest;
        const showFacilitators = user && !isOes && !isGuest;
        const isMinreg = user && (user.authorities.indexOf('ROLE_MINREG') >= 0);
        const dashboardAllowed = !isMinreg;

        if (!user) {
            return (
                ''
            )
        }

        return (
            <div className={classes.home}>

                <Typography gutterBottom variant="h2" component="h2" className={classNames(classes.h2, 'registries')}
                            style={{'color': '#fdbb2d'}}>
                    {I18n[lang].home.registries}
                </Typography>

                {isGuest && !localStorage.getItem('welcomeMsgShown') && (
                    <Dialogue title={"Dear U-Data User,"}
                              content={"You will be able to preview a number of U-Data’s tools: search and analyse events, municipality passport, events and participants registries, and good practice registry. Please be aware that all data entered during this session will be lost and settings will be reset to defaults on logout.\nYour feedback is highly appreciated. Just click on ‘Feedback’ to share your comments, suggestions or questions."}
                              onClose={this.onWelcomeMsgClose}/>
                )}

                <Grid container className={classes.root} spacing={0} justify="space-evenly" direction="row"
                      alignItems="center">
                    <Grid item xs={12} md={4} alignItems="center">
                        <Link to="/events">
                            <Card className={classes.card}>
                                <div className={classes.cardIcon}>
                                    <i className={classes.cardIconEvents}></i>
                                </div>
                                <div className={classes.cardInfo}>
                                    <h4>{I18n[lang].home.events}</h4>
                                    <Hidden only={['xs', 'sm']}><p>{I18n[lang].home.eventsText}</p></Hidden>
                                </div>
                            </Card>
                        </Link>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Link to="/participants">
                            <Card className={classes.card}>
                                <div className={classes.cardIcon} style={{'backgroundColor': '#fdbb2d'}}>
                                    <i className={classes.cardIconParticipants}></i>
                                </div>

                                <div className={classes.cardInfo}>
                                    <h4>{I18n[lang].home.participants}</h4>
                                    <Hidden only={['xs', 'sm']}><p>{I18n[lang].home.participantsText}</p></Hidden>
                                </div>

                            </Card>
                        </Link>
                    </Grid>
                    {showTrainers && (
                        <Grid item xs={12} md={4}>
                            <Link to="/trainers">
                                <Card className={classes.card}>
                                    <div className={classes.cardIcon} style={{'backgroundColor': '#fdbb2d'}}>
                                        <i className={classes.cardIconTrainers}></i>
                                    </div>

                                    <div className={classes.cardInfo}>
                                        <h4>{I18n[lang].home.trainers}</h4>
                                        <Hidden only={['xs', 'sm']}><p>{I18n[lang].home.trainersText}</p></Hidden>
                                    </div>

                                </Card>
                            </Link>
                        </Grid>
                    )}
                    {showFacilitators && (
                        <Grid item xs={12} md={4}>
                            <Link to="/facilitators">
                                <Card className={classes.card}>
                                    <div className={classes.cardIcon} style={{'backgroundColor': '#fdbb2d'}}>
                                        <i className={classes.cardIconFacilitators}></i>
                                    </div>

                                    <div className={classes.cardInfo}>
                                        <h4>{I18n[lang].home.facilitators}</h4>
                                        <Hidden only={['xs', 'sm']}><p>{I18n[lang].home.facilitatorsText}</p></Hidden>
                                    </div>

                                </Card>
                            </Link>
                        </Grid>
                    )}
                    <Grid item xs={12} md={4}>
                        <Link to="/good-practices">
                            <Card className={classes.card}>
                                <div className={classes.cardIcon} style={{'backgroundColor': '#fdbb2d'}}>
                                    <i className={classes.cardIconPractice}></i>
                                </div>

                                <div className={classes.cardInfo}>
                                    <h4>{I18n[lang].home.practice}</h4>
                                    <Hidden only={['xs', 'sm']}><p>{I18n[lang].home.practiceText}</p></Hidden>
                                </div>

                            </Card>
                        </Link>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Link to="/communities">
                            <Card className={classes.card}>
                                <div className={classes.cardIcon} style={{'backgroundColor': '#fdbb2d'}}>
                                    <i className={classes.cardIconHromadas}></i>
                                </div>

                                <div className={classes.cardInfo}>
                                    <h4>{I18n[lang].home.hromadas}</h4>
                                    <Hidden only={['xs', 'sm']}><p>{I18n[lang].home.hromadasText}</p></Hidden>
                                </div>

                            </Card>
                        </Link>
                    </Grid>
                </Grid>


                <Grid container spacing={0} className={classes.root}>


                    <Grid item xs={12} md={4}>

                        <Typography gutterBottom variant="h2" component="h2" className={classNames(classes.h2, 'extra')}
                                    style={{'color': '#f8d95d'}}>
                            {I18n[lang].home.indicators}
                        </Typography>
                        <Link to="/indicators">
                            <Card className={classNames(classes.card, 'extra')}>
                                <div className={classNames(classes.cardIcon, 'extra')}
                                     style={{'backgroundColor': '#f8d95d'}}>
                                    <i className={classes.cardIconIndicators}></i>
                                </div>

                                <div className={classNames(classes.cardInfo, 'extra')}>
                                    <h4>{I18n[lang].home.program}</h4>
                                    <Hidden only={['xs', 'sm']}><p>{I18n[lang].home.programText}</p></Hidden>
                                </div>

                            </Card>
                        </Link>
                    </Grid>


                    <Hidden only={['xs', 'sm']}>
                        {!isMinreg && (<Grid item xs={12} md={4}>
                                <Typography gutterBottom variant="h2" component="h2"
                                            className={classNames(classes.h2, 'extra')}
                                            style={{'color': '#88adf0'}}>
                                    {I18n[lang].home.dashboard}
                                </Typography>


                                <Link to={dashboardAllowed ? "/dashboard" : '/'}>
                                    <Card className={classNames(classes.card, 'extra')}>
                                        <div className={classNames(classes.cardIcon, 'extra')}
                                             style={{'backgroundColor': '#88adf0'}}>
                                            <i className={classNames(classes.cardIconMaps, 'cardIconName')}></i>
                                        </div>
                                        <div className={classNames(classes.cardInfo, 'extra')}>
                                            <h4>{I18n[lang].home.maps}</h4>
                                            <Hidden only={['xs', 'sm']}><p>{I18n[lang].home.mapsText}</p></Hidden>
                                        </div>
                                    </Card>
                                </Link>
                            </Grid>

                        )}
                    </Hidden>

                    <Grid xs={12} md={4}>
                        <Typography gutterBottom variant="h2" component="h2" className={classNames(classes.h2, 'extra')}
                                    style={{'color': '#add8e6'}}>
                            {I18n[lang].home.search}
                        </Typography>
                        <a href="/search/oes">
                            <Card className={classNames(classes.card, 'extra')}>
                                <div className={classNames(classes.cardIcon, 'extra')}
                                     style={{'backgroundColor': '#add8e6'}}>
                                    <i className={classes.cardIconSearch}></i>
                                </div>
                                <div className={classNames(classes.cardInfo, 'extra')}>
                                    <h4>{I18n[lang].home.oes}</h4>
                                    <Hidden only={['xs', 'sm']}><p>{I18n[lang].home.oesText}</p></Hidden>
                                </div>
                            </Card>
                        </a>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withRoot(withStyles(styles)(Home));
