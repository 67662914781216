import React from "react";
import {
    Typography,
    Grid,
    Button,
    TablePagination,
    Paper,
    TextField,
    FormControlLabel,
    Checkbox,
    Hidden,
    List,
    ListItem,
    SwipeableDrawer,
    Divider,
    IconButton,
    InputAdornment,
    withWidth
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import {withStyles} from "@material-ui/core/styles";

import fetchData from "../../utils/fetch";
import downloadFile from "../../utils/fetchFile";
import Loader from "../../components/loader";
import {Link} from "react-router-dom";
import classNames from "classnames";
import {isEmpty, each} from "lodash";
import Select from "react-select";
import makeAnimated from "react-select/lib/animated";
import "url-search-params-polyfill";
import {WithContext as ReactTags} from "react-tag-input";
import {MuiPickersUtilsProvider, InlineDatePicker} from "material-ui-pickers";

import {I18n} from "../../i18n/";
import history from "../../utils/history";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import TablePaginationActions from "../../components/pagination/CustomPagination";
import CustomSearch from "../../components/search";
import categoryCaptions from "../../utils/categoryCaptions";
import {selectStyles} from "../../style/select-styles";

// import 'react-tag-input/dist/ReactTags.css';
import "react-datepicker/dist/react-datepicker.css";
import styles from "./style";
import {observer} from "mobx-react";
import {StoreContext} from "../../stores/context";
import {GetApp} from "@material-ui/icons";
import CustomTitle from "../../components/title";

const sortOptions = {
    Eng: [
        {value: "startDate,desc", label: I18n["Eng"].search.startDesc},
        {value: "startDate,asc", label: I18n["Eng"].search.startAsc},
        {value: "endDate,desc", label: I18n["Eng"].search.endDesc},
        {value: "endDate,asc", label: I18n["Eng"].search.endAsc},
    ],
    Ukr: [
        {value: "startDate,desc", label: I18n["Ukr"].search.startDesc},
        {value: "startDate,asc", label: I18n["Ukr"].search.startAsc},
        {value: "endDate,desc", label: I18n["Ukr"].search.endDesc},
        {value: "endDate,asc", label: I18n["Ukr"].search.endAsc},
    ],
};

const filterCategories = {
    owners: ["owners", "ownerIds"],
    regions: ["regions", "regionIds"],
    formats: ["formats", "formatIds"],
    target_groups: ["targetGroups", "targetGroupIds"],
    target_levels: ["targetLevels", "targetLevelIds"],
    categories: ["categories", "categoryIds"],
    category_results: ["categoryResults", "categoryResultIds"],
    organizers: ["organizers", "organizerIds"],
    event_characters: ["eventCharacters", "eventCharacterIds"],
    funding_sources: ["fundingSources", "fundingSourceIds"],
    trainers: ["trainers", "trainerIds"],
    europe_actions: ["europeActions", "europeActionIds"],
    training_package_types: ["trainingPackageTypes", "trainingPackageTypeIds"],
    thematic_modules: ["thematicModules", "thematicModuleIds"],
    kind: ["kind", "kinds"]
};

class Search extends React.Component {
    state = {
        loading: true,
        items: [],
        filters: {},
        page: 0,
        total: 0,
        rowsPerPage: 25,
        searchQuery: "",
        order: "desc",
        orderBy: "startDate,desc",
        currentUser: null,
        currentPage: "events",
        owners: {limit: 5, checkedAll: false, uncheckedIds: []},
        regions: {limit: 5, checkedAll: false, uncheckedIds: []},
        formats: {limit: 5, checkedAll: false, uncheckedIds: []},
        target_groups: {limit: 5, checkedAll: false, uncheckedIds: []},
        target_levels: {limit: 5, checkedAll: false, uncheckedIds: []},
        categories: {limit: 5, checkedAll: false, uncheckedIds: []},
        category_results: {limit: 5, checkedAll: false, uncheckedIds: []},
        organizers: {limit: 5, checkedAll: false, uncheckedIds: []},
        event_characters: {limit: 5, checkedAll: false, uncheckedIds: []},
        funding_sources: {limit: 5, checkedAll: false, uncheckedIds: []},
        participants: {limit: 5, checkedAll: false, uncheckedIds: []},
        trainers: {limit: 5, checkedAll: false, uncheckedIds: []},
        europe_actions: {limit: 5, checkedAll: false, uncheckedIds: []},
        training_package_types: {limit: 5, checkedAll: false, uncheckedIds: []},
        thematic_modules: {limit: 5, checkedAll: false, uncheckedIds: []},
        tags: [],
        participantsCount: 0,
        selectedFilters: "",
        startDate: "",
        endDate: "",
        sortingBy: {},
        filteringGetParams: "?",
        kind: {limit: 5, checkedAll: false, uncheckedIds: []},
        filterDrawer: false
    };

    static contextType = StoreContext;

    initialWindowWidth = window.innerWidth;

    componentDidMount() {
        const query = new URLSearchParams(document.location.search);
        const search = query.get("query");

        if (search) {
            this.setState({searchQuery: search}, () => {
                this.getFilteredData("", [], true);
            });
        } else {
            const {
                searchQuery,
                selectedTags,
                selectedFilters,
                xsQuery,
            } = this.props;

            if (isEmpty(searchQuery) && isEmpty(selectedTags)) {
                this.getData();
            } else {
                const newState = {
                    searchQuery,
                    selectedFilters,
                    participantsQuery: xsQuery,
                    tags: selectedTags,
                };
                const date = (selectedTags || []).find((item) => item.id === "date");
                if (date) {
                    newState.startDate = date.startDate;
                    newState.endDate = date.endDate;
                }

                this.setState(newState, () => {
                    this.getFilteredData(selectedFilters, selectedTags, true);
                });
            }
        }
        this.checkAuth();
    }

    async getData() {
        const query = this.props.searchQuery || this.state.searchQuery;
        const queryParam = isEmpty(query) ? "" : "?query=" + query;
        const data = await fetchData("get", `/oes${queryParam}`);
        const filters = await fetchData("get", `/oes/filters${queryParam}`);
        this.setState({
            loading: false,
            items: data[0],
            total: data[1],
            participantsCount: data[2].participantsCount,
            filters: filters[0],
        });
    }

    getParams(selectedFilters, selectedTags) {
        const query = this.state.searchQuery || this.props.searchQuery || "";

        let getParams = "?";
        if (query !== "") {
            getParams += `query=${query}&`;
        }
        if (this.state.orderBy !== "") {
            getParams += `sort=${this.state.orderBy}&`;
        }
        if (selectedFilters !== "") {
            getParams += selectedFilters;
        }
        const participantQueryTag = (selectedTags || []).find(
            (x) => x.id == "participantsQuery"
        );
        if (participantQueryTag) {
            getParams += `participants_query=${participantQueryTag.value}&`;
        }
        return getParams;
    }

    async getFilteredData(selectedFilters, selectedTags, withCounters) {
        let getParams = this.getParams(selectedFilters, selectedTags);

        const data = await fetchData("get", `/oes${getParams}`);

        const newState = {
            loading: false,
            items: data[0],
            total: data[1],
            participantsCount: data[2].participantsCount,
        };
        // UPDATE STATS on filters column
        if (withCounters) {
            const filters = await fetchData("get", `/oes/filters${getParams}`);
            let filtersFinal = filters[0];
            const tagsWithoutCounters = [
                "date",
                "participants",
                "eventsQuery",
                "participantsQuery",
            ];

            selectedTags.forEach((tag) => {
                if (tagsWithoutCounters.indexOf(tag.id) >= 0) {
                    return;
                }

                const filterOptions = filtersFinal[tag.category];
                if (tag.filter && filterOptions) {
                    tag.filter.count = 0;
                    let matchedItem = filterOptions.find((filter) => {
                        return filter.id === tag.filter.id;
                    });
                    if (!matchedItem) {
                        filterOptions.unshift(tag.filter);
                    }
                } else {
                    // case for ALL selected
                }
            });
            newState.filters = filtersFinal;
        }
        this.setState(newState);
    }

    getFiltersFromCheckboxes(collection) {
        const {lang} = this.context.langStore;
        // ownerIds[], owners, owners
        let tag = [];
        let param = [];
        let [camelCasedName, inputName] = filterCategories[collection];
        inputName += "[]";
        const collectionState = this.state[collection];
        if (collectionState.checkedAll) {
            let titles = [];
            const titleColumn = lang == "Eng" ? "title_en" : "title_ua";
            let allTitle = `${lang == "Eng" ? "All" : "Всi"} ${
                I18n[lang].search[camelCasedName]
            }`;
            param.push(inputName + "=0");
            // ! As far as unchecked inputs could be not rendered, we should to look at uncheckedIds for negatives
            collectionState.uncheckedIds.forEach((id) => {
                const item = this.state.filters[collection].find((obj) => obj.id == id);
                titles.push(item[titleColumn] || item["title_en"] || item["title_ua"]);
                param.push(inputName + "=" + id);
            });
            const title = allTitle + (titles.length ? " - " + titles.join(", ") : "");
            tag.push({id: title, text: title, category: collection, filter: null});
        } else {
            // ! Assume that all checked inputs have been rendered
            document
                .querySelectorAll('[name="' + inputName + '"]:checked')
                .forEach((item) => {
                    let title = item.title;
                    const filterSource = this.state.filters[collection].find((obj) => {
                        return obj.title_en === title;
                    });
                    tag.push({
                        id: title,
                        text: title,
                        category: collection,
                        filter: filterSource,
                    });
                    param.push(inputName + "=" + item.dataset.id);
                });
        }
        param = param.join("&") + (param.length ? "&" : "");

        return [param, tag];
    }

    handleFilterChange = (event) => {
        let beforeOpts = {loading: true};
        if (event) {
            // Handle Unchecks fo
            const checkedCollection = event.target.dataset.collection;
            const collectionState = this.state[checkedCollection];
            if (checkedCollection && collectionState && collectionState.checkedAll) {
                const value = parseInt(event.target.value) || event.target.value;
                const valuePos = collectionState.uncheckedIds.indexOf(value);
                if (event.target.checked) {
                    collectionState.uncheckedIds.splice(valuePos, 1);
                } else if (valuePos < 0) {
                    collectionState.uncheckedIds.push(value);
                }
                beforeOpts[checkedCollection] = collectionState;
            }
        }
        this.setState(beforeOpts);

        const participantParams = [
            "participantRegionIds",
            "employerTypeIds",
            "employerLevelIds",
            "genders",
            "communities"
        ];
        const participantTags = [
            "participant_regions",
            "employer_types",
            "employer_levels",
            "genders",
            "communities"
        ];
        let selectedFilters = this.state.selectedFilters
            .split("&")
            .filter((pair) => {
                return !!participantParams.find((p) => pair.indexOf(p) == 0) || !pair;
            })
            .join("&");
        let selectedTags = this.state.tags.filter(
            (tag) => participantTags.indexOf(tag.category) >= 0
        );

        each(filterCategories, (opts, collection) => {
            let [tempA, tempB] = this.getFiltersFromCheckboxes(collection);
            selectedFilters += tempA;
            selectedTags = selectedTags.concat(tempB);
        });

        let {startDate, endDate} = this.state;

        startDate = startDate && moment(startDate).format("YYYY-MM-DD");
        endDate = endDate && moment(endDate).format("YYYY-MM-DD");

        if (startDate) {
            selectedFilters += `startDate=${startDate}&`;
        }
        if (endDate) {
            selectedFilters += `endDate=${endDate}&`;
        }

        if (startDate || endDate) {
            selectedTags.push({
                id: "date",
                text: `Date range: ${startDate} - ${endDate}`,
                startDate: startDate,
                endDate: endDate,
            });
        }

        let participantsFrom = document.querySelector('[name="participantsFrom"]')
            .value;
        let participantsTo = document.querySelector('[name="participantsTo"]')
            .value;

        if (participantsFrom) {
            selectedFilters += `anop_min=${participantsFrom}&`;
        }
        if (participantsTo) {
            selectedFilters += `anop_max=${participantsTo}&`;
        }

        if (participantsFrom || participantsTo) {
            selectedTags.push({
                id: "participants",
                text: `Participants range: ${participantsFrom} - ${participantsTo}`,
            });
        }

        const {searchQuery, participantsQuery} = this.state;
        if (!isEmpty(searchQuery)) {
            selectedTags.push(this.eventsQueryTag(searchQuery));
        }
        if (!isEmpty(participantsQuery)) {
            selectedTags.push(this.participantsQueryTag(participantsQuery));
        }

        this.props.onSearchChange(selectedTags, selectedFilters, searchQuery);
        this.setState({tags: selectedTags, selectedFilters: selectedFilters});
        this.getFilteredData(selectedFilters, selectedTags, true);

        return true;
    };

    handleChangePage = (event, page) => {
        this.setState({loading: true, page: page}, () => {
            let getParams = `page=${page + 1}&`;
            if (this.state.selectedFilters !== "") {
                getParams += this.state.selectedFilters;
            }
            this.getPage(getParams);
        });
    };

    async getPage(getParams) {
        this.getFilteredData(getParams, this.state.tags, false);
        this.scrollToTop();
    }

    handleDownload = (event) => {
        event.preventDefault();
        this.getDownloadableResults(
            `/oes.xlsx${this.getParams(this.state.selectedFilters, this.state.tags)}`
        );
    };

    handleDownloadStats = async (event) => {
        event.preventDefault();
        this.getDownloadableResults(
            `/oes/filters.xlsx${this.getParams(
                this.state.selectedFilters,
                this.state.tags
            )}`
        );
    };

    async getDownloadableResults(path) {
        this.setState({loading: true});
        await downloadFile(path);
        this.setState({loading: false});
    }

    updateInputValue = (e) => this.setState({searchQuery: e.target.value});

    participantsQueryTag = (query) => {
        const {lang} = this.context.langStore;
        return {
            id: "participantsQuery",
            text: `${I18n[lang].search.participants}: ${query}`,
            value: query,
        };
    };

    eventsQueryTag = (query) => {
        const {lang} = this.context.langStore;
        return {
            id: "eventsQuery",
            text: `${I18n[lang].search.events}: ${query}`,
            value: query,
        };
    };

    clearSearchField = () => {
        this.setState({searchQuery: "", participantsQuery: "", loading: false});
        history.push({
            search: ``,
        });
        this.getSearchResults();
    };

    async getSearchResults() {
        const data = await fetchData("get", `/oes`);
        const filters = await fetchData("get", `/xs/filters`);

        this.setState({
            loading: false,
            items: data[0],
            total: data[1],
            participantsCount: data[2].participantsCount,
            filters: filters[0],
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({loading: true});

        const {searchQuery, selectedFilters, participantsQuery} = this.state;

        const tags = this.state.tags.filter((t) => t.id !== "eventsQuery");
        tags.push(this.eventsQueryTag(searchQuery));
        this.getFilteredData(selectedFilters, tags, true);

        history.push({
            search: `?query=${searchQuery}`,
        });

        this.props.onSearchChange(
            tags,
            selectedFilters,
            searchQuery,
            participantsQuery
        );
    };

    handleSort = (orderBy) => {
        let order = "desc";
        if (this.state.orderBy === orderBy.value && this.state.order === "desc") {
            order = "asc";
        }
        this.setState(
            {
                loading: true,
                orderBy: orderBy.value,
                order: order,
                sortingBy: orderBy,
            },
            () => {
                this.getFilteredData(this.state.selectedFilters, this.state.tags);
            }
        );
    };

    changeLimit = (collection, count) => {
        const collectionState = this.state[collection];
        collectionState.limit = count;
        this.setState({[collection]: collectionState});
    };

    handleViewAll = (event) => {
        event.preventDefault();
        this.changeLimit(event.target.dataset.collection, 100000);
    };

    handleCollapse = (event) => {
        event.preventDefault();
        this.changeLimit(event.target.dataset.collection, 5);
    };

    handleSelectAll = (event) => {
        event.preventDefault();
        const collection = event.target.dataset.collection;
        const collectionState = this.state[collection];
        collectionState.checkedAll = true;
        collectionState.uncheckedIds = [];
        this.setState({[collection]: collectionState}, () => {
            this.handleFilterChange();
        });
    };

    handleClearAll = (event) => {
        event.preventDefault();
        this.setState({loading: true});
        this.props.onSearchChange([], '', '', '')

        const collectionStates = {};
        each(filterCategories, (options, collection) => {
            const collectionState = this.state[collection];
            if (!collectionState.checkedAll) {
                return;
            }
            collectionState.checkedAll = false;
            collectionState.uncheckedIds = [];
            collectionStates[collection] = collectionState;
        });
        // only hidden events query should left
        collectionStates.tags = this.state.tags.filter(
            (t) => t.id == "eventsQuery"
        );
        collectionStates.participantsQuery = "";
        collectionStates.startDate = "";
        collectionStates.endDate = "";

        this.setState(collectionStates);

        document
            .querySelectorAll('[type="checkbox"]:checked')
            .forEach((checkbox) => {
                checkbox.removeAttribute("checked");
            });

        this.getData();
    };

    toggleShowFilters = () => {
        this.setState({filterDrawer: !this.state.filterDrawer})
    };

    async checkAuth() {
        const [result] = await fetchData("get", "/account");
        if (!result || !result.id) {
            window.location.href = "/login";
        } else {
            const {lang} = this.context.langStore;
            this.setState({
                currentUser: result,
                lang: lang,
                sortingBy: {
                    value: "startDate,desc",
                    label: I18n[lang].search.startDesc,
                },
            });
        }
    }

    handleDeleteTag = (i) => {
        const {tags} = this.state;
        const selectedTag = tags
            .filter((t) => t.id !== "eventsQuery")
            .filter((tag, index) => index === i)[0];
        const selectedId = selectedTag.id;
        switch (selectedId) {
            case "date":
                this.setState({startDate: "", endDate: ""}, this.handleFilterChange);
                break;
            case "participants":
                this.setState(
                    {participantsFrom: "", participantsTo: ""},
                    this.handleFilterChange
                );
                break;
            case "participantsQuery":
                this.setState({participantsQuery: ""}, this.handleFilterChange);
                break;
            default:
                if (selectedTag.filter) {
                    const checkbox = document.querySelector(
                        '[title="' +
                        selectedId +
                        '"][data-collection="' +
                        selectedTag.category +
                        '"]'
                    );
                    if (checkbox) {
                        checkbox.removeAttribute("checked");
                        checkbox.parentNode.click();
                    } else {
                        const updatedTags = tags;
                        updatedTags.splice(i, 1);
                        this.setState({tags: updatedTags});

                        const toCamel = (s) => {
                            return s.replace(/([-_][a-z])/gi, ($1) => {
                                return $1.toUpperCase().replace("-", "").replace("_", "");
                            });
                        };

                        let selectedFilters = "";
                        updatedTags.forEach((item) => {
                            let category = toCamel(item.category);
                            selectedFilters += `${category}Ids[]=${item.filter.id}&`;
                        });
                        this.setState({selectedFilters, loading: true});
                        this.getFilteredData(selectedFilters, updatedTags, true);
                    }
                } else {
                    const collection = selectedTag.category;
                    const collectionState = this.state[collection];
                    collectionState.checkedAll = false;
                    collectionState.uncheckedIds = [];
                    this.setState({[collection]: collectionState}, () => {
                        this.handleFilterChange();
                    });
                }
        }
    };

    renderFilter = (collection) => {
        const [camelCasedName, inputName] = filterCategories[collection];
        const collectionState = this.state[collection];
        const classes = this.props.classes;
        const {lang} = this.context.langStore;
        const counters = this.state.filters[collection];
        const checkedAll = collectionState.checkedAll;
        const checkedIds = checkedAll
            ? []
            : this.state.tags
                .filter((tag) => tag.category === collection && tag.filter)
                .map((tag) => tag.filter.id);
        const uncheckedIds = checkedAll ? collectionState.uncheckedIds : [];
        const limit = collectionState.limit;
        const isViewAll = limit === 100000;
        let shownCounters = counters;
        if (!isViewAll && counters) {
            shownCounters = counters.slice(0, limit);
            shownCounters = shownCounters.concat(
                counters
                    .slice(limit, counters.length)
                    .filter((filter) => checkedIds.indexOf(filter.id) >= 0)
            );
        }
        return (
            <>
                <Grid item xs={12} className={classes.filterBlock}>

                    <h4>{I18n[lang].search[camelCasedName]}</h4>
                </Grid>
                {counters &&
                    shownCounters.map((counter) => {
                        const checked = checkedIds.indexOf(counter.id) >= 0;
                        const unchecked = uncheckedIds.indexOf(counter.id) >= 0;
                        return (<Grid xs={12} item container>
                                <Grid item className={classes.filter} key={counter.id} xs={10}>
                                    <FormControlLabel
                                        className={classes.controlLabel}
                                        control={
                                            <Checkbox
                                                name={inputName + "[]"}
                                                value={counter.id}
                                                checked={
                                                    (checkedAll && !unchecked) || (!checkedAll && checked)
                                                }
                                                inputProps={{
                                                    title: counter.title_en,
                                                    "data-collection": collection,
                                                    "data-id": counter.id,
                                                }}
                                                onChange={this.handleFilterChange}
                                                icon={<div className="checkbox"></div>}
                                                checkedIcon={
                                                    <div className="checkbox checkbox--checked"></div>
                                                }
                                                className={classes.checkbox}
                                            />
                                        }
                                        label={
                                            lang === "Eng"
                                                ? counter.title_en
                                                : counter.title_ua || counter.title_en
                                        }
                                    />
                                </Grid>

                                {counter.count !== 0 && (
                                    <Grid item xs={2} style={{textAlign: "right"}}
                                          className={classes.count}>{counter.count}</Grid>
                                )}

                            </Grid>
                        )
                            ;
                    })}
                {counters && counters.length > 1 && (
                    <Grid item container xs={12} className={classes.viewAll} style={{display: "flex"}}>
                        {counters.length > 5 && (
                            <Grid item>
                                <a href
                                   data-collection={collection}
                                   className={classes.viewAllLink}
                                   onClick={isViewAll ? this.handleCollapse : this.handleViewAll}
                                >
                                    {isViewAll
                                        ? I18n[lang].search.collapse
                                        : I18n[lang].search.viewAll}
                                </a>
                            </Grid>
                        )}
                        <Grid item style={{flexGrow: "1", textAlign: "end"}}>
                            <a
                                href="#"
                                className={classes.selectAllLink}
                                data-collection={collection}
                                onClick={this.handleSelectAll}
                            >
                                {I18n[lang].search.selectAll}
                            </a>
                        </Grid>
                    </Grid>
                )}

            </>
        );
    };

    handleDate = (field) => {
        return (date) => {
            this.setState({[field]: date}, () => {
                this.handleFilterChange();
            });
        };
    };

    scrollToTop = () => {
        if (window.scrollY != 0) {
            setTimeout(() => {
                window.scrollTo(0, window.scrollY - 30);
                this.scrollToTop();
            }, 5);
        }
    };

    handleDate = (field) => {
        return (date) => {
            this.setState({[field]: date}, () => {
                this.handleFilterChange();
            });
        };
    };

    scrollToTop = () => {
        if (window.scrollY != 0) {
            setTimeout(() => {
                window.scrollTo(0, window.scrollY - 30);
                this.scrollToTop();
            }, 5);
        }
    };

    renderFilterBlock = (asOES) => {
        const classes = this.props.classes;
        const {lang} = this.context.langStore;
        const calendarStyle = {
            color: "#e6feff",
            width: 32,
        };

        const {
            items,
            page,
            rowsPerPage,
            total,
            currentUser,
            tags,
            participantsCount,
            sortingBy,
        } = this.state;
        const isFirstPhase = currentUser?.currentPhaseId === 1;
        return (<>

            <Grid container xs={12}>
                <Grid item xs={12} className={classes.filterBlock}>
                    <h4>{I18n[lang].search.dateRange}</h4>
                </Grid>
                <Grid item xs={6} lg={8}>
                    <div className={classNames(classes.calendar, classes.dateBlock)}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <InlineDatePicker
                                className={classNames(classes.dateField, classes.textField)}
                                format="dd.MM.yy"

                                value={
                                    this.state.startDate !== ""
                                        ? new Date(this.state.startDate)
                                        : null
                                }
                                onChange={this.handleDate("startDate")}
                                labelFunc={this.labelFunc}
                                format="yy/MM/dd"

                                InputProps={{

                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <div className={classes.dataIcon}>
                                                <img
                                                    src="/images/icons/calendar.svg"
                                                    alt="calendar"
                                                    className="date-icon"
                                                />
                                            </div>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                </Grid>

                <Grid item xs={6} lg={8}>
                    <div className={classNames(classes.calendar, classes.dateBlock)}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <InlineDatePicker
                                className={classNames(classes.dateField, classes.textField)}
                                format="dd.MM.yy"

                                value={
                                    this.state.endDate !== ""
                                        ? new Date(this.state.endDate)
                                        : null
                                }
                                onChange={this.handleDate("endDate")}
                                labelFunc={this.labelFunc}
                                format="yy/MM/dd"

                                InputProps={{

                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <div className={classes.dataIcon}>
                                                <img
                                                    src="/images/icons/calendar.svg"
                                                    alt="calendar"
                                                    className="date-icon"
                                                />
                                            </div>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                </Grid>


                {this.renderFilter("owners")}
                {this.renderFilter("regions")}

                {this.renderFilter("formats")}
                {this.renderFilter("target_groups")}
                {this.renderFilter("target_levels")}
                {this.renderFilter("categories")}
                {this.renderFilter("category_results")}

                {this.renderFilter("organizers")}
                {this.renderFilter("funding_sources")}
                {this.renderFilter("event_characters")}

                <Grid item container xs={12} spacing={8}>
                    <Grid item xs={12} className={classes.filterBlock}>
                        <h4>{I18n[lang].search.participants}</h4>
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            label={I18n[lang].search.from}
                            type="number"
                            name="participantsFrom"
                            className={classes.dateField}
                            onChange={this.handleFilterChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label={I18n[lang].search.to}
                            type="number"
                            name="participantsTo"
                            className={classes.dateField}
                            onChange={this.handleFilterChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                </Grid>
                {!asOES && this.renderFilter("trainers")}

                {isFirstPhase && this.renderFilter("europe_actions")}

                {this.renderFilter("training_package_types")}
                {this.renderFilter("thematic_modules")}
                {this.renderFilter("kind")}
            </Grid>


        </>);
    };

    labelFunc = (date) => (date ? moment(date).format("L") : "");

    render() {
        const {classes} = this.props;
        const {
            items,
            page,
            rowsPerPage,
            total,
            currentUser,
            tags,
            participantsCount,
            sortingBy,
        } = this.state;
        const selectStylesWidth = selectStyles(this.props.width);
        const {lang} = this.context.langStore;

        const asOES =
            currentUser &&
            currentUser.authorities.filter(
                (r) => ["ROLE_OES", "ROLE_MINREG", "ROLE_GUEST"].indexOf(r) >= 0
            ).length > 0;

        const isFirstPhase = currentUser?.currentPhaseId === 1;
        const calendarStyle = {
            color: "#e6feff",
            width: 32,
        };
        return (
            <div className={classes.individualPage}>{this.state.loading && <Loader/>}
                <Hidden mdUp>
                    <SwipeableDrawer
                        open={this.state.filterDrawer}
                        onClose={this.toggleShowFilters}
                        onOpen={this.toggleShowFilters}
                        classes={{paper:classes.drawerPaper}}
                    >
                        <Typography component="div">
                            <div className={classes.drawerHeader}>
                                <IconButton onClick={this.toggleShowFilters}>
                                    <CloseIcon/>
                                </IconButton>
                            </div>

                            <Paper className={classes.paper} style={{maxWidth: this.initialWindowWidth + "px"}}>
                                <div className={classes.filterBlock}>
                                    <h3>{I18n[lang].search.eventFilter}</h3>
                                </div>

                                {this.renderFilterBlock(asOES)}
                            </Paper>
                        </Typography>
                    </SwipeableDrawer>
                </Hidden>

                <Hidden mdUp>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography gutterBottom variant="h2" component="h2" className={classes.searchTitle}>
                                {I18n[this.props.lang].header["searchAnalyse"]}
                            </Typography>
                        </Grid>
                    </Grid>
                </Hidden>

                <Grid container direction="row" spacing={16}>
                    <Hidden mdUp>
                        <Grid item xs={12}>
                            <Link to="/search/oes" className={classes.eventsBtn}>
                                <Button
                                    variant={"contained"}
                                    className={classNames(classes.button, classes.oesActive)}
                                >
                                    <img
                                        src="/images/icons/calendar-white.svg"
                                        alt="i"
                                        width="42"
                                    />
                                    {I18n[lang].search.events}
                                </Button>
                            </Link>

                            <Link to="/search/xs" className={classes.participantsBtn}>
                                <Button
                                    variant={"outlined"}
                                    color="primary"
                                    className={classNames(classes.button, classes.xsInactive)}
                                >
                                    <img
                                        src="/images/icons/participants-active.svg"
                                        alt="i"
                                        width="23"
                                    />
                                    {I18n[lang].search.participants}
                                </Button>
                            </Link>
                        </Grid>
                    </Hidden>
                    <Grid item xs={12} md={12} style={{display: "flex"}}>
                        <Hidden only={['xs', 'sm']}>
                            <Link to="/search/oes" className={classes.eventsBtn}>
                                <Button
                                    variant={"contained"}
                                    className={classNames(classes.button, classes.oesActive)}
                                >
                                    <img
                                        src="/images/icons/calendar-white.svg"
                                        alt="i"
                                        width="42"
                                    />
                                    {I18n[lang].search.events}
                                </Button>
                            </Link>

                            <Link to="/search/xs" className={classes.participantsBtn}>
                                <Button
                                    variant={"outlined"}
                                    color="primary"
                                    className={classNames(classes.button, classes.xsInactive)}
                                >
                                    <img
                                        src="/images/icons/participants-active.svg"
                                        alt="i"
                                        width="23"
                                    />
                                    {I18n[lang].search.participants}
                                </Button>
                            </Link>
                        </Hidden>
                        <div style={{flexGrow: "2"}}>
                            <CustomSearch
                                placeholder={I18n[lang].search.search}
                                props={this.props}
                                value={this.state.searchQuery}
                                updateInputValue={this.updateInputValue}
                                submit={this.handleSubmit}
                                state={this.state}
                                clearSearchField={this.clearSearchField}
                            />
                        </div>
                        <Hidden only={['xs', 'sm', 'md']}>
                            <div className={classes.exportButton}>
                                <Link
                                    to="#"
                                    className={classNames(classes.stats, "download")}
                                    onClick={this.handleDownloadStats}
                                >
                                    Export Stats
                                    <GetApp/>
                                </Link>
                            </div>
                            <div className={classes.exportButton}>
                                <Link
                                    to="#"
                                    className={classNames(classes.calendarDownload, "download")}
                                    onClick={this.handleDownload}
                                >
                                    Event calendar
                                    <GetApp/>
                                </Link>
                            </div>
                        </Hidden>
                    </Grid>
                    <Hidden only={['xs', 'sm']}>
                        <Grid item md={3} xl={2}>

                            <Paper className={classes.paper}>
                                {this.renderFilterBlock(asOES)}

                            </Paper>
                        </Grid>
                    </Hidden>
                    <Grid item xs={12} md={9} xl={10}>
                        <Paper className={classes.resultsColumn}>
                            <Grid
                                container
                                xs={12}
                                spacing={24}
                                alignItems="center"
                                className={classes.topFilters}
                                direction="row"
                            >
                                <Grid container item xs={12} spacing={0}>

                                    <Grid item xs={12} md={8} className={classes.resultsCount}>
                                        <div>
                                            {total} {I18n[lang].search.eventsFound}
                                        </div>
                                        <div>
                                            {participantsCount} {I18n[lang].search.participations}
                                        </div>


                                    </Grid>

                                    <Grid item xs={12} md={4} style={{marginTop: "10px"}}>
                                        <Select
                                            styles={selectStylesWidth}
                                            className={classes.select}
                                            closeMenuOnSelect={true}
                                            components={makeAnimated()}
                                            options={sortOptions[lang]}
                                            onChange={this.handleSort}
                                            value={sortingBy}
                                        />
                                    </Grid>
                                    {tags.length > 0 && (
                                        <Grid item xs={12} className={classes.tagsWrapper}>
                                            <ReactTags
                                                tags={tags.filter((t) => t.id !== "eventsQuery" && !t.participant)}
                                                handleDelete={this.handleDeleteTag}
                                                allowDragDrop={false}
                                                classNames={{tag: classes.eventTag}}
                                            />
                                            <ReactTags
                                                tags={tags.filter((t) => t.id !== "eventsQuery" && t.participant)}
                                                handleDelete={this.handleDeleteTag}
                                                allowDragDrop={false}
                                                classNames={{tag: classes.participantTag}}
                                            />
                                        </Grid>)}
                                    <Grid item xs={12} style={{marginTop: "15px"}}>
                                        <Hidden mdUp>
                                            <a
                                                href="#"
                                                className={classes.clearAll}
                                                onClick={this.toggleShowFilters}
                                            >
                                                {I18n[lang].search.showFilters}
                                            </a>
                                            &nbsp;
                                        </Hidden>
                                        <a
                                            href=""
                                            className={classes.clearAll}
                                            onClick={this.handleClearAll}
                                        >
                                            {I18n[lang].search.clearAllFilters}
                                        </a>
                                    </Grid>

                                </Grid>


                            </Grid>

                            <Grid item xs={12}>
                                <List>
                                    {items.map((item, i) => {
                                        return (
                                            <ListItem key={i} className={classes.result}>

                                                <div style={{display:"flex"}}>

                                                    <Hidden only={['xs', 'sm']}>
                                                        <div className={classes.resultPhoto}>
                                                            {item.eventFotoPreviewUrl ? (
                                                                <img src={item.eventFotoPreviewUrl}/>
                                                            ) : (
                                                                <div className={classes.resultPhotoPlaceHolder}></div>
                                                            )}
                                                        </div>
                                                    </Hidden>

                                                    <Grid container className="info">
                                                        <Grid item xs={12}>
                                                            <Link to={`/events/view/${item.id}`} target="_blank"
                                                                  className={classes.resultTitle}>
                                                                {lang === "Eng" ? item.nameEn : item.nameUa}
                                                            </Link>
                                                        </Grid>
                                                        <Grid item xs={12} className={classes.resultSubtitle}>
                                                            {item.startDate} - {item.endDate} |
                                                            {lang === "Eng" ? item.oblastEn : item.oblastUa} |
                                                            {lang === "Eng" ? item.format.en : item.format.ua} |
                                                            {item.participantsCount}
                                                        </Grid>
                                                        <Grid item xs={12} className={classes.resultDescription}>
                                                            <p>{lang === "Eng" ? item.descEn : item.descUa}</p></Grid>
                                                    </Grid>
                                                </div>


                                            </ListItem>
                                        );
                                    })}
                                </List>
                                <Grid item xs={12}>
                                    <TablePagination
                                        className={classes.pagination}
                                        labelDisplayedRows={({from, to, count}) =>
                                            `Showing ${from} - ${to} of ${count} items`
                                        }
                                        component="div"
                                        count={total}
                                        rowsPerPage={rowsPerPage}
                                        rowsPerPageOptions={[]}
                                        page={page}
                                        onChangePage={this.handleChangePage}
                                        ActionsComponent={TablePaginationActions}
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>

            </div>
        )
            ;
    }
}

export default withStyles(styles)(observer(withWidth()(Search)));
