import React from "react";
import classNames from "classnames";
import { Field } from "react-final-form";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const styles = {
  root: {
    display: "flex",
    alignItems: "flex-start",
    margin: "0 0 20px 0",
    paddingLeft: "7px",
  },
  checkbox: {
    padding: 0,
  },
  checked: {
    color: "#6495ed !important",
  },
  error: {
    position: "relative",
    "&::before": {
      content: "''",
      display: "block",
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      width: "7px",
      backgroundColor: "#fe8368",
    },
  },
};

export const GIZCheckboxField = withStyles(styles)(({
  classes,
  name,
  label,
  validator = null,
  disabled = false,
  className = null,
}) => {
  return (
    <Field name={name} validate={validator} type="checkbox">
      {({ input, meta }) => {
        const { value, onChange, onBlur, onFocus } = input;
        return (
          <FormControlLabel
            control={
              <Checkbox
                checked={value}
                onChange={onChange}
                onBlur={onBlur}
                onFocus={onFocus}
                disabled={disabled}
                classes={{ checked: classes.checked }}
                className={classes.checkbox}
              />
            }
            label={label}
            className={classNames(classes.root, className, {
              [classes.error]:
                meta.invalid && (meta.touched || (meta.visited && meta.dirty)),
            })}
          />
        );
      }}
    </Field>
  );
});
