import {config} from "./config";
import {Breakpoints} from "../config";

const styles = (theme) => ({
    root: {
        textAlign: "left",
        backgroundColor: "#f7f9fb",

        "& a": {
            textDecoration: "none",
        },
        "& h2": {
            textTransform: "uppercase !important",
            fontWeight: "700",
            color: "#ECBC2D",
            fontSize: config.pageHeader.fontSize,
            margin: "16px 0 10px 18px",
            [theme.breakpoints.down(Breakpoints.md)]: {
                fontSize: config.small.pageHeader.fontSize,
            },
        },
        "& .modal-actions": {
            textAlign: "right",
            "& .button-cancel": {
                marginRight: "20px",
            },
        },

        "& .btnIndex": {
            display: "inline-block",
            width: "45px",
            height: "39px",
            lineHeight: "36px",
            marginRight: "26px",
            position: "relative",
            background: "#9BD9C8 no-repeat center center / 50px 50px",
            borderRadius: config.input.borderRadius,

            "&.btn-download": {
                fontSize: "14px",
                width: "auto",
                marginTop: "10px",
                color: "#ffffff",
                background: "#77d2b7",
                padding: "0 16px 0 60px",
                letterSpacing: 0.3,
                fontWeight: "400",
                "&:hover": {
                    background: "#9bd9c8",
                },

                "& i": {
                    width: "60px",
                    height: "100%",
                    display: "inline-block",
                    background:
                        "url(/images/icons/icon-download.svg) no-repeat center center",
                    position: "absolute",
                    left: "0",
                    top: "0",
                },
            },
        },
        "& div": {
            boxShadow: "none",
        },
        "& .block-measurement .btn .btn-word": {
            background: "#958DAE no-repeat center center !important",
        },
        "& .actions": {
            whiteSpace: "nowrap",
            display:"flex",
            justifyContent:"flex-end",
            "& a": {
                color: "#fff",
                fontSize: "12px",
                minHeight: "25px",
                lineHeight: "25px",

                minwidth:"52px",

                display:"flex",
                justifyContent:"center",
                alignItems:"center",

                [theme.breakpoints.down(Breakpoints.md)]: {
                    height: "32px",
                },
                backgroundColor: "#6495ed",

                "& i": {
                    display: "block",
                    width: "16px",
                    height: "16px",
                    marginRight: "9px",
                },
                "&.view": {
                    "& i": {
                        width: "19px",
                        background:
                            "url(/images/icons/icon-action-view.svg) no-repeat 0 center / 19px 20px",
                    },
                },
                "&.edit": {
                    backgroundColor: "#6495ed !important",
                    "& i": {
                        top: "-2px",
                        background:
                            "url(/images/icons/icon-action-edit.svg) no-repeat 0 0 / 20px 20px",
                    },
                },
                "&.delete": {
                    backgroundColor: "#fe8368 !important",
                    "& i": {
                        background:
                            "url(/images/icons/icon-action-delete.svg) no-repeat 0 0 / 14px 14px",
                    },
                },
                "&:first-child": {
                    backgroundColor: "#add8e6",
                    borderRadius: "6px 0 0 6px",
                },
                "&:last-child": {
                    backgroundColor: "#fe8368",
                    borderRadius: "0 6px 6px 0",
                },
            },
            "& svg": {
                width: "15px",
                height: "15px",
                marginRight: "2px",
            },
        },
    },
    controllButtons: {
        marginTop: "24px!important",
    },
    indexSearchField:
        {
            marginTop:"10px",
            paddingLeft: "28px",
            [theme.breakpoints.down(Breakpoints.md)]: {
                marginTop:"0px",
                paddingLeft: "16px",
                marginRight: "16px",
            },
        },
    tableCard: {
        width: "100%",
        marginBottom: "7px",
        "& > div":
            {
                padding: "10px",
            }
    },

    tableCardItem: {
        padding: "3px 0",

    },
    cardActions: {
        display: "flex",
        justifyContent: "flex-end",
        "&  a i": {
            marginRight: "0 !important",
        },
        "&  a": {
            height: "32px",
            width: "32px",
            alignItems: "center",
            justifyContent: "center",

            display: "inline-flex",
            color: "#fff",
            fontSize: "12px",
            minHeight: "25px",
            lineHeight: "25px",
            backgroundColor: "#6495ed",
            margin: 0,

        },
        "&  a.edit": {
            backgroundColor: "#6495ed !important",
            "&:hover": {
                backgroundColor: "#5486e0 !important",
            },
        },
        "&  a.view": {
            backgroundColor: "#add8e6",
            "&:hover": {
                backgroundColor: "#9bcfe0",
            },
        },

        "& a i": {
            display: "block",
            width: "14px",
            height: "14px",
            marginRight: "9px",
        },
        "& a.view": {
            "& i": {
                width: "16px",
                background:
                    "url(/images/icons/icon-action-view.svg) no-repeat 0 center / 16px 16px",
            },
        },
        "& a.edit": {
            backgroundColor: "#6495ed !important",
            "& i": {
                width: "16px",
                top: "-2px",
                marginLeft: "4px",
                background:
                    "url(/images/icons/icon-action-edit.svg) no-repeat 0 0 / 16px 16px",
            },
        },
        "& a.delete": {
            backgroundColor: "#fe8368 !important",
            "& i": {
                background:
                    "url(/images/icons/icon-action-delete.svg) no-repeat 0 0 / 14px 14px",
            },
        },

        "&  a.delete": {
            backgroundColor: "#fe8368 !important",
            "&:hover": {
                backgroundColor: "#f67a5f !important",
            },
        },

        "& a:first-child": {
            backgroundColor: "#add8e6",
            borderRadius: "6px 0 0 6px",
        },
        "& a:last-child": {
            backgroundColor: "#fe8368",
            borderRadius: "0 6px 6px 0",
        },


    },

    layoutColumn:
        {
            "& > div":
                {
                    paddingRight: "20px",
                    paddingBottom: "20px",
                    [theme.breakpoints.down(Breakpoints.md)]: {
                        paddingRight: "0",
                    },
                }

        },
    cardTableRow: {
        display: "flex",
    },

    cardTableHeader: {
        flexBasis: "50%",
        marginBottom: "10px",

        fontWeight: config.small.label.fontWeight,
        fontSize: config.small.label.fontSize,
        lineHeight: "19px",

        "& span:hover": {
            color: "#191970 !important",
        },

    },
    cardTableValue: {
        marginBottom: "10px",

        fontStyle: "normal",
        fontWeight:  config.small.input.fontWeight,
        fontSize: config.small.input.fontSize,
        lineHeight: "19px",
        flexBasis: "60%",
    },
    paper: {
        height: "100%",
        padding: "10px 20px",

        boxShadow: "none!important",
        borderRadius: "10px!important",

        [theme.breakpoints.down(Breakpoints.md)]: {
            padding: "0 8px",
        },
        "& ul": {
            color: config.input.color,
            fontFamily: config.fontFamily,
        },
        "& h2": {
            margin: "-4px -1px 5px !important",
        },
        "& h3": {
            marginLeft: 0,
        },

        "& div fieldset:hover": {
            borderColor: "rgba(0, 0, 0, 0) !important",
        },
        "& div fieldset": {
            borderColor: "rgba(0, 0, 0, 0) !important",
        },
    },

    title: {
        "& div h2": {
            fontSize: config.pageHeader.fontSize,
            // marginBottom: "2rem",
            marginLeft: "28px",
            [theme.breakpoints.down(Breakpoints.md)]: {
                marginLeft: "16px",
                marginTop: "100px",
                fontSize: config.small.pageHeader.fontSize,
                fontWeight:  config.small.pageHeader.fontWeight,
                lineHeight: "29px",
                // marginBottom: 0,
            },
        },
    },

    buttonGrey: {
        backgroundColor: "#789",
        justifyContent: "space-between",
        "&:hover": {
            backgroundColor: "#789",
            opacity: "0.9",
        },
        "& span svg": {
            width: "20px",
        },
        // "& span": {
        //   fontSize: "12px",
        //   paddingRight: "5px",
        // },
        "& i": {
            display: "inline-block",
            width: "16px",
            height: "16px",
            marginRight: "10px",
            background: "url(/images/icons/print-icon.png) no-repeat 0 0 / 16px 16px",
        },
    },
    individualPage: {
        margin: "24px",
        [theme.breakpoints.down(Breakpoints.md)]: {
            paddingTop: "80px",
            margin: "12px",
        },

        "& ul":
            {
                listStyle: "none",
                paddingLeft: "0",

            }
    },
    // general layouts
    layout:
        {
            minHeight: "100vh",
            alignItems: "stretch",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",

            background: "#F7F9FB",
            [theme.breakpoints.down(Breakpoints.md)]: {
                minWidth: "300px",
            },
        },
    content: {
        background: "#F7F9FB",
    },

    buttonDialogHeader: {
        marginRight: 30,
        [theme.breakpoints.down(Breakpoints.md)]: {
            marginLeft: "16px",
        }

    },

    indexHead: {
        textAlign: "center",
        color: "#88adf0",
        margin: "5px 0",
        letterSpacing: "0",
        fontSize: "36pt",
    },

    indexTable: {
        maxWidth: "100%",
        textAlign: "center",

        "& thead tr th": {
            padding: "4px 12px",
            fontSize: config.table.header.fontSize,
            color: config.table.header.color,
            fontWeight: config.table.header.fontWeight,
            whiteSpace: "nowrap",

            "& span:hover": {
                color: "#3366cc !important",
            },
            "& span:focus": {
                color: "#3366cc !important",
            },
            "& span:active": {
                color: "#3366cc !important",
            },
        },
        "& tbody tr": {
            verticalAlign: "top",
        },
        "& tbody tr td": {
            padding: "12px",

            fontSize: config.table.data.fontSize,
            color: config.table.data.color,
            fontWeight: config.table.data.fontWeight,

            "&.actions": {
                textAlign: "right",
                "& a": {
                    minWidth: "52px",
                    height: "42px",
                    alignItems: "center",
                    justifyContent: "center",
                    "& i": {
                        marginRight: "0",
                    },
                },
            },

            "& a": {
                // padding: '0 20px',
                display: "inline-block",

            },
        },
        "& thead tr th:first-child, tbody tr td:first-child": {
            paddingLeft: "28px!important",
        },

        "& thead tr th:last-child, tbody tr td:last-child": {
            paddingRight: "26px!important",
        },
        "& a": {
            color: config.table.data.color,
            "&:hover": {
                textDecoration: "underline",
            },
        },
    },

    buttonRemoveSmall: {
        padding: "7px 11px!important",
        marginRight: "0!important",
        borderRadius: "6px!important",
    },

    inlineTable: {
        marginLeft: "13px",
        width: "-webkit-fill-available",
        textAlign: "center",
        "& td,& td a": {
            fontSize: config.label.fontSize,
            color: config.table.data.color,
        },
        "& td a": {
            cursor: "pointer",
        },
        "& thead tr": {
            height: "32px",
        },
        "& thead tr th": {
            paddingBottom: "0!important",

            color: "#6495ed",
            fontSize: "16px",

            padding: "4px 15px",
            whiteSpace: "nowrap",

            fontWeight: "300",

        },

        "& tbody tr td": {
            padding: "15px",

            fontSize: config.label.fontSize,
            color: "#212161",
        },
    },

    idColumn: {
        width: "70px"
    },

    centerCell: {
        textAlign: "center!important",
    },

    singleViewAction: {
        backgroundColor: "#add8e6!important",
        borderRadius: "0.5rem!important",
    },
    actions: {
        whiteSpace: "nowrap",
        "& a": {
            display: "inline-flex",
            color: "#fff",
            fontSize: "12px",
            lineHeight: "25px",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#6495ed",
            padding: "12px 0",
            width: "auto",
            minWidth: "96px",
            "&:first-child": {
                backgroundColor: "#add8e6!important",
                borderRadius: "0.5rem 0 0 0.5rem",
            },
            "&:last-child": {
                backgroundColor: "#fe8368",
                borderRadius: "0 0.5rem 0.5rem 0",
            },
        },
        "& svg": {
            width: "15px",
            height: "15px",
            marginRight: "2px",
        },
    },

    tableWrapper: {
        overflow: "hidden",
        width: "100%",
        overflowX: "auto",
    },
    pagination: {
        marginTop: 20,
        color: "#BDBEC0",
        "& div:first-child": {
            display: "flex",
            "flex-direction": "column",
            "align-items": "center",
        },
        [theme.breakpoints.down(Breakpoints.md)]: {
            marginTop: "-10px",
        }
    },
    alignRight: {
        textAlign: "right",
    },

    indexCreate: {
        textAlign: "right",
        paddingRight: "26px",
        marginTop: "14px",
        [theme.breakpoints.down(Breakpoints.md)]: {
            paddingRight: "0",
            marginBottom: "12px",
            marginRight: "16px",
            marginTop: "0",
        },
        "& a button.btn-create": {
            boxShadow: "none",
            padding: "10px 15px",
            borderRadius: "2rem",
            background: "#6495ed",
            marginRight: "0",
            "&:hover": {
                background: "#5486e0",
            },
            "& svg": {
                width: "1.2em",
                height: "1.2em",
                marginRight: "5px",
            },
        },

    },

    indexTooltip: {
        display: "inline-block",
    },

    error: {
        color: config.error.color,
    },

    // general editors
    //error on radios
    groupError: {
        "& label span:first-child": {
            "& svg": {
                fill: config.error.color,
            },
        },
    },

    //calendar
    calendar: {
        minWidth: "145px",
        maxHeight: "55px",

        "& .transparent input": {
            color: "transparent !important",
        },
    },
    dateField: {

        "& input": {
            padding: "6px",
        },

        '& input[type="date"]': {
            color: "#778899 !important",
        },
        '& input[type="date"]::-webkit-calendar-picker-indicator': {
            color: "rgba(0, 0, 0, 0)",
            opacity: "1",
            display: "block",
            background:
                "#add8e6 url(/images/icons/calendar.svg) no-repeat center center",
            backgroundSize: "30px 30px",
            width: "35px",
            height: "35px",
            borderWidth: "thin",
            position: "absolute",
            right: "-15px",
            top: "-4px",
            borderRadius: "0 0.25rem 0.25rem 0",
        },

        '& input[type="date"]::placeholder': {
            color: "#778899 !important",
        },
        '& input[type="date"]::-webkit-datetime-edit-year-field': {
            color: "#778899 !important",
        },
        '& input[type="date"]::-webkit-datetime-edit-month-field': {
            color: "#778899 !important",
        },
        '& input[type="date"]::-webkit-datetime-edit-day-field': {
            color: "#778899 !important",
        },
        "& > div:before": {
            display: "none",
        },
        "& > div:after": {
            display: "none",
        },
        "& > div": {
            background: config.input.background,
            height: "32px",
        },
    },
    dataIcon: {
        color: "#e6feff",
        background: "#add8e6",
        width: "30px",
        height: "30px",
        borderRadius: config.input.borderRadius,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: "2",
        position: "relative",
        cursor: "pointer",
        "& img.date-icon": {
            fill: "#e6feff",
            width: "20px",
        },
        "&img mg.date-icon:hover": {
            fill: "#e0f3fe",
        },
        [theme.breakpoints.down(Breakpoints.md)]: {
            height: "32px"
        },
    },

    //text
    textField: {
        // background: config.input.background,
        // borderRadius: config.input.borderRadius,
        // fontFamily: config.input.fontFamily,
        // fontSize: config.input.fontSize,
        // "&:hover": {
        //   boxShadow: "inset 0 0 0px 2px #6495ed",
        // },
    },
    numberInput: {
        "& input": {
            [theme.breakpoints.down(Breakpoints.md)]: {
                paddingBottom: "9px",
                paddingTop: "9px",
            },
        },
    },

    errorField: {
        borderLeft: "7px solid #fe8368",
        borderTopLeftRadius: config.input.borderRadius,
        borderBottomLeftRadius: config.input.borderRadius,

        "& input, fieldset, > div": {
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
        },
    },

    //for URLS in Events view
    formButton: {
        fontSize: "0.8rem",
        fontWeight: "700",
        color: "#ffffff",
        backgroundColor: "#add8e6",
        display: "inline-block",
        marginBottom: "0",
        padding: "5px 10px",
        borderRadius: config.input.borderRadius,
        cursor: "pointer",
        border: "none",
        float: "right",
        fontFamily: config.input.fontFamily,
        "& svg":
            {
                fontSize: "16px",
                fontWeight: "700px",

            }
    },
    mediaReference: {
        // padding: "10px 10px 0",
        // marginBottom: "10px",
        // '& input[type="text"]': {
        //   width: "100%",
        //   marginBottom: "20px",
        //   background: "#e1edfb",
        //   border: "none",
        //   padding: "10px 14px",
        //   fontSize: config.label.fontSize,
        //   borderRadius: "3px",
        // },
    },

    groupSelect: {
        // Events groupimgs in select
        "& .groupStyles": {
            color: "#fff",

            fontSize: config.input.fontSize,
        },
    },

    // grouping for radios
    group: {
        "&:hover": {
            boxShadow: "none !important",
        },
        "& label span:first-child": {
            padding: "5px 0px 5px 5px",
            "& svg": {
                height: "15px",
            },
        },
        "& label span:last-child": {
            color: "#191970",
            fontSize: config.input.fontSize,
            fontWeight: "400",
            letterSpacing: "0.5px",
        },
    },

    inputFile: {
        "& label": {
            display: "block",
            marginBottom: "10px",
            "& svg": {
                marginRight: "10px"
            },
        },
        "& input": {
            display: "block",
            width: "0.1px",
            height: "0.1px",
            opacity: "0",
            overflow: "hidden",
            zIndex: "-1",
        },
        "& input + label": {
            fontSize: "0.8rem",
            fontWeight: "700",
            color: "#fff",
            backgroundColor: "#add8e6",
            display: "inline-block",
            marginBottom: "0",
            padding: "4px 10px!important",
            borderRadius: config.input.borderRadius,
            cursor: "pointer",
            border: "none",
            fontFamily: config.input.fontFamily,

            width: "120px",
            textAlign: "center",
        },
        "& span": {
            marginLeft: "5px",
            fontSize: "10px",
            color: "gray",
            fontWeight: "700",
        },
        "& p": {
            marginTop: "4px",
            fontSize: "10px",
            color: "gray",
            fontWeight: "700",
            width: "120px",
            textAlign: "center",
        },
    },

    modal: {
        overflowX: "hidden",
        backgroundColor: "#f7f9fb",
        boxShadow: theme.shadows[5],
        padding: "25px",
        width: "94%",
        height: "82vh !important",
        overflowY: "auto",
        borderRadius: "10px",
        "&:focus": {
            outline: "0",
        },

        "& .close": {
            marginTop: "-41px",
            top: "35px",
            color: "#b4c3d1",
            cursor: "pointer",
            position: "relative",
            float: "right",
            [theme.breakpoints.down(Breakpoints.md)]: {
                //   marginRight: "-24px",
            },
        },
        [theme.breakpoints.down(Breakpoints.md)]: {
            width: "100% !important",
            height: "100% !important",
            paddingTop: "10px!important",

        },
    },

    // button inside textbox
    checkBtn: {
        height: "30px",
        width: "160px",
        position: "relative",
        left: "18px",
        borderRadius: "0 10px 10px 0",
        fontFamily: "Montserrat, sans-serif",
    },

    h84: {
        height: "65px",
        padding: "0 12px !important",
    },

    map: {
        height: "300px",
        "& .leaflet-control-geosearch.bar": {
            width: "90%",
        },
        "& .leaflet-top .leaflet-control": {
            marginTop: "225px",
        },
        "&.error-field": {
            border: "1px solid #fe8368 !important",
            borderLeftWidth: "5px !important",
        },
        "& .leaflet-control-geosearch form input":
            {
                fontFamily:config.fontFamily,
                fontSize: config.input.fontSize,
                fontWeight: config.input.fontWeight,
                color: config.input.color,
                [theme.breakpoints.down(Breakpoints.md)]: {
                    fontSize: config.small.input.fontSize,
                    fontWeight: config.small.input.fontWeight,
                },

            }
    },

    listItem: {
        display: "flex",
        alignItems: "flex-start",
        "& > div": {
            flexGrow: 1,
        },
        "& > a": {
            whiteSpace: "nowrap",
            color: "6495ed",
            "&:hover": {
                textDecoration: "none",
            },
        },
    },

    phoneCell: {
        marginBottom: "6px",
        "& span": {
            "&:first-child": {
                color: "#77d2b7",
                paddingTop: "12px",
                paddingBottom: 0,
            },
            "&:last-child": {
                marginTop: "24px",
                marginBottom: "0px",
                lineHeight: "17px",
                color: config.label.color,
                fontSize: config.label.fontSize,
                fontWeight: config.label.fontWeight,
                letterSpacing: config.label.letterSpacing,
            },
        },
    },

    checkboxLabel: {
        "& span": {
            "&:first-child": {
                color: "#77d2b7",
            },
            "&:last-child": {
                color: config.label.color,
                fontSize: config.label.fontSize,
                fontWeight: config.label.fontWeight,
                letterSpacing: config.label.letterSpacing,
            },
        },
    },

    // search on index pages

    searchEvent: {
        // width: "37%",
        background: "#eef2f6",
        marginBottom: "20px",
        marginTop: "0",
        [theme.breakpoints.down(Breakpoints.md)]: {
            marginBottom: "0",
        },
        "& >div": {
            padding: "0",
            "& div": {
                height: "100%",
                maxHeight: "100%",
                position: "absolute",
                right: "0",
            },
        },
        "& label": {
            top: "-6px",
            fontWeight: 100,
            color: "#778899 !important",
            fontSize: "16px",
        },
        "& fieldset": {
            border: "none",
        },

        borderRadius: "0.5rem",
        fontWeight: "100",
        "& input": {
            background: "#eef2f6",
            fontWeight: 100,
            padding: "10px 14px",
            fontSize: "16px",

        },
    },

    deleteSearch: {
        "& a": {
            color: "#9daab7",
            "& svg": {
                width: "1.5em",
                height: "1.5em",
                marginRight: "5px",
                background: "url(/images/trash.svg) no-repeat center center",
                backgroundSize: "80% 80%",
                "& path": {
                    fill: "transparent",
                },
                [theme.breakpoints.down(Breakpoints.md)]: {
                    width: "1em",
                    height: "1em",
                },
            },
        },
    },
    btnSearch: {
        borderRadius: "0 0.5rem 0.5rem 0",
        background: "#ECBC2D",
        padding: "5px 10px",
        fontSize: "16px",
        color: "#ffffff",
        height: "100%",
        fontFamily: config.fontFamily,
        "& path": {
            fill: "transparent",
        },

        "&:hover": {
            background: "#f9c908",
        },
        "& svg": {
            width: "1em",
            height: "1em",
            background: "url(/images/loupe.svg) no-repeat center center",
            backgroundSize: "250% 250%",
        },
    },

    // for communities view
    // indicator charts
    indicatorsCircle: {
        marginTop: "5px",
        height: "10px",
        width: "10px",
        backgroundColor: "#c9cfd6",
        borderRadius: "50%",
        display: "inline-block",
    },
    indicatorsCircleYellow: {
        backgroundColor: config.yellow,
    },
    indicatorsCircleRed: {
        backgroundColor: config.red,
    },

    detailTable: {
        "& thead tr th span": {
            color: "#88adf0",
            fontSize: "16px",
            fontFamily: "Montserrat, sans-serif",

            fontWeight: 400,
            letterSpacing: "0.7px",
        },
        "& tbody tr td": {
            padding: "10px!important",
        },
    },

    eventTable: {
        "& thead tr th:first-child": {
            paddingLeft: 17,
            width: "77px",
        },
        "& thead tr th:nth-child(2)": {
            width: "100px",
        },

        "& thead tr th:nth-child(4)": {
            width: "150px",
        },
        "& thead tr": {
            height: "32px",
            verticalAlign: "bottom",
        },
        "& thead tr th": {
            padding: "0 15px 0 10px",
        },
        "& tbody tr td:first-child": {
            padding: "10px 15px",
        },
        "& tbody tr td:nth-child(2)": {
            width: "115px",
        },
        "& thead tr th:nth-child(3)": {
            width: "28.5%",
        },
        "& tbody tr td:nth-child(4)": {
            width: "150px",
        },
        "& thead tr th:nth-child(5)": {
            width: "17%",
        },
        "& thead tr th:last-child": {
            width: "20%",
        },
        "& tbody tr": {
            height: "54px",
            verticalAlign: "top",
        },
    },
    "& div.participants-table": {
        "& thead tr th:first-child": {
            width: "230px",
        },
        "& thead tr th:nth-child(2)": {
            width: "150px",
        },
        "& thead tr th:nth-child(3)": {
            width: "20%",
        },
        "& thead tr th:nth-child(4)": {
            width: "auto",
        },
        "& tbody tr td:first-child": {
            width: "230px",
            padding: "0 0 0 17px",
        },
        "& tbody tr td:nth-child(2)": {
            width: "150px",
        },
        "& thead tr td:nth-child(3)": {
            width: "20%",
        },
        "& tbody tr td:nth-child(4)": {
            width: "auto",
        },
    },
    wauto: {
        width: "auto !important",
    },

    adjustTwoRight: {
        marginRight: "2px",
    },

    // by setting xs=true on griditem with multiselects, we need to switch off flex-grow
    noFlexGrow: {
        flexGrow: "unset",
    },
    searchInField: {
        height: "auto",
        marginLeft: 0,
        "& button": {
            paddingTop: "3px",
            paddingBottom: "3px",
        },
    },
    labelInGrid: {
        marginTop: "4px",
    },
    noCapitalize: {
        textTransform: "none!important",
    },
    infoMap: {
        "& a": {
            borderBottom: "1px solid #6495ed",
            fontFamily: config.fontFamily,
            color: config.label.color,
            fontWeight: config.label.fontWeight,
            fontSize: config.label.fontSize,
            [theme.breakpoints.down(Breakpoints.md)]: {
                fontSize: config.small.label.fontSize,
                fontWeight: config.small.label.fontWeight,
            },
        },
    },
    mediaItemList:
        {
            [theme.breakpoints.down(Breakpoints.md)]: {
                marginBottom:"10px",
            },
            "& button":
                {
                    [theme.breakpoints.down(Breakpoints.md)]: {
                        marginBottom:"5px",
                    },
                }
        },
    mediaItemItem:
        {
            [theme.breakpoints.down(Breakpoints.md)]: {
                marginBottom:"10px",
            },
        }


});

export default styles;
