import React from "react";
import classNames from "classnames";
import { Field } from "react-final-form";
import { InputLabel, TextField } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const styles = {
  root: {
    marginBottom: "20px",
  },
  label: {
    padding: 0,
    textAlign: "left",
  },
  field: {},
  error: {
    borderLeft: "7px solid #fe8368",
  },
};

const GIZTextField = ({
  classes,
  name,
  label,
  validator = null,
  disabled = false,
  className = null,
}) => {
  return (
    <Field name={name} validate={validator}>
      {({ input, meta }) => {
        const { value, onChange, onBlur, onFocus } = input;
        return (
          <div className={classNames(classes.root, className)}>
            <InputLabel className={classes.label}>{label}</InputLabel>
            <TextField
              name={name}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              onFocus={onFocus}
              variant="outlined"
              disabled={disabled}
              className={classes.field}
              inputProps={{
                className: classNames({
                  [classes.error]:
                    meta.invalid &&
                    (meta.touched || (meta.visited && meta.dirty)),
                }),
              }}
            />
          </div>
        );
      }}
    </Field>
  );
};

export default withStyles(styles)(GIZTextField);
