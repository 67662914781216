import React from 'react';
import withRoot from '../../../withRoot';
import BaseGraph from '../base-graph'
import { horizontalBarDatasetOptions, horizontalBarOptions, horizontalBarPlugins } from '../constants'
import {Settings} from "../../../config"
class MunicipalitiesInOblastChart extends React.Component {
  render() {
    return (
      <BaseGraph
        type='horizontalBar'
        tableTitle={`Percentage of municipalities covered by ${Settings.Tenant.ProgramName} events per oblast`}
        url='/graph/cummunity_per_oblast'
        chartOptions={horizontalBarOptions('percent')}
        datasetOptions={horizontalBarDatasetOptions}
        filter={this.props.filter}
        plugins={horizontalBarPlugins('percent')}
      />
    )
  }
}

export default withRoot(MunicipalitiesInOblastChart);
