import React from 'react';
import withRoot from '../../../withRoot';
import BaseGraph from '../base-graph'
import { horizontalBarDatasetOptions, horizontalBarOptions, horizontalBarPlugins } from '../constants'

class TSPModuleSfSChart extends React.Component {
  render() {
    return (
      <BaseGraph
        type='horizontalBar'
        tableTitle="Steps for Specialists by thematic module (number)"
        url='/graph/event_sfs'
        chartOptions={horizontalBarOptions('number')}
        datasetOptions={horizontalBarDatasetOptions}
        filter={this.props.filter}
        plugins={horizontalBarPlugins('number')}
        noActions={this.props.noActions}
      />
    )
  }
}

export default withRoot(TSPModuleSfSChart);
