import { I18n } from "../i18n/";

export default function categoryCaptions(user) {
  const lang =
    user && (user.langKey === "ua" || user.langKey === "Ukr") ? "Ukr" : "Eng";
  const categorySection =
    user && user.currentPhaseNumber === 2 ? "categories_p2" : "categories";

  return I18n[lang][categorySection];
}
