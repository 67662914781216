import React from 'react';
import BaseGraph from '../base-graph';
import withRoot from '../../../withRoot';
import {barDatasetOptions, barOptions, barPlugins} from "../constants";

class EventSatisfactionChart extends React.Component {
  render() {
    return (
        <BaseGraph
            type='bar'
            tableTitle="Satisfaction rate for different event formats"
            url='/graph/event_satisfactions'
            chartOptions={barOptions()}
            datasetOptions={barDatasetOptions}
            filter={this.props.filter}
            plugins={barPlugins()}
            noActions={this.props.noActions}
        />
    );
  }
}

export default withRoot(EventSatisfactionChart);
