import React, { memo, forwardRef } from "react";
import { SvgIcon } from "@material-ui/core";

const GoTo = (props, ref) => {
  return (
    <SvgIcon ref={ref} {...props}>
      <path
        d="M15.9508 7.7692L7.40269 11.0627C7.05509 11.1966 6.82146 11.5232 6.80866 11.8929C6.79586 12.2626 7.0065 12.6043 7.34416 12.7613L10.5967 14.2757L12.1241 17.5018C12.2781 17.827 12.6073 18.0334 12.9677 18.0334C12.9784 18.0334 12.9891 18.0332 12.9998 18.0329C13.3725 18.0202 13.7015 17.7884 13.8366 17.4437L17.1571 8.96574C17.2908 8.62417 17.2086 8.23638 16.9473 7.97727C16.686 7.71817 16.2948 7.63657 15.9508 7.7692ZM12.8867 14.7629L12.1409 13.1878C12.0484 12.9926 11.8901 12.8355 11.6933 12.7439L10.1059 12.0048L14.6531 10.2529L12.8867 14.7629Z"
      />
      <path
        d="M12.3878 0.90625C5.94296 0.906373 0.699707 6.10729 0.699707 12.4999C0.699707 18.8927 5.94296 24.0938 12.388 24.0939C18.8333 24.0938 24.0769 18.8928 24.0769 12.5001C24.0769 6.10717 18.8332 0.90625 12.3878 0.90625ZM12.3878 22.2448C6.97084 22.2447 2.5637 17.8732 2.5637 12.4998C2.5637 7.12669 6.97071 2.75522 12.3878 2.7551C17.8053 2.7551 22.2127 7.12657 22.2127 12.4999C22.2128 17.8732 17.8053 22.2447 12.3878 22.2448Z"
      />
    </SvgIcon>
  );
};

GoTo.displayName = "GoToIcon";

export default memo(forwardRef(GoTo));
