export const variables = {
  get environment() {
    const { host } = window.location;
    switch (host) {
      case "dmi.u-lead.org.ua":
        return "production";
      case "u-data.u-lead.org.ua":
        return "production";
      case "udata.flitta.dev":
        return "staging";
      default:
        return "development";
    }
  },

  get forumHost() {
    return this.environment === "production"
      ? "forum.u-lead.org.ua"
      : "dmi-forum.archer-soft.com";
  },
};
