export const LANGUAGES = {
  ENGLISH: "Eng",
  UKRAINIAN: "Ukr",
};

export const LANGUAGE_KEYS = {
  ENGLISH: "en",
  UKRAINIAN: "ua",
};

const relation = [
  [LANGUAGES.ENGLISH, LANGUAGE_KEYS.ENGLISH],
  [LANGUAGES.UKRAINIAN, LANGUAGE_KEYS.UKRAINIAN],
];

export const langToLangKey = (lang) => {
  for (let item of relation) {
    const [itemLang, itemKey] = item;
    if (itemLang === lang) {
      return itemKey;
    }
  }
  return "--";
};

export const langKeyToLang = (key) => {
  for (let item of relation) {
    const [itemLang, itemKey] = item;
    if (itemKey === key) {
      return itemLang;
    }
  }
  return "---";
};
