import React from 'react';
import BaseGraph from '../base-graph';
import withRoot from '../../../withRoot';
import {barDatasetOptions, barOptions, barPlugins, horizontalBarOptions} from "../constants";
import {Settings} from "../../../config"
class EventByYearChart extends React.Component {
  render() {
    return (
    <BaseGraph
        type='bar'
        tableTitle={`${Settings.Tenant.ProgramName} events by year`}
        url='/graph/event_year'
        chartOptions={barOptions('number')}
        datasetOptions={barDatasetOptions}
        plugins={barPlugins('number')}
    />
    );
  }
}

export default withRoot(EventByYearChart);
