import React from "react";
import classNames from "classnames";
import {observer} from "mobx-react";
import PropTypes from "prop-types";
import {Typography} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
import withRoot from "../withRoot";
import {Route, Router, Switch, Redirect} from "react-router-dom";
import {StoreContext} from "../stores/context";
import Header from "../components/GIZHeader";
import SmallHeader from "../components/SmallHeader";
import Footer from "../components/GIZFooter";
import Home from "../pages/home";
import Login from "../pages/login/";
import ForgotPassword from "../pages/forgot-password";
import ResetPassword from "../pages/reset-password";
import Search from "../pages/search/";
import SearchXS from "../pages/search/xs";
import Events from "../pages/events/";
import Participants from "../pages/participants/";
import About from "../pages/about";
import Category from "../pages/category/";
import SelectOptions from "../pages/selectOptions/";
import Region from "../pages/region/";
import Team from "../pages/team/";
import User from "../pages/user/";
import Communities from "../pages/communities/";
import {Trainers, Facilitators} from "../pages/persons";
import Templates from "../pages/templates/";
import Support from "../pages/support/";
import Settings from "../pages/settings/";
import Password from "../pages/password/";
import EuropeActions from "../pages/europe-actions";
import CsoGrants from "../pages/cso-grants";
import DonorProjects from "../pages/donor-projects";
import ThematicPackages from "../pages/thematic-packages";
import Indicator from "../pages/indicator";
import IndicatorDetails from "../pages/indicator/details/";
import GoodPractice from "../pages/good-practice/";
import Dashboard from "../pages/dashboard";
import Graphs from "../pages/graphs";
import InteractiveMap from "../pages/map";
import MailLogs from "../pages/mail-logs";
import Notallowed from "../pages/notallowed";
import Notfound from "../pages/notfound";
import UIDemo from "./ui-demo";
import Preregistration from "./preregistration";
import Notifier from "../components/notifier";
import {
    loginRoute,
    forgotPasswordRoute,
    resetPasswordRoute,
    preregistrationRoute,
} from "../utils/routes";

import {
    FundingSourceService,
    EmployerLevelService,
    EmployertypeService,
    TargetGroupService,
    TargetLevelService,
    OrganizerService,
    FormatService,
    EventCharacterService,
} from "../services/SelectOptionService";

import {I18n} from "../i18n";
import {Breakpoints} from "../config";
import styles from "../style/styles";

class Index extends React.Component {
    static contextType = StoreContext;

    state = {
        tags: [],
        filters: "",
    };

    async componentDidMount() {
        const {history} = this.props;
        this.handleLocationChange(history.location);
        history.listen(this.handleLocationChange);
    }

    handleLocationChange = (location) => {
        const {appStore, routerStore} = this.context;
        if (routerStore.isPublicRoute) {
            appStore.hideHeader();
        } else {
            appStore.showHeader();
        }
    };

    handleTags = (tags, filters) => {
        this.setState({tags, filters});
    };

    handleSearch = (tags, filters, oesQuery, xsQuery) => {
        this.setState({tags, filters, oesQuery, xsQuery});
    };

    restrictedAccess = (permission, componentFunc) => {
        // On the page load currentUser could be null, so we should pass component render
        // Don't worry. User will be redirected after currentUser assigning
        if (permission || !this.context.userStore.data) {
            return componentFunc;
        } else {
            return () => <Redirect to="/"/>;
        }
    };

    oesRoute = () => {
        const {langStore} = this.context;
        const {tags, filters, oesQuery, xsQuery} = this.state;

        return (
            <Search
                lang={langStore.lang}
                onSearchChange={this.handleSearch}
                selectedTags={tags}
                selectedFilters={filters}
                searchQuery={oesQuery}
                xsQuery={xsQuery}
            />
        );
    };

    render() {
        const {history, classes} = this.props;
        const {isHeaderShowing} = this.context.appStore;
        const {isMinreg, isOES, data: currentUser} = this.context.userStore;
        const {lang} = this.context.langStore;
        const {tags, filters} = this.state;

        const indicatorsAllowed = currentUser && currentUser.indicatorsAllowed;
        const isAdmin = currentUser && currentUser.isAdmin;
        const dashboardAllowed = !isMinreg;

        return (

            <Router history={history}>
                <Typography component="div">
                    <div className={classes.layout}>
                        <div className={classNames(classes.content, classes.root)}>
                            <Hidden mdUp>
                            {isHeaderShowing &&             <SmallHeader/>}
                            </Hidden>

                            <Hidden only={['xs', 'sm']}>
                                {isHeaderShowing && <Header/>}
                            </Hidden>
                            <Switch>
                                <Route
                                    exact
                                    path="/"
                                    render={() => {
                                        if (isMinreg) {
                                            return this.oesRoute();
                                        } else {
                                            return <Home user={currentUser} lang={lang}/>;
                                        }
                                    }}
                                />
                                <Route path={loginRoute} component={Login}/>
                                <Route path={forgotPasswordRoute} component={ForgotPassword}/>
                                <Route path={resetPasswordRoute} component={ResetPassword}/>
                                <Route path={preregistrationRoute} component={Preregistration}/>

                                <Route
                                    path="/support"
                                    render={() => <Support user={currentUser} lang={lang}/>}
                                />
                                <Route path="/settings" component={Settings}/>
                                <Route path="/password" component={Password}/>

                                <Route path="/notallowed" component={Notallowed}/>

                                <Route
                                    path="/events"
                                    render={() => <Events user={currentUser} lang={lang} classes={classes}/>}
                                />
                                <Route path="/search/oes" render={this.oesRoute}/>
                                {isAdmin && <Route path="/ui-demo" component={UIDemo}/>}
                                {!isMinreg && (
                                    <Switch>
                                        {!isOES && (
                                            <Route
                                                path="/search/xs"
                                                render={() => (
                                                    <SearchXS
                                                        lang={lang}
                                                        onSearchChange={this.handleSearch}
                                                        selectedTags={tags}
                                                        selectedFilters={filters}
                                                        searchQuery={this.state.xsQuery}
                                                        oesQuery={this.state.oesQuery}
                                                    />
                                                )}
                                            />
                                        )}

                                        <Route
                                            path="/participants"
                                            render={() => <Participants lang={lang}  classes={classes}/>}

                                        />
                                        <Route path="/trainers" render={() => <Trainers lang={lang} classes={classes}/>}/>
                                        <Route path="/facilitators" render={() => <Facilitators lang={lang} classes={classes}/>}/>
                                        <Route path="/about" component={About}/>
                                        <Route
                                            path="/communities"
                                            render={() => (
                                                <Communities user={currentUser} lang={lang}/>
                                            )}
                                        />
                                        <Route
                                            path="/indicators"
                                            render={() => (
                                                <Indicator user={currentUser} lang={lang}/>
                                            )}
                                        />
                                        <Route
                                            path="/indicator/:id"
                                            render={this.restrictedAccess(indicatorsAllowed, () => (
                                                <IndicatorDetails user={currentUser} lang={lang}/>
                                            ))}
                                        />
                                        <Route
                                            path="/good-practices"
                                            render={() => (
                                                <GoodPractice user={currentUser} lang={lang} classes={classes}/>
                                            )}
                                        />
                                        {/* should be declared in the index view */}
                                        {/* <Route
                      path="/good-practices/view/:id"
                      render={() => (
                        <GoodPracticeView user={currentUser} lang={lang} />
                      )}
                    />
                    <Route
                      path="/good-practices/edit/:id"
                      render={() => (
                        <GoodPracticeEdit user={currentUser} lang={lang} />
                      )}
                    />
                    <Route
                      path="/good-practices/create"
                      render={() => (
                        <GoodPracticeEdit user={currentUser} lang={lang} />
                      )}
                    /> */}
                                        <Route
                                            path="/dashboard"
                                            render={this.restrictedAccess(dashboardAllowed, () => (
                                                <Dashboard user={currentUser} lang={lang}/>
                                            ))}
                                        />
                                        <Route
                                            path="/graphs"
                                            render={this.restrictedAccess(dashboardAllowed, () => (
                                                <Graphs user={currentUser} lang={lang}/>
                                            ))}
                                        />
                                        <Route
                                            path="/map"
                                            render={this.restrictedAccess(dashboardAllowed, () => (
                                                <InteractiveMap user={currentUser} lang={lang}/>
                                            ))}
                                        />

                                        <Route
                                            path="/indicator-graphs/:id?"
                                            render={this.restrictedAccess(dashboardAllowed, () => (
                                                <Graphs
                                                    user={currentUser}
                                                    lang={lang}
                                                    kind={"indicators"}
                                                />
                                            ))}
                                        />

                                        <Route
                                            path="/templates"
                                            render={() => (
                                                <Templates user={currentUser} lang={lang}/>
                                            )}
                                        />
                                        <Route
                                            path="/team"
                                            render={this.restrictedAccess(isAdmin, () => (
                                                <Team lang={lang}/>
                                            ))}
                                        />
                                        <Route
                                            path="/user"
                                            render={this.restrictedAccess(isAdmin, () => (
                                                <User lang={lang}/>
                                            ))}
                                        />
                                        <Route
                                            path="/maillog"
                                            component={this.restrictedAccess(isAdmin, MailLogs)}
                                        />
                                        <Route
                                            path="/europe-actions"
                                            render={this.restrictedAccess(isAdmin, () => (
                                                <EuropeActions lang={lang}/>
                                            ))}
                                        />
                                        <Route
                                            path="/thematic-packages"
                                            render={this.restrictedAccess(isAdmin, () => (
                                                <ThematicPackages lang={lang}/>
                                            ))}
                                        />
                                        <Route
                                            path="/category"
                                            render={this.restrictedAccess(isAdmin, () => (
                                                <Category lang={lang} user={currentUser} classes={classes}/>
                                            ))}
                                        />
                                        <Route
                                            path="/employer-level"
                                            render={this.restrictedAccess(isAdmin, () => (
                                                <SelectOptions
                                                    path="employer-level"
                                                    serviceClass={EmployerLevelService}
                                                    lang={lang}
                                                    i18n={I18n[lang].employerLevel}
                                                    title={I18n[lang].common.employerLevel}
                                                    classes={classes}
                                                />
                                            ))}
                                        />
                                        <Route
                                            path="/employer-type"
                                            render={this.restrictedAccess(isAdmin, () => (
                                                <SelectOptions
                                                    path="employer-type"
                                                    serviceClass={EmployertypeService}
                                                    lang={lang}
                                                    i18n={I18n[lang].employerType}
                                                    classes={classes}
                                                />
                                            ))}
                                        />
                                        <Route
                                            path="/event-character"
                                            render={this.restrictedAccess(isAdmin, () => (
                                                <SelectOptions
                                                    path="event-character"
                                                    serviceClass={EventCharacterService}
                                                    lang={lang}
                                                    i18n={I18n[lang].eventCharacter}
                                                    classes={classes}
                                                />
                                            ))}
                                        />
                                        <Route
                                            path="/format"
                                            render={this.restrictedAccess(isAdmin, () => (
                                                <SelectOptions
                                                    path="format"
                                                    serviceClass={FormatService}
                                                    lang={lang}
                                                    i18n={I18n[lang].format}
                                                    classes={classes}
                                                />
                                            ))}
                                        />
                                        <Route
                                            path="/funding-source"
                                            render={this.restrictedAccess(isAdmin, () => (
                                                <SelectOptions
                                                    path="funding-source"
                                                    serviceClass={FundingSourceService}
                                                    lang={lang}
                                                    i18n={I18n[lang].fundingSources}
                                                    classes={classes}
                                                />
                                            ))}
                                        />
                                        <Route
                                            path="/organizer"
                                            render={this.restrictedAccess(isAdmin, () => (
                                                <SelectOptions
                                                    path="organizer"
                                                    serviceClass={OrganizerService}
                                                    lang={lang}
                                                    i18n={I18n[lang].organizer}
                                                    classes={classes}
                                                />
                                            ))}
                                        />
                                        <Route
                                            path="/region"
                                            render={this.restrictedAccess(isAdmin, () => (
                                                <Region lang={lang}/>
                                            ))}
                                        />
                                        <Route
                                            path="/target-group"
                                            render={this.restrictedAccess(isAdmin, () => (
                                                <SelectOptions
                                                    path="target-group"
                                                    serviceClass={TargetGroupService}
                                                    lang={lang}
                                                    i18n={I18n[lang].targetGroup}
                                                    classes={classes}
                                                />
                                            ))}
                                        />
                                        <Route
                                            path="/target-level"
                                            render={this.restrictedAccess(isAdmin, () => (
                                                <SelectOptions
                                                    path="target-level"
                                                    serviceClass={TargetLevelService}
                                                    lang={lang}
                                                    i18n={I18n[lang].targetLevel}
                                                    classes={classes}
                                                />
                                            ))}
                                        />
                                        <Route
                                            path="/cso-grants"
                                            render={this.restrictedAccess(isAdmin, () => (
                                                <CsoGrants lang={lang}/>
                                            ))}
                                        />
                                        <Route
                                            path="/donor-projects"
                                            render={this.restrictedAccess(isAdmin, () => (
                                                <DonorProjects lang={lang}/>
                                            ))}
                                        />
                                    </Switch>
                                )}
                                <Route component={Notfound}/>
                            </Switch>
                        </div>

                        <Footer />
                        <Notifier/>
                    </div>
                </Typography>
            </Router>

        );
        //}
    }
}

Index.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRoot(withStyles(styles)(observer(Index)));
