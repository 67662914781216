import React from "react";
import { observer } from "mobx-react";
import "url-search-params-polyfill";
import IndexTable from "../../components/table/index";
import Item from "./item";
import ViewEvent from "./view";
import CreateButton from "./CreateButton";
import EventService from "../../services/EventService";
import { StoreContext } from "../../stores/context";

class Event extends React.Component {
  static contextType = StoreContext;

  render() {
    const path = "events";
    const { langStore } = this.context;
    const i18nProps = {
      i18n: langStore.events,
      i18nCommon: langStore.common,
    };

    return (
      <IndexTable
        {...this.props}
        {...i18nProps}
        serviceClass={EventService}
        path={path}
        showCreate={() => false}
        renderCreateButton={() => (<CreateButton path={path} />)}
        columns={[
          { id: "id", title: "id" },
          {
            id: this.props.lang === "Eng" ? "nameEn" : "nameUa",
            title: "name",
          },
          { id: "startDate", title: "startDate" },
          { id: "endDate", title: "endDate" },
          {
            id: this.props.lang === "Eng" ? "oblastEn" : "oblastUa",
            sort: this.props.lang === "Eng" ? "regionNameEn" : "regionNameUa",
            title: "oblast",
          },
        ]}
        onEditItem={(itemId, item, handleItemSave) => {
          return (
            <Item
              itemId={itemId}
              serviceClass={EventService}
              indexPath={path}
              handleItemSave={handleItemSave}
              isEditable={true}
              {...i18nProps}
              {...this.props}
            />
          );
        }}
        onViewItem={(itemId, item) => {
          return (
            <ViewEvent
              item={item}
              id={itemId}
              indexPath={path}
              isEditable={false}
              {...i18nProps}
              {...this.props}
            />
          );
        }}
      />
    );
  }
}

export default observer(Event);
