import React from "react";
import {MuiThemeProvider, createMuiTheme} from "@material-ui/core/styles";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import blue from "@material-ui/core/colors/blue";
import green from "@material-ui/core/colors/green";
import CssBaseline from "@material-ui/core/CssBaseline";
import {config} from "./style/config";
import {Breakpoints} from "./config";

// A theme with custom primary and secondary color.
// It's optional.

const breakpoints = createBreakpoints({});

const theme = createMuiTheme({
    palette: {
        primary: {
            light: blue[300],
            main: "#5486e0",
            dark: blue[700],
        },
        secondary: {
            light: green[300],
            main: green[500],
            dark: green[700],
        },
        background: {
            paper: "#ffffff",
        },
    },
    breakpoints: { ... breakpoints,
        values: {... Breakpoints},
    },
    typography: {
        useNextVariants: true,
        fontFamily: config.fontFamily,
        h2: {
            color: config.pageHeader.color,
            fontSize: config.pageHeader.fontSize,
            fontWeight: config.pageHeader.fontWeight,
            letterSpacing: "1px",
            textTransform: "uppercase!important",
            fontFamily: config.fontFamily,
            lineHeight: 1,
            [breakpoints.down(Breakpoints.md)]: {
                fontSize: config.small.pageHeader.fontSize,
                fontWeight: config.small.pageHeader.fontWeight,
            },
        },
        h3: {
            textTransform: "uppercase",
            color: "#6495ed !important",
            fontSize: "24px !important",
            fontWeight: "500 !important",
            letterSpacing: "0.8px !important",
            marginLeft: 15,
            [breakpoints.down(Breakpoints.md)]: {
                fontSize: config.small.subTitle.fontSize,
                fontWeight: config.small.subTitle.fontWeight,
            },
        },
        h4: {
            color: "#88adf0",
            fontSize: "16px",
            fontFamily: "Montserrat, sans-serif",
            paddingTop: "10px",
            fontWeight: "500",
            letterSpacing: "0.7px",
        },
        h5: {
            color: "#6495ed",
            fontSize: "24px",
            fontFamily: "Montserrat, sans-serif",
            fontWeight: "500",
        },
        h6: {
            color: "#6495ed",
            fontSize: "24px",
            fontFamily: "Montserrat, sans-serif",
            fontWeight: "100",

        },
        body2: {
            fontSize: config.readOnly.fontSize,
            color: config.readOnly.color,
            [breakpoints.down(Breakpoints.md)]: {
                fontSize: config.small.readOnly.fontSize,
            },
        },
    },
    overrides: {
        root: {
            "&$disabled": {backgroundColor: "#eceeef!important"},
        },

        MuiOutlinedInput: {
            input: {
                padding: config.input.padding,
                filter: "blur(0)",
                [breakpoints.down(Breakpoints.md)]: {
                    padding: config.small.input.padding,
                }
            },
            multiline: {
                padding: config.input.padding,
                boxSizing: "border-box",
                backgroundColor: config.input.background,
                borderRadius: config.input.borderRadius,
                [breakpoints.down(Breakpoints.md)]: {
                    padding: config.small.input.padding,
                }
            },
            adornedEnd: {
                paddingRight: 0,
            },
            inputAdornedEnd: {
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
            },
        },
        MuiGrid: {
            // "spacing-xs-16":
            //     {
            //         [breakpoints.down(Breakpoints.md)]: {
            //             marginRight: 0,
            //             width: "calc(100% + 24px)",
            //         },
            //     },
            item: {

                "& label": {
                    padding: "0!important",
                },
                "& textField": {
                    marginBottom: "0!important",
                },

            },
            container:
                {}

        },

        MuiFormLabel: {
            root: {
                fontSize: config.label.fontSize,
                fontWeight: config.label.fontWeight,
                letterSpacing: config.label.letterSpacing,
                padding: "10px 0px 2px",
                marginBottom: "2px",

                color: config.label.color,

                position: "relative",
                transform: "translate(0, 0px) scale(1)",
                display: "block",

                [breakpoints.down(Breakpoints.md)]: {
                    fontSize: config.small.label.fontSize,
                    fontWeight: config.small.label.fontWeight,
                    color: config.small.label.color,
                    marginBottom: "10px",
                },
            },
        },
        MuiFormControl: {
            root: {
                width: "100%",

                "& div fieldset:hover": {
                    borderColor: "rgba(0, 0, 0, 0) !important",
                },
                "& div fieldset": {
                    borderColor: "rgba(0, 0, 0, 0) !important",
                },

                // Obsolete but applied to older pages
                "&.text-field": {
                    width: "100%",
                    marginBottom: "20px",
                    display: "block",
                    "& div": {
                        display: "flex",
                    },
                    "& label": {
                        fontWeight: "100",
                        position: "relative",
                        color: "#88adf0",
                        transform: "none",
                        padding: "1vh 0",
                        textAlign: "left",
                    },
                    "& fieldset": {
                        border: "none",
                        borderRadius: "0.25rem",
                    },
                    "& fieldset legend": {
                        display: "none",
                    },
                    "& input": {
                        border: "1px solid #e1edfb",
                        backgroundColor: "#e1edfb",
                        borderRadius: "0.25rem",
                        padding: "10px 14px",
                        color: "#191970",
                    },
                    "& input:focus": {
                        color: "#464a4c",
                        backgroundColor: "#fff",
                        borderColor: "#5cb3fd",
                        outline: "0",
                    },
                    "&.error-field input": {
                        borderLeft: "1px solid #fe8368",
                        borderLeftWidth: "6px",
                        borderRadius: "0.5rem 0.25rem 0.25rem 0.5rem",
                    },
                },
                "&.text-area": {
                    width: "100%",
                    marginBottom: "20px",
                    "-ms-overflow-style": "none !important",
                    "& textarea": {
                        lineHeight: "1.1",
                        padding: "4px 0 !important",
                        color: "#191970",
                        "-ms-overflow-style": "none !important",
                    },
                    "& label": {
                        paddingBottom: "3px",
                        color: "#88adf0",
                        fontSize: "30pt",
                        fontWeight: "100",
                        position: "relative",
                        transform: "translate(0, -10px) scale(1)",
                        "@media only screen and (max-width: 1700px)": {
                            fontSize: "16pt",
                        },
                    },
                    "& fieldset": {
                        borderRadius: "0.25rem",
                        border: "none !important",
                        backgroundColor: "#e1edfb",
                        padding: "10px 14px",
                    },
                    "& fieldset:hover": {
                        border: "1px solid #e1edfb",
                        backgroundColor: "#e1edfb",
                        outline: "0",
                    },
                    "& > div:hover": {
                        outline: "0",
                    },
                    "& > div": {
                        padding: "7px 14px 0",
                    },
                    "&.error-field fieldset": {
                        borderLeft: "1px solid #fe8368 !important",
                        borderLeftWidth: "6px !important",
                        borderRadius: "0.5rem 0.25rem 0.25rem 0.5rem",
                    },
                },
            },
        },
        MuiTableSortLabel: {
            active: {
                color: "#191970!important",
                "& span:hover": {
                    color: "#191970 !important",
                },
            },

        },
        MuiInputBase: {
            input: {
                color: config.input.color,
                fontSize: config.input.fontSize,
                fontWeight: config.input.fontWeight,
                letterSpacing: config.input.letterSpacing,
                fontFamily: config.fontFamily,
                background: config.input.background,
                borderRadius: config.input.borderRadius,
                // borderTopRightRadius: config.input.borderRadius,
                // borderBottomRightRadius: config.input.borderRadius,
                padding: config.input.padding,
                [breakpoints.down(Breakpoints.md)]: {
                    fontSize: config.small.input.fontSize,
                    lineHeight: config.small.input.lineHeight,
                    color: config.small.input.color,
                },
            },
        },

        MuiInput: {
            root: {
                color: config.input.color,
                fontSize: config.input.fontSize,
                fontWeight: config.input.fontWeight,
                letterSpacing: config.input.letterSpacing,
                fontFamily: config.fontFamily,
                // background: config.input.background,
                borderRadius: config.input.borderRadius,
            },
            input: {
                color: config.input.color,
                fontSize: config.input.fontSize,
                fontWeight: config.input.fontWeight,
                letterSpacing: config.input.letterSpacing,
                fontFamily: config.fontFamily,
                background: config.input.background,
                borderRadius: config.input.borderRadius,
                padding: config.input.padding,
                [breakpoints.down(Breakpoints.md)]: {
                    fontSize: config.small.input.fontSize,
                    lineHeight: config.small.input.lineHeight,
                    color: config.small.input.color,
                },
            },
            error: {
                "&:after": {
                    border: 0,
                },
            },
            // "&:hover": {
            //   boxShadow: "inset 0 0 0px 2px #6495ed",
            // },
        },
        MuiFormHelperText: {
            error: {
                display: "none",
            },
        },
        MuiButton: {
            root: {
                [breakpoints.down(Breakpoints.md)]: {
                    lineHeight: "16px"
                },
                boxShadow: "none !important",
                textTransform: "none",
                "&.btn-create": {
                    borderRadius: "100px",
                    backgroundColor: "#6495ed",
                    marginRight: "2.1vw",
                    boxShadow: "none",
                    padding: "6px 25px 6px 20px",
                    textTransform: "none",
                    "&:hover": {
                        backgroundColor: "#5486e0",
                    },
                    "& svg": {
                        width: "1.2em",
                        height: "1.2em",
                        marginRight: "5px",
                    },
                },
                "& span": {
                    minWidth: "60px",
                    justifyContent: "space-around",
                },
                "&.btn-create:hover": {
                    backgroundColor: "#5486e0 !important",
                },
                "&.btn-login": {
                    borderRadius: "100px",
                    backgroundColor: "#6495ed",
                },
                "&.btn-login:hover": {
                    backgroundColor: "##5486e0",
                },
                "&.btn-edit": {
                    backgroundColor: "#6495ed",
                },
                "&.btn-edit:hover": {
                    backgroundColor: "#5486e0",
                },
                "&.btn-delete": {
                    backgroundColor: "#fe8368",
                },
                "&.btn-delete:hover": {
                    backgroundColor: "#f67a5f",
                },
                "&.btn-add": {
                    backgroundColor: "#add8e6",
                },
                "&.btn-add:hover": {
                    backgroundColor: "#9bcfe0",
                },
                "&.button-cancel": {
                    color: "#fff",
                    backgroundColor: "#fe8368",
                    border: "1px solid #fe8368",
                    borderRadius: "10px",
                    boxShadow: "none",
                    textTransform: "none",
                    fontSize: "11px",
                    fontWeight: "400",
                    marginRight: "20px",
                    padding: "14px 22px 15px",
                    lineHeight: "1",
                    minWidth: "118px",
                    textAlign: "center",
                    height: "100%",
                    [breakpoints.down(Breakpoints.md)]: {
                        padding: "7px 10px 6px 10px",
                        borderRadius: "5px",
                        minWidth: "80px",
                    },
                    "& i": {
                        display: "inline-block",
                        minWidth: "16px",
                        minHeight: "16px",
                        marginRight: "8px",
                        background:
                            "url(/images/icons/button-close.svg) no-repeat 0 0 / 14px 14px",
                        [breakpoints.down(Breakpoints.md)]: {
                            width: "14px",
                            height: "14px",
                        },
                    },
                    "&:hover": {
                        backgroundColor: "#f67a5f",
                    },
                },
                "&.button-delete": {
                    color: "#fff",
                    backgroundColor: "#fe8368",
                    border: "1px solid #fe8368",
                    borderRadius: "10px",
                    boxShadow: "none",
                    textTransform: "none",
                    fontSize: "11px",
                    fontWeight: "400",
                    marginRight: "20px",
                    padding: "14px 22px 15px",
                    lineHeight: "1",
                    minWidth: "118px",
                    textAlign: "center",
                    "& i": {
                        display: "inline-block",
                        minWidth: "16px",
                        minHeight: "16px",
                        marginRight: "10px",

                    },
                    "&:hover": {
                        backgroundColor: "#f67a5f",
                    },
                },
                "&.button-submit": {
                    color: "#fff",
                    backgroundColor: "#6495ed",
                    border: "1px solid #6495ed",
                    borderRadius: "10px",
                    boxShadow: "none",
                    textTransform: "none",
                    fontSize: "11px",
                    fontWeight: "400",
                    marginRight: "20px",
                    padding: "14px 22px 15px",
                    lineHeight: "1",
                    minWidth: "118px",
                    textAlign: "center",
                    maxHeight: "47px",
                    [breakpoints.down(Breakpoints.md)]: {
                        padding: "7px 10px 6px 10px",
                        borderRadius: "5px",
                        minWidth: "80px",
                    },
                    "& i": {
                        display: "inine-block",
                        width: "19px",
                        height: "19px",
                        marginRight: "10px",
                        background:
                            "url(/images/icons/button-save.svg) no-repeat 0 0 / 14px 14px",
                        [breakpoints.down(Breakpoints.md)]: {
                            marginRight: 0,
                            width: "14px",
                            height: "14px",
                        },
                    },
                    "&:hover": {
                        backgroundColor: "#5486e0",
                        borderColor: "#5486e0",
                    },
                },
            },

            sizeSmall: {
                padding: "8px",
            },
        },

        MuiBackdrop: {
            invisible: {
                opacity: "0 !important",
            },
            root: {
                [breakpoints.down(Breakpoints.md)]: {
                    top: "80px",
                },
            }
        },
        MuiListItem: {
            root: {
                paddingTop: "3px",
                paddingBottom: "3px",
            },
        },
        MuiSnackbar: {
            root: {
                zIndex: "3001",
            },
        },
        MuiModal: {
            root: {
                [breakpoints.down(Breakpoints.md)]: {
                    top: "80px",
                },
                boxShadow: "none",
                zIndex: "2001",
                "& .modal": {
                    margin: "3% auto",
                    position: "relative",
                    height: "90vh",
                    width: "94%",
                    backgroundColor: "#f7f9fb",
                    boxShadow: "none",
                    outline: "none",
                    borderRadius: "15px",
                    padding: "25px",
                    overflowY: "auto",
                    "& a": {
                        textDecoration: "none",
                    },
                    [breakpoints.down(Breakpoints.md)]: {
                        margin: '0',
                        width: "100%!important",
                        height: "100%!important",
                        borderRadius: "0!important",
                        paddingLeft: "8px",
                        paddingRight: "8px",
                        backgroundColor: "#ffffff",
                    },
                },
                "& .close": {
                    position: "absolute",
                    right: "10px",
                    top: "10px",
                    color: "#666",
                    cursor: "pointer",
                },
            },
        },
        MuiDrawer: {
            root: {},
            paper: {
                background: "#6495ed",
            },
        },
        MuiSwitch: {
            checked: {
                // backgroundColor: '#8ccfe5'
            },
            bar: {
                backgroundColor: "#d6d6d6",
                opacity: "1",
                top: "51%",
                left: "55%",
                width: "31px",
                height: "15px",
            },
            icon: {
                width: "15px",
                height: "15px",
                boxShadow: "0 0 5px 4px rgba(73, 66, 66, 0.26)",
                backgroundColor: "#a6a6a6",
                opacity: "1",
            },
            iconChecked: {
                width: "15px",
                height: "15px",
                backgroundColor: "#add8e6",
                boxShadow: "0 0 5px 4px rgba(73, 66, 66, 0.26)",
            },
        },
        MuiTooltip: {
            popper: {
                opacity: "1",
            },
            tooltipPlacementRight: {
                position: "relative",
                left: "-45px",
            },
        },
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: "#add8e5",
            },
        },
        MuiPickersDay: {
            isSelected: {
                backgroundColor: "#add8e5",
                "&:hover": {
                    backgroundColor: "#add8e5",
                },
            },
        },
    },
    title: {
        "& div h2": {
            fontSize: "60px",
            margin: "2.6vh 0 1.3vh 3.1vw",
            "@media only screen and (max-width: 1700px)": {
                fontSize: "40px",
            },
        },
        "div &": {
            width: "100%",
            margin: "0",
        },
    },
});

theme.overrides.MuiTablePagination = {
    root: {
        "& span": {
            fontWeight: 900,
            [theme.breakpoints.down(1440)]: {
                fontWeight: 100,
            },
        },
    },
};

theme.overrides.MuiTable = {
    root: {
        "& tr th": {
            backgroundColor: "#f7f9fb",
            borderBottom: "none",
            whiteSpace: "nowrap",
            fontSize: "20pt",
            color: "#6495ed",
            fontWeight: "100",
        },

        "& tr td": {
            borderBottom: "none",
        },
        "& tr td, & tr th": {
            padding: "15px",
        },
        "& tr": {
            backgroundColor: "#fff",
        },
        "& tr:nth-child(even)": {
            backgroundColor: "#f7f9fb",
        },
    },
};

function withRoot(Component) {
    function WithRoot(props) {
        // MuiThemeProvider makes the theme available down the React tree
        // thanks to React context.
        return (
            <MuiThemeProvider theme={theme}>
                {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                <CssBaseline/>
                <Component {...props} />
            </MuiThemeProvider>
        );
    }

    return WithRoot;
}

export default withRoot;
