export const JWT_EXPIRATION_STATES = {
  Valid: "valid",
  Expiring: "expiring",
  Expired: "expired",
};

const EXPIRING_OFFSET = 1000 * 60 * 60;

export const decode = (token) => {
  try {
    const main = token.split(".")[1];
    const decoded = JSON.parse(atob(main));
    return decoded;
  } catch (ex) {
    return {};
  }
};

export const checkExpiration = (token) => {
    const decoded = decode(token);
    const exp = parseInt(decoded.exp, 10) * 1000;

    if (isNaN(exp) || Date.now() >= exp) {
      return JWT_EXPIRATION_STATES.Expired;
    } else if (exp - Date.now() < EXPIRING_OFFSET) {
      return JWT_EXPIRATION_STATES.Expiring;
    } else {
      return JWT_EXPIRATION_STATES.Valid;
    }
};
