import React from "react";
import {
  Grid,
  Button,
  TextField,
  Typography,
  Modal,
  InputLabel,
} from "@material-ui/core";
import Select from "react-select";
import { Close } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import withRoot from "../../withRoot";
import fetchData from "../../utils/fetch";
import { Redirect } from "react-router-dom";
import { Form, Field } from "react-final-form";
import { each, isEmpty } from "lodash";
import { I18n } from "../../i18n/";
import { metricArray } from "./utils";
import classNames from "classnames";
import makeAnimated from "react-select/lib/animated";
import { selectStyles } from "../../style/select-styles";

const styles = (theme) => ({
  root: {
    textAlign: "left",
    width: "652px",
    margin: "30px 3%",
  },
  modal: {
    width: "652px !important",
    "& h2": {
      textTransform: "uppercase",
      marginBottom: "40px !important",
      color: "#88ADF0 !important",
      fontSize: "30px !important",
      fontWeight: "900 !important",
      textAlign: "center",
    },
  },
  alignRight: {
    textAlign: "right",
  },
  data: {
    "& h4": {
      marginBottom: "0",
    },
  },
  label: {
    display: "block",
    margin: "20px 0 10px",
  },
  textField: {
    width: "100%",
    marginBottom: "20px",
    "& label": {
      marginBottom: "10px",
    },
    "&.code-field": {
      display: "flex",
      justifyContent: "flex-start",
      flexDirection: "row",
      alignItems: "center",
      "& label": {
        margin: "18px 15px 0 0",
      },
      "& > div": {
        width: "120px",
      },
    },
  },
  textarea: {
    "&.indicator-field": {
      display: "flex",
      justifyContent: "flex-start",
      flexDirection: "row",
      "& label": {
        margin: "18px 15px 0 0",
      },
      "& > div": {
        flexGrow: "1",
      },
    },
  },
  buttonCancel: {
    marginRight: "20px",
  },
  select: {
    "& .css-vj8t7z": {
      border: "0",
      backgroundColor: "#E1EDFB",
      borderRadius: "6px",
    },
    "& .css-d8oujb": {
      display: "none",
    },
    "& .css-15k3avv": {
      zIndex: "9999",
    },
    "& .css-1ep9fjw": {
      backgroundColor: "#B4C3D1",
      padding: "12px",
      borderRadius: "0 6px 6px 0",
      display: "none",
      "&:last-child": {
        display: "flex",
      },
      "& svg": {
        background:
          "url(/images/icons/icon-arrow-bottom.png) no-repeat center center / 15px 15px",
        "& path": {
          display: "none",
        },
      },
    },
    "&.error-field > div": {
      borderLeft: "6px solid #fe8368",
    },
  },
  measurements: {
    "& .css-10nd86i": {
      width: "125px",
      marginBottom: "13px",
    },
    "& input[type=text]": {
      border: "none",
      backgroundColor: "#E1EDFB",
      borderRadius: "6px",
      padding: "12px 16px",
      color: "#778899",
    },
    "& .measurement": {
      marginBottom: "8px",
      display: "flex",
      "& input:first-child": {
        width: "125px",
        marginRight: "20px",
      },
      "& input:last-child": {
        flexGrow: "1",
      },
    },
  },
  buttons: {
    marginTop: "30px",
    "& .button-submit": {
      marginRight: "0",
    },
  },
});

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
const required = (value) => (value ? undefined : "Required");

const measurementsOptions = [
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
];

class ItemEdit extends React.Component {
  state = {
    open: true,
    errorResult: false,
    errorLead: false,
    measurementsLength: 4,
    measurements: [
      { number: "1", text: "text1" },
      { number: "2", text: "text2" },
      { number: "3", text: "text3" },
      { number: "4", text: "text4" },
    ],
    initialMeasurements: [],
    resultsList: [],
    eventOwners: [],
    item: null,
    selectedLeadUnit: null,
    selectedSupportUnits: [],
  };

  componentDidMount() {
    this.getData();
  }

  async getData() {
    const data = await fetchData("get", "/categories/results");
    const asGuest = this.isGuest();

    const item = this.props.item;

    const users = [];
    const teams = [];
    if (!asGuest) {
      const usersData = await fetchData(
        "get",
        `/users/event_owners${item && item.fake ? "?all=1" : ""}`
      );
      const teamsData = await fetchData("get", "/teams?size=200");
      each(usersData[0], (item) => {
        users.push({
          value: item.id.toString(),
          label: item.name,
          type: "User",
        });
      });

      each(teamsData[0], (item) => {
        teams.push({
          value: item.id.toString(),
          label: item.name,
          type: "Team",
        });
      });
    }

    ///jfl: results should better be filtered on server. categories have the necessary phase_1
    const isFirstPhase = this.props.user?.currentPhaseId === 1;

    const results = (await data[0]).filter(
      (r) =>
        isFirstPhase ||
        (!r.nameEn.startsWith("R1") &&
          !r.nameEn.startsWith("R2") &&
          !r.nameEn.startsWith("R3") &&
          !r.nameEn.startsWith("R4") &&
          !r.nameEn.startsWith("R5") &&
          !r.nameEn.startsWith("R6") &&
          !r.nameEn.startsWith("R7"))
    );
    const resultsList = [];
    each(results, (item) => {
      resultsList.push({ value: item.id.toString(), label: item.nameEn });
    });

    this.setState({
      resultsList: resultsList,
      units: users,
    });

    const stateOpts = {};

    if (item) {
      stateOpts.item = item;

      if (!asGuest) {
        stateOpts.selectedLeadUnit =
          item.leadUnit && item.leadUnitType == "User"
            ? this.filterSelected(users, item.leadUnit.id)
            : null;
        stateOpts.selectedSupportUnits = [];
        item.supportUnits.map((unit) => {
          if (unit && unit.type == "User") {
            stateOpts.selectedSupportUnits.push(
              this.filterSelected(users, unit.id)
            );
          }
        });
      }

      stateOpts.measurementsLength = item.measurementCount;
      stateOpts.initialMeasurements = [];
      stateOpts.measurements = metricArray(item).map((el, i) => {
        const metric = {
          number: i + 1,
          text: item[`measurementDescription${i + 1}`],
        };
        stateOpts.initialMeasurements.push(metric);
        return metric;
      });
    } else {
      stateOpts.errorLead = true;
      stateOpts.errorResult = true;
    }

    this.setState(stateOpts);
  }

  isGuest = () => {
    const user = this.props.user;
    return user && user.authorities.indexOf("ROLE_GUEST") >= 0;
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  renderRedirect = () => {
    if (!this.state.open) {
      return <Redirect to="/indicators" />;
    }
  };

  saveItem = async (values) => {
    const {
      measurements,
      measurementsLength,
      selectedLeadUnit,
      selectedSupportUnits,
    } = this.state;
    const data = {};
    const errorOpts = {};

    ["id", "code", "jhiIndicator"].forEach((x) => {
      data[x] = values[x];
    });

    if (values.resultId && values.resultId.value) {
      data.resultId = values.resultId.value;
    } else if (typeof values.resultId != "number") {
      errorOpts.errorResult = true;
    }

    if (!this.isGuest()) {
      if (selectedLeadUnit) {
        data.leadUnitId = selectedLeadUnit.value;
        data.leadUnitType = "User";
      } else {
        errorOpts.errorLead = true;
      }

      data.supportUnitIds = selectedSupportUnits.map((selectedUnit) => {
        return { id: selectedUnit.value, type: selectedUnit.type };
      });
    }

    data.measurementCount = measurementsLength;
    metricArray(data).map((el, i) => {
      data[`measurementDescription${i + 1}`] = measurements[i].text;
    });

    if (!isEmpty(errorOpts)) {
      this.setState(errorOpts);
      return;
    }

    if (data.id) {
      await fetchData("put", `/indicators/${data.id}`, data);
    } else {
      await fetchData("post", "/indicators", data);
    }
    this.setState({ open: false });

    this.props.handleItemSave();
  };

  onSubmit = async (values) => {
    await sleep(300);
    this.saveItem(values);
  };

  handleMeasurementChange = (selectedOption) => {
    this.setState({ measurementsLength: selectedOption.value });

    if (selectedOption.value < this.state.measurements.length) {
      const measurements = this.state.measurements.splice(
        0,
        selectedOption.value
      );
      this.setState({ measurements });
    } else {
      let measurements = this.state.measurements;

      for (
        let i = this.state.measurements.length + 1;
        i <= selectedOption.value;
        i++
      ) {
        measurements.push(
          this.state.initialMeasurements[i - 1] || {
            number: `${i}`,
            text: `text${i}`,
          }
        );
      }
      this.setState({ measurements });
    }
  };

  handleMeasurementNumberChange = () => {};

  handleMeasurementTextChange = (idx) => {
    return (event) => {
      const measurements = this.state.measurements;
      measurements[idx].text = event.target.value;
      this.setState({ measurements: measurements });
    };
  };

  filterSelected = (collection, value) => {
    return collection.find((option) => option.value == value);
  };

  handleDropdownChange = (option) => {
    this.setState({ [option]: false });
  };

  handleSupportUnitChange = (selectedOption) => {
    this.setState({ selectedSupportUnits: selectedOption });
  };

  handleLeadUnitChange = (selectedOption) => {
    this.setState({
      selectedLeadUnit: selectedOption.value ? selectedOption : null,
    });
  };

  render() {
    const { classes, lang } = this.props;
    const {
      item,
      errorLead,
      errorResult,
      measurementsLength,
      resultsList,
      units,
      selectedLeadUnit,
      selectedSupportUnits,
    } = this.state;

    return (
      <>
        {this.renderRedirect()}

        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.open}
          onClose={this.handleClose}
        >
          <div className={classNames("modal", classes.modal)}>
            <a className="close" onClick={this.handleClose}>
              <Close />
            </a>
            <Typography
              gutterBottom
              variant="h2"
              component="h2"
              style={{ color: "#fdbb2d" }}
            >
              {item ? "Edit" : "Create"} An Indicator
            </Typography>
            <Typography className={classes.data}>
              <Form
                onSubmit={this.onSubmit}
                initialValues={item ? item : {}}
                render={({
                  handleSubmit,
                  form,
                  submitting,
                  pristine,
                  values,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Field name="resultId">
                      {({ input, meta }) => (
                        <>
                          <InputLabel
                            className={classNames(classes.label, {
                              "error-field": errorResult,
                            })}
                          >
                            Result or Specific Objective
                          </InputLabel>
                          <Select
                            styles={selectStyles()}
                            className={classNames(classes.select, {
                              "error-field": errorResult,
                            })}
                            closeMenuOnSelect={true}
                            components={makeAnimated()}
                            options={resultsList}
                            onMenuOpen={() =>
                              this.handleDropdownChange("errorResult")
                            }
                            {...input}
                            value={this.filterSelected(
                              resultsList,
                              input.value
                            )}
                          />
                        </>
                      )}
                    </Field>

                    <Field name="code" validate={required}>
                      {({ input, meta }) => (
                        <TextField
                          label="Code"
                          margin="normal"
                          className={classNames(
                            "text-field",
                            classes.textField,
                            "code-field",
                            { "error-field": meta.error && meta.touched }
                          )}
                          variant="outlined"
                          {...input}
                        />
                      )}
                    </Field>

                    <Field name="jhiIndicator" validate={required}>
                      {({ input, meta }) => (
                        <TextField
                          label="Indicator"
                          margin="normal"
                          className={classNames(
                            classes.textarea,
                            "text-area",
                            "indicator-field",
                            { "error-field": meta.error && meta.touched }
                          )}
                          variant="outlined"
                          multiline
                          rows="4"
                          rowsMax="20"
                          {...input}
                        />
                      )}
                    </Field>

                    <div className={classes.measurements}>
                      <InputLabel className={classes.label}>
                        Please select the number of cells for quantitative
                        measurements
                      </InputLabel>
                      <Select
                        styles={selectStyles()}
                        className={classes.select}
                        closeMenuOnSelect={true}
                        components={makeAnimated()}
                        value={measurementsOptions.find(
                          (option) => option.value === measurementsLength
                        )}
                        options={measurementsOptions}
                        onChange={this.handleMeasurementChange}
                      />

                      {this.state.measurements.map((measurement, idx) => (
                        <div className="measurement">
                          <input
                            type="text"
                            placeholder="Number"
                            value={measurement.number}
                            onChange={this.handleMeasurementNumberChange(idx)}
                          />
                          <input
                            type="text"
                            placeholder="Text"
                            value={measurement.text}
                            onChange={this.handleMeasurementTextChange(idx)}
                          />
                        </div>
                      ))}
                    </div>

                    {!this.isGuest() && (
                      <>
                        <Field name="leadUnitId">
                          {({ input, meta }) => (
                            <>
                              <InputLabel className={classes.label}>
                                Lead Unit
                              </InputLabel>
                              <Select
                                styles={selectStyles()}
                                className={classNames({
                                  "error-field": errorLead,
                                })}
                                closeMenuOnSelect={true}
                                components={makeAnimated()}
                                onMenuOpen={() =>
                                  this.handleDropdownChange("errorLead")
                                }
                                {...input}
                                options={units}
                                value={selectedLeadUnit}
                                onChange={this.handleLeadUnitChange}
                              />
                            </>
                          )}
                        </Field>

                        <Field name="supportUnits">
                          {({ input, meta }) => (
                            <>
                              <InputLabel className={classes.label}>
                                Supporting Unit(s)/Team(s)
                              </InputLabel>
                              <Select
                                styles={selectStyles()}
                                closeMenuOnSelect={true}
                                isMulti
                                components={makeAnimated()}
                                {...input}
                                options={units}
                                value={selectedSupportUnits}
                                onChange={this.handleSupportUnitChange}
                              />
                            </>
                          )}
                        </Field>
                      </>
                    )}

                    <Grid
                      container
                      spacing={24}
                      alignItems="center"
                      className={classes.buttons}
                    >
                      <Grid item xs={12} className={classes.alignRight}>
                        <Button
                          variant="contained"
                          className="button-cancel"
                          onClick={this.handleClose}
                        >
                          <i></i>
                          {I18n[lang].common.cancel}
                        </Button>
                        <Button
                          variant="contained"
                          className="button-submit"
                          type="submit"
                          color="primary"
                        >
                          <i></i>
                          {I18n[lang].common.save}
                        </Button>
                        <Button
                            variant="contained"
                            className="button-delete"

                        >
                          <i></i>
                          {I18n[lang].common.delete}
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                )}
              />
            </Typography>
          </div>
        </Modal>
      </>
    );
  }
}

export default withRoot(withStyles(styles)(ItemEdit));
