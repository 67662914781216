import React from "react";

import { CategoryService } from "../../services/SelectOptionService.js";
import Edit from "../../components/dialog/edit.js";
import {
  FormTextField,
  FormCheckboxField,
  FormSelectField,
} from "../../components/dialog/formField";
import { Paper, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import styles from "../../style/styles";

const Item = (props) => {
  const handleGetData = async (service, editorState) => {
    const resultsList = await service.GetAllSelectOptions(
      "categories/results",
      editorState.categories
    );
    const state = { resultsList: resultsList };
    return state;
  };

  const { classes, i18n, ...rest } = { ...props };

  return (
    <Edit
      i18n={i18n}
      handleGetData={handleGetData}
      serviceClass={CategoryService}
      {...rest}
      classes={classes}
      render={(fieldProps) => (
        <Paper className={classes.paper}>
          <Grid container direction="column" spacing={16}>
            <Grid item xs="6">
              <FormTextField fieldName="langKey" i18n={i18n} {...fieldProps} />
            </Grid>
            <Grid item xs="6">
              <FormTextField fieldName="ua" i18n={i18n} {...fieldProps} />
            </Grid>
            <Grid item xs="6">
              <FormTextField fieldName="en" i18n={i18n} {...fieldProps} />
            </Grid>
            <Grid item xs="6">
              <FormCheckboxField
                fieldName="active"
                i18n={i18n}
                {...fieldProps}
              />
            </Grid>
            <Grid item xs="6">
              <FormTextField fieldName="descEn" i18n={i18n} {...fieldProps} />
            </Grid>
            <Grid item xs="6">
              <FormTextField fieldName="descUa" i18n={i18n} {...fieldProps} />
            </Grid>
            <Grid item xs="6">
              <FormSelectField
                selectList={fieldProps.formState.resultsList}
                fieldName="categoryResultId"
                label={i18n.result}
                i18n={i18n}
                {...fieldProps}
              />
            </Grid>
            <Grid item xs="6">
              <FormTextField fieldName="keywords" i18n={i18n} {...fieldProps} />
            </Grid>
          </Grid>
        </Paper>
      )}
    />
  );
};

export default withStyles(styles)(Item);
