import React, { useContext } from "react";
import { observer } from "mobx-react";
import "url-search-params-polyfill";
import IndexTable from "../../components/table/index.js";
import Item from "./item";
import ParticipantService from "../../services/ParticipantService";
import { StoreContext } from "../../stores/context";
import { I18n } from "../../i18n";

const Participants = observer((props) => {
  const path = "participants";
  const { langStore } = useContext(StoreContext);
  const i18nProps = {
    i18n: langStore.participants,
    i18nCommon: I18n[props.lang].common,
  };

  return (
    <IndexTable
      {...props}
      {...i18nProps}
      serviceClass={ParticipantService}
      path={path}
      showCreate={() => {
        return false;
      }}
      showView={(i) => {
        return true;
      }}
      showEdit={(i) => {
        return true;
      }}
      showDelete={(i) => {
        return false;
      }}
      buttonsOnlyXl={true}
      columns={[
        { id: "name" },
        { id: "position" },
        { id: "employerName", shortTitle: "employerNameShort" },
        { id: "contactAddress" },
        {
          id: "eventsCount",
          center: true,
          displayFunc: (row) => (row.shortEvents ? row.shortEvents.length : 0),
        },
        {
          id: "community",
          displayFunc: (row) =>
            langStore.isEng ? row.communityNameEn : row.communityNameUa,
        },
        {
          id: "oblast",
          displayFunc: (row) => (langStore.isEng ? row.oblastEn : row.oblastUa),
          sort: langStore.isEng ? "oblastEn" : "oblastUa",
        },
        {
          id: "employerType",
          shortTitle: "employerTypeShort",
        displayFunc: (row) =>
            langStore.isEng ? row.employerTypeEn : row.employerTypeUa,
          sort: langStore.isEng ? "employerTypeEn" : "employerTypeUa",
        },
        {
          id: "employerLevel",
          displayFunc: (row) =>
            langStore.isEng ? row.employerLevelEn : row.employerLevelUa,
          sort: langStore.isEng ? "employerLevelEn" : "employerLevelUa",
        },
      ]}
      onEditItem={(itemId, item, handleItemSave) => {
        return (
          <Item
            item={item}
            itemId={itemId}
            indexPath={path}
            handleItemSave={handleItemSave}
            isEditable={true}
            {...i18nProps}
            {...props}
          />
        );
      }}
      onViewItem={(itemId, item) => {
        return (
          <Item
            item={item}
            itemId={itemId}
            indexPath={path}
            isEditable={false}
            {...i18nProps}
            {...props}
          />
        );
      }}
    />
  );
});

export default Participants;
