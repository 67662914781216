import { makeAutoObservable, runInAction } from "mobx";
import FileSaver from "file-saver";
import { REQUEST_STATES } from "../utils/RequestStates";
import { DataFetcher } from "../utils/DataFetcher";

export class EventStore {
  dataFetcher: DataFetcher = null;
  templateDownloadingStatus = REQUEST_STATES.Initial;
  importUploadingStatus = REQUEST_STATES.Initial;
  importedRows = 0;
  importErrors = [];

  constructor(dataFetcher) {
    this.dataFetcher = dataFetcher;
    makeAutoObservable(this, {
      dataFetcher: false,
    });
  }

  get downloadTemplateInProgress() {
    return this.templateDownloadingStatus === REQUEST_STATES.Pending;
  }

  get uploadParticipantsInProgress() {
    return this.importUploadingStatus === REQUEST_STATES.Pending;
  }

  async downloadParticipantsTemplate() {
    this.templateDownloadingStatus = REQUEST_STATES.Pending;
    try {
      const { name, data } =
        await this.dataFetcher.downloadParticipantsTemplate();
      FileSaver.saveAs(data, name);
      runInAction(() => {
        this.templateDownloadingStatus = REQUEST_STATES.Success;
      });
    } catch (ex) {
      runInAction(() => {
        this.templateDownloadingStatus = REQUEST_STATES.Error;
      });
    }
  }

  async uploadParticipants(eventId, file) {
    this.importUploadingStatus = REQUEST_STATES.Pending;
    this.importErrors = [];
    this.importedRows = 0;
    try {
      const data = await this.dataFetcher.uploadParticipants(eventId, file);
      runInAction(() => {
        this.importedRows = data.importedIds?.length ?? 0;
        this.importErrors = data.errors ?? [];
        this.importUploadingStatus = REQUEST_STATES.Success;
      });
    } catch (ex) {
      runInAction(() => {
        this.importUploadingStatus = REQUEST_STATES.Error;
      });
      throw ex;
    }
  }

  clearImportErrors = () => {
    this.importErrors = [];
  };

  async switchPreregistrationState(eventId, enabled) {
    return this.dataFetcher.switchPreregistrationState(eventId, enabled);
  }

  async deletePreregParticipant(id) {
    return this.dataFetcher.deletePreregParticipant(id);
  }
}
