import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "./GIZAlert";


const GIZNotification = ({ classes, variant, children, onClose, open }) => {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={open}
      autoHideDuration={5000}
      onClose={onClose}
      message={children}
      ContentProps={{
        className: classes[variant],
      }}
    />
  );
};

export default withStyles(styles)(GIZNotification);
