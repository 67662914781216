import React from 'react';
import withRoot from '../../../withRoot';
import BaseGraph from '../base-graph'
import { horizontalBarDatasetOptions, horizontalBarOptions, horizontalBarPlugins } from '../constants'
import {Settings} from "../../../config"
class ParticipantEventFormatChart extends React.Component {
  render() {
    return (
      <BaseGraph
        type='horizontalBar'
        tableTitle={`Participants of ${Settings.Tenant.ProgramName} events by format (%)`}
        url='/graph/event_participant_formats'
        chartOptions={horizontalBarOptions()}
        datasetOptions={horizontalBarDatasetOptions}
        filter={this.props.filter}
        plugins={horizontalBarPlugins()}
        noActions={this.props.noActions}
      />
    )
  }
}

export default withRoot(ParticipantEventFormatChart);
