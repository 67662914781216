import React, { Component } from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router";
import { Redirect } from "react-router-dom";
import { Typography, Select, MenuItem } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { StoreContext } from "../stores/context";
import GIZPublicHeader from "../components/GIZPublicHeader";
import GIZAlert from "../components/GIZAlert";
import GIZNotification from "../components/GIZNotification";
import GIZButton from "../components/GIZButton";
import GIZForm from "../components/GIZForm";
import GIZTextField from "../components/GIZTextField";
import GIZPhoneField from "../components/GIZPhoneField";
import GIZRadioGroup from "../components/GIZRadioGroup";
import GIZSelectField from "../components/GIZSelectField";
import { GIZCheckboxField } from "../components/GIZCheckboxField";
import { GIZAddressField } from "../components/GIZAddressField";
import Loader from "../components/loader";
import { required } from "../validators/required";
import { email } from "../validators/email";
import { phone } from "../validators/phone";
import { PreregistrationSteps } from "../utils/PreregistrationSteps";
import { LANGUAGES } from "../utils/lang";

const styles = () => ({
  root: {
    backgroundColor: "#f7f9fb",
    paddingBottom: "30px",
    minHeight: "calc(100vh - 100px)",
  },
  content: {
    position: "relative",
    padding: "1.5rem 12rem",
  },
  title: {
    marginTop: "30px",
  },
  formContainer: {
    maxWidth: "600px",
    margin: "20px auto",
  },
  button: {
    margin: "0 10px",
    maxWidth: "230px",
  },
  personalDataDescription: {
    color: "#000",
    fontSize: "10px",
    textAlign: "left",
  },
  heading: {
    fontSize: "18px !important",
    marginBottom: "2rem !important",
  },
});

class Preregistration extends Component {
  static contextType = StoreContext;

  constructor(props) {
    super(props);
    this.state = {
      notificationError: "",
    };
  }

  componentDidMount() {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    const { preregistrationStore } = this.context;
    preregistrationStore.loadEventData(id);
  }

  handleChangeLanguage = (e) => {
    this.context.langStore.changeLang(e.target.value);
  };

  handleRenderForm = (form) => {
    this.context.preregistrationStore.setForm(form);
  };

  handleSubmit = async () => {
    const { preregistrationStore } = this.context;
    try {
      await preregistrationStore.register();
    } catch (ex) {
      this.setState({
        notificationError: "Unknown server error. Please try again.",
      });
    }
  };

  handleExisting = () => {
    this.context.preregistrationStore.setExistingParticipant();
  };

  handleNew = () => {
    this.context.preregistrationStore.setNewParticipant();
  };

  handleCloseNotification = () => {
    this.setState({
      notificationError: "",
    });
  };

  renderTitle() {
    const { classes } = this.props;
    const { preregistrationStore, langStore } = this.context;

    if (
      preregistrationStore.step === PreregistrationSteps.EventNotFound ||
      preregistrationStore.step === PreregistrationSteps.RegistrationSuccessful
    ) {
      return null;
    }

    return (
      <>
        <div>
          <Select
            className="select"
            value={langStore.lang}
            renderValue={(value) => value}
            onChange={this.handleChangeLanguage}
          >
            <MenuItem value={LANGUAGES.ENGLISH}>English</MenuItem>
            <MenuItem value={LANGUAGES.UKRAINIAN}>Українська</MenuItem>
          </Select>
        </div>
        <Typography
          variant="h2"
          component="h1"
          gutterBottom
          className={classes.title}
        >
          {langStore.preregistration.title}
        </Typography>
      </>
    );
  }

  renderError() {
    const { preregistrationStore } = this.context;
    return preregistrationStore.step === PreregistrationSteps.EventNotFound ? (
      <GIZAlert variant="error">Event not found.</GIZAlert>
    ) : null;
  }

  renderSuccess() {
    const { classes } = this.props;
    const { preregistrationStore, langStore } = this.context;

    if (
      preregistrationStore.step !== PreregistrationSteps.RegistrationSuccessful
    ) {
      return null;
    }

    return (
      <>
        <Typography
          variant="h2"
          component="h1"
          gutterBottom
          className={classes.title}
        >
          {`${langStore.preregistration.registrationSuccessful1}"${preregistrationStore.eventName}".`}
        </Typography>
        <Typography
          variant="h2"
          component="h1"
          gutterBottom
          className={classes.title}
        >
          {langStore.preregistration.registrationSuccessful2}
        </Typography>
      </>
    );
  }

  renderForm() {
    const { classes } = this.props;
    const { preregistrationStore, langStore } = this.context;

    if (
      preregistrationStore.step === PreregistrationSteps.EventNotFound ||
      preregistrationStore.step === PreregistrationSteps.RegistrationSuccessful
    ) {
      return null;
    }

    return (
      <div className={classes.formContainer}>
        {preregistrationStore.step === PreregistrationSteps.EventFound && (
          <>
            <GIZButton
              color="primary"
              onClick={this.handleExisting}
              className={classes.button}
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: langStore.preregistration.existingBtn,
                }}
              ></span>
            </GIZButton>
            <GIZButton
              color="primary"
              onClick={this.handleNew}
              className={classes.button}
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: langStore.preregistration.newBtn,
                }}
              ></span>
            </GIZButton>
          </>
        )}

        <GIZForm onSubmit={this.handleSubmit} onRender={this.handleRenderForm}>
          {preregistrationStore.step ===
            PreregistrationSteps.ExistingParticipant && (
            <>
              <Typography
                variant="h3"
                component="h2"
                className={classes.heading}
              >
                {langStore.preregistration.existingSubtitle}
              </Typography>
              <GIZTextField
                name="name"
                label={langStore.preregistration.name}
                validator={required}
              />
              <GIZPhoneField
                name="cellPhone"
                label={langStore.participants.cellPhone}
                validator={phone}
              />
              <GIZCheckboxField
                name="personalData"
                validator={required}
                label={langStore.preregistration.personalData}
              />
              <GIZButton color="primary" type="submit">
                {langStore.preregistration.submit}
              </GIZButton>
              <p className={classes.personalDataDescription}>
                {langStore.preregistration.personalDataDescription}
              </p>
            </>
          )}

          {preregistrationStore.step ===
            PreregistrationSteps.NewParticipant && (
            <>
              <Typography
                variant="h3"
                component="h2"
                className={classes.heading}
              >
                {`${langStore.preregistration.newSubtitle1}"${preregistrationStore.eventName}".`}
                <br />
                {langStore.preregistration.newSubtitle2}
              </Typography>
              <GIZTextField
                name="name"
                label={langStore.preregistration.name}
                validator={required}
              />
              <GIZPhoneField
                name="cellPhone"
                label={langStore.participants.cellPhone}
                validator={phone}
              />
              <GIZTextField name="email" label="E-mail" validator={email} />
              <GIZRadioGroup
                name="gender"
                label={langStore.common.gender}
                values={[
                  { label: langStore.common.FEMALE, value: "FEMALE" },
                  { label: langStore.common.MALE, value: "MALE" },
                  { label: "Other", value: "OTHER" },
                ]}
                validator={required}
              />
              <Typography variant="h3" component="h2">
                {langStore.participants.employment}
              </Typography>
              <GIZTextField
                name="position"
                label={langStore.participants.position}
                validator={required}
              />
              <GIZTextField
                name="employerName"
                label={langStore.participants.employerName}
                validator={required}
              />
              <GIZSelectField
                name="employerTypeId"
                label={langStore.participants.employerType}
                placeholder="Select"
                values={preregistrationStore.employerTypes.map((item) => ({
                  label: item[langStore.langKey],
                  value: item.id,
                }))}
                validator={required}
              />
              <GIZSelectField
                name="employerLevelId"
                label={langStore.participants.employerLevel}
                placeholder="Select"
                values={preregistrationStore.availableEmployerLevels.map(
                  (item) => ({
                    label: item[langStore.langKey],
                    value: item.id,
                  })
                )}
                validator={required}
              />
              {!preregistrationStore.regionsDisabled && (
                <GIZSelectField
                  name="regionId"
                  label={langStore.participants.region}
                  placeholder="Select"
                  values={preregistrationStore.availableRegions.map((item) => ({
                    label: item[langStore.langKey],
                    value: item.id,
                  }))}
                  disabled={preregistrationStore.regionsDisabled}
                  validator={required}
                />
              )}
              <GIZAddressField
                name="employerLocation"
                label={langStore.participants.employerAddress}
                validator={required}
              />
              <GIZCheckboxField
                name="personalData"
                validator={required}
                label={langStore.preregistration.personalData}
              />
              <GIZButton color="primary" type="submit">
                {langStore.preregistration.submit}
              </GIZButton>
              <p className={classes.personalDataDescription}>
                {langStore.preregistration.personalDataDescription}
              </p>
            </>
          )}
        </GIZForm>
      </div>
    );
  }

  render() {
    const { preregistrationStore, userStore } = this.context;
    const { classes } = this.props;
    const { notificationError } = this.state;

    if (userStore.id) {
      return <Redirect to="/" />;
    }

    return (
      <div className={classes.root}>
        <GIZPublicHeader />
        <div className={classes.content}>
          {this.renderTitle()}
          {this.renderError()}
          {this.renderSuccess()}
          {this.renderForm()}
          {preregistrationStore.pending && <Loader />}
        </div>
        <GIZNotification
          open={notificationError.length > 0}
          onClose={this.handleCloseNotification}
          variant="error"
        >
          {notificationError}
        </GIZNotification>
      </div>
    );
  }
}
export default withStyles(styles)(withRouter(observer(Preregistration)));
