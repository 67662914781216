import { variables } from "./variables";

export const loginRoute = "/login";
export const forgotPasswordRoute = "/forgot-password";
export const resetPasswordRoute = "/reset-password";
export const preregistrationRoute = "/preregistration/:id";

export const forumRoute = `https://${variables.forumHost}/c/tech-issues`;

export const publicRoutes = [
  loginRoute,
  forgotPasswordRoute,
  resetPasswordRoute,
  preregistrationRoute,
];
